<template>
  <div class="page-detail-container">
    <!--顶部标题和页面全局按钮-->
    <wt-detail-header title="详情">
      <wt-button v-if="viewType == 'add'" v-has-any-premission="['NEWS:ADD']" type="primary" :loading="isButtonLoading" @click="onClickAdd">保存</wt-button>
      <wt-button v-if="viewType == 'edit'" v-has-any-premission="['NEWS:EDIT']" type="primary" :loading="isButtonLoading" @click="onClickSave">保存</wt-button>
      <wt-button @click="$emit('on-close')">返回</wt-button>
    </wt-detail-header>
    <!--内容区域-->
    <div class="wt-detail-container" v-loading="isFromLoading">
      <wt-detail-section title="基本信息">
        <el-form :model="detailForm" ref="form" size="small" class="wt-detail-section-form-center" label-width="30%">
          <el-form-item label="动态分类ID" prop="categoryId" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <el-input v-model="detailForm.categoryId" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="动态封面" prop="cover" :rules="[{ required: true, message: '动态封面不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <wt-image-upload v-model="detailForm.cover" placeholder="请输入" :limit="1"></wt-image-upload>
          </el-form-item>
          <el-form-item label="动态附件" prop="attachment" :rules="[{ required: true, message: '动态附件不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <wt-file-upload v-model="detailForm.attachment" placeholder="请输入" type="json" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document" :limit="1"></wt-file-upload>
          </el-form-item>
          <el-form-item label="动态类型 0/默认" prop="type" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' }]">
            <el-select v-model="detailForm.type" placeholder="请选择"></el-select>
          </el-form-item>
          <el-form-item label="是否发布" prop="isPublish" :rules="[{ required: true, message: '请选择发布状态', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' }]">
            <wt-form-simple-select-item v-model="detailForm.isPublish"/>
          </el-form-item>
          <el-form-item label="是否删除" prop="isDelete" :rules="[{ required: true, message: '请选择删除状态', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' }]">
            <wt-form-simple-select-item v-model="detailForm.isDelete"/>
          </el-form-item>
          <el-form-item label="简介" prop="introduction" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <el-input v-model="detailForm.introduction" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="动态详情" prop="content" class="col-12" :rules="[{ required: true, validator: $wtValidator.ValidatorWtEditor, message: '输入内容不能为空', trigger: 'change' }]">
            <WtTinymce v-model="detailForm.content"></WtTinymce>
          </el-form-item>
        </el-form>
      </wt-detail-section>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'ExampleNewsEdit',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isButtonLoading: false,
      isFromLoading: false,
      detailForm: {}
    }
  },
  mounted() {
    if (this.viewType !== 'add') {
      this.requestDetail()
    }
  },
  created() {
  },
  methods: {
    onClickAdd() {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestAdd()
      }).catch(() => {
      })
    },
    onClickSave() {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestUpdate()
      }).catch(() => {
      })
    },
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: '/modules/news/detail',
        method: 'post',
        data: { id: that.detailData.id }
      }).then((resp) => {
        that.detailForm = resp.data || {}
        that.isFromLoading = false
      }).catch(() => {
        that.isFromLoading = false
      })
    },
    requestUpdate() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that.$wtRequest({
        url: '/modules/news/update',
        method: 'post',
        data: that.detailForm
      }).then((resp) => {
        that.isButtonLoading = false
        that.isFromLoading = false
        that.$message({ type: 'success', message: '保存成功' })
        that.$emit('on-refresh')
        that.$emit('on-close')
      }).catch(() => {
        that.isButtonLoading = false
        that.isFromLoading = false
      })
    },
    requestAdd() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that.$wtRequest({
        url: '/modules/news/add',
        method: 'post',
        data: that.detailForm
      }).then((resp) => {
        that.isButtonLoading = false
        that.isFromLoading = false
        that.$message({ type: 'success', message: '保存成功' })
        that.$emit('on-refresh')
        that.$emit('on-close')
      }).catch(() => {
        that.isButtonLoading = false
        that.isFromLoading = false
      })
    }
  },
  computed: {
    ...mapState({
    })
  }
}
</script>
