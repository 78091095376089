<template>
  <div class="wt-detail-tab-header">
    <div class="wt-detail-tab-header-top">
      <div class="wt-detail-header-title">
        <slot name="title">
          {{title}}
        </slot>
      </div>
      <div class="wt-detail-header-buttons">
        <slot>
        </slot>
      </div>
    </div>
    <div class="tabs">
      <div class="tab" :class="{active: (item.id == activeTabId)}" v-for="(item, index) in dataList" :key="index" @click="onClickTab(item.id)">{{item.name}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WtDetailTabHeader',
  props: ['title', 'dataList', 'activeId'],
  data() {
    return {
      activeTabId: ''
    }
  },
  created() {
    this.activeTabId = this.activeId
  },
  methods: {
    onClickTab(tabId) {
      let itemData = {}
      this.dataList.forEach(item => {
        if (item.id === tabId) {
          itemData = item
        }
      })
      this.activeTabId = itemData.id
      this.$emit('on-tab-header-change', itemData)
    }
  }
}
</script>

<style scoped>
.wt-detail-tab-header > .tabs{width: 100%;position: absolute;bottom: 0;left: 0;height: 40px;display: flex;align-items: center;padding-left: 24px;}
.wt-detail-tab-header > .tabs > .tab{height: 100%;display: flex;justify-content: center;align-items: center;color: #333;padding: 0 10px;margin-right: 6px;cursor: pointer;border-bottom: 2px solid transparent;}
.wt-detail-tab-header > .tabs > .tab:hover{color: #409EFF;}
.wt-detail-tab-header > .tabs > .tab.active{color: #409EFF;border-bottom: 2px solid #1890ff;}
</style>
