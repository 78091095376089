<template>
  <div>
    <wt-button type="primary" @click="onClickExport">{{text}}</wt-button>
  </div>
</template>

<script>
export default {
  name: 'exportButton',
  isAutoComponent: true,
  props: {
    templateUrl: {
      type: String,
      default: './'
    },
    text: {
      type: String,
      default: '导出'
    }
  },
  data() {
    return {
      wtTemplateServerUrl: window.wtConst.TEMPLATE_SERVER_URL,
      successCount: '',
      isFormLoading: false,
      stepValue: 0,
      uploadData: {},
      isDialogFormVisible: false
    }
  },
  methods: {
    onClickExport() {
      window.open(this.wtTemplateServerUrl + 'template/模板.xls', '_blank')
    },
    onClickDialogOpen() {
      this.successCount = Math.floor(Math.random() * 100)
      this.stepValue = 0
      this.uploadData = {}
      this.isFormLoading = false
      this.isDialogFormVisible = true
    },
    onClickDownloadTemplate() {
      const baseUrl = window.location.href.substr(0, window.location.href.indexOf('#'))
      window.open(baseUrl + 'template/模板.xls', '_blank')
    },
    onClickNextPrev() {
      this.stepValue -= 1
    },
    onClickNextStep() {
      this.stepValue += 1
    },
    onClickCommit() {
      const that = this
      that.isFormLoading = true
      setTimeout(() => {
        that.$message({ type: 'success', message: '导入成功' })
        that.isFormLoading = false
        that.isDialogFormVisible = false
      }, 1000)
    },
    onFileUpload(data) {
      this.uploadData.file = data.file
    }
  }
}
</script>

<style scoped>
  .wt-import-container{}
  .wt-import-container .body{height: 350px;}
  .wt-import-container .content{width: 100%;height: 350px;}
  .wt-import-container .content .content-upload{width: 100%; height: 300px; display: flex;justify-content: center;align-items: center;}
  .wt-import-container .content  .content-check{}
</style>
