import request from '@/utils/request'
const app = {
  state: {
    dictCacheMap: {},
    sidebar: {
      opened: true
    }
  },
  mutations: {
    TOGGLE_SIDEBAR: state => {
      state.sidebar.opened = !state.sidebar.opened
    },
    UPDATE_DICT_DATA: (state, data) => {
      state.dictCacheMap[data.dictKey] = data.list
    }
  },
  actions: {
    ToggleSideBar: ({ commit }) => {
      commit('TOGGLE_SIDEBAR')
    },
    queryDictList({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        if (state.dictCacheMap[data.dictKey] && state.dictCacheMap[data.dictKey].length > 0) {
          resolve(state.dictCacheMap[data.dictKey])
          return
        }
        request({
          url: '/modules/dict/ajaxList',
          method: 'post',
          data: { 'dictKey': data.dictKey }
        }).then(resp => {
          commit('UPDATE_DICT_DATA', {
            dictKey: data.dictKey,
            list: resp.data
          })
          resolve(resp.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default app
