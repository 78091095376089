<template>
  <el-cascader v-model="data" :props="props" @change="onAreaChange"></el-cascader>
</template>

<script>
export default {
  name: 'areaSelect',
  isAutoComponent: true,
  data() {
    return {
      isFromLoading: false,
      data: [],
      props: {
        lazy: true,
        lazyLoad: this.lazyLoad
      }
    }
  },
  watch: {
    value(val, oldVal) {
      if (!oldVal || oldVal.length <= 0) {
        this.data = val
      }
    }
  },
  methods: {
    onAreaChange(val) {
      this.$emit('input', val)
    },
    lazyLoad(node, resolve) {
      const that = this
      if (node.level === 0) {
        that.requestProvinceList().then((data) => {
          resolve(data.map(item => {
            return {
              value: item.code,
              label: item.name,
              leaf: false
            }
          }))
        })
      } else if (node.level === 1) {
        that.requestCityList(node.value).then((data) => {
          resolve(data.map(item => {
            return {
              value: item.code,
              label: item.name,
              leaf: false
            }
          }))
        })
      } else if (node.level === 2) {
        that.requestDistrictList(node.value).then((data) => {
          resolve(data.map(item => {
            return {
              value: item.code,
              label: item.name,
              leaf: true
            }
          }))
        })
      }
    },
    requestProvinceList() {
      return new Promise((resolve, reject) => {
        const that = this
        that.isFromLoading = true
        that.$wtRequest({
          url: '/modules/area/provinceList',
          method: 'post',
          data: {}
        }).then((resp) => {
          that.isFromLoading = false
          resolve(resp.data)
        }).catch(() => {
          that.isFromLoading = false
          reject(new Error(''))
        })
      })
    },
    requestCityList(key) {
      return new Promise((resolve, reject) => {
        const that = this
        that.isFromLoading = true
        that.$wtRequest({
          url: '/modules/area/cityList',
          method: 'post',
          data: { key: key }
        }).then((resp) => {
          that.isFromLoading = false
          resolve(resp.data)
        }).catch(() => {
          that.isFromLoading = false
          reject(new Error(''))
        })
      })
    },
    requestDistrictList(key) {
      return new Promise((resolve, reject) => {
        const that = this
        that.isFromLoading = true
        that.$wtRequest({
          url: '/modules/area/districtList',
          method: 'post',
          data: { key: key }
        }).then((resp) => {
          that.isFromLoading = false
          resolve(resp.data)
        }).catch(() => {
          that.isFromLoading = false
          reject(new Error(''))
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
