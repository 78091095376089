import request from '@/utils/request'
const api = 'https://meining.100api.com.cn/server'

// 登录/api/auth/loginByCode
export function sendResetPasswordSmsCodeByMobile(data) {
  return request({
    url: api + '/modules/auth/sendResetPasswordSmsCodeByMobile',
    method: 'post',
    data
  })
}
export function authSetPassword(data) {
  return request({
    url: api + '/modules/auth/setPassword',
    method: 'post',
    data
  })
}
export function index(data) {
  return request({
    url: api + '/modules/index/index',
    method: 'post',
    data
  })
}
export function patientUpdate(data) {
  return request({
    url: api + '/modules/patient/update',
    method: 'post',
    data
  })
}
export function patientRecordDetail(data) {
  return request({
    url: api + '/modules/patientRecord/detail',
    method: 'post',
    data
  })
}
export function patientRecordDetail2(data) {
  return request({
    url: api + '/modules/medicalRecords/detail',
    method: 'post',
    data
  })
}
export function patientRecordDetail3(data) {
  return request({
    url: api + '/modules/consultRecord/detail',
    method: 'post',
    data
  })
}
export function patientRecordDetail4(data) {
  return request({
    url: api + '/modules/giftOrder/list',
    method: 'post',
    data
  })
}
export function patienDelete(data) {
  return request({
    url: api + '/modules/patient/delete',
    method: 'post',
    data
  })
}
export function doctorPatient(data) {
  return request({
    url: api + '/modules/doctorPatient/bindingDoctor',
    method: 'post',
    data
  })
}
export function doctorAdd(data) {
  return request({
    url: api + '/modules/doctor/add',
    method: 'post',
    data
  })
}
export function doctorUpdate(data) {
  return request({
    url: api + '/modules/doctor/update',
    method: 'post',
    data
  })
}
export function messageAdd(data) {
  return request({
    url: api + '/modules/message/add',
    method: 'post',
    data
  })
}
export function doctorDelete(data) {
  return request({
    url: api + '/modules/doctor/delete',
    method: 'post',
    data
  })
}
export function articleDetail(data) {
  return request({
    url: api + '/modules/article/detail',
    method: 'post',
    data
  })
}
export function articleGroupList(data) {
  return request({
    url: api + '/modules/articleGroup/list',
    method: 'post',
    data
  })
}
export function articleUpdate(data) {
  return request({
    url: api + '/modules/article/update',
    method: 'post',
    data
  })
}
export function articleGroupAdd(data) {
  return request({
    url: api + '/modules/articleGroup/add',
    method: 'post',
    data
  })
}
export function articleGroupUpdate(data) {
  return request({
    url: api + '/modules/articleGroup/update',
    method: 'post',
    data
  })
}
export function articleGroupDelete(data) {
  return request({
    url: api + '/modules/articleGroup/delete',
    method: 'post',
    data
  })
}
export function articleAdd(data) {
  return request({
    url: api + '/modules/article/add',
    method: 'post',
    data
  })
}
export function articleDelete(data) {
  return request({
    url: api + '/modules/article/delete',
    method: 'post',
    data
  })
}
export function articleList(data) {
  return request({
    url: api + '/modules/article/list',
    method: 'post',
    data
  })
}
export function articleGroupDetail(data) {
  return request({
    url: api + '/modules/articleGroup/detail',
    method: 'post',
    data
  })
}
export function giftAdd(data) {
  return request({
    url: api + '/modules/gift/add',
    method: 'post',
    data
  })
}
export function giftDetail(data) {
  return request({
    url: api + '/modules/gift/detail',
    method: 'post',
    data
  })
}
export function giftUpdate(data) {
  return request({
    url: api + '/modules/gift/update',
    method: 'post',
    data
  })
}
export function giftOrderUpdate(data) {
  return request({
    url: api + '/modules/giftOrder/update',
    method: 'post',
    data
  })
}

