<template>
  <div class="page-detail-container">
    <!--顶部标题和页面全局按钮-->
    <wt-detail-header title="详情">
      <wt-button @click="$emit('on-close')">返回</wt-button>
    </wt-detail-header>
    <!--内容区域-->
    <div class="wt-detail-container">
      <wt-detail-section title="基本信息">
        <el-form :model="detailForm" ref="form" size="small" class="wt-detail-section-form-center" label-width="30%">
                    <el-form-item label="动态分类ID" prop="categoryId" :rules="[{ required: true, message: '动态分类ID不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
              <el-input v-model="detailForm.categoryId" placeholder="请输入" readonly disabled></el-input>
            </el-form-item>
            <el-form-item label="发布人ID" prop="creatorId" :rules="[{ required: true, message: '发布人ID不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
              <el-input v-model="detailForm.creatorId" placeholder="请输入" readonly disabled></el-input>
            </el-form-item>
            <el-form-item label="机构ID" prop="orgId" :rules="[{ required: true, message: '机构ID不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
              <el-input v-model="detailForm.orgId" placeholder="请输入" readonly disabled></el-input>
            </el-form-item>
            <el-form-item label="动态名称" prop="title" :rules="[{ required: true, message: '动态名称不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
              <el-input v-model="detailForm.title" placeholder="请输入" readonly disabled></el-input>
            </el-form-item>
            <el-form-item label="副标题" prop="subtitle" :rules="[{ required: true, message: '副标题不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
              <el-input v-model="detailForm.subtitle" placeholder="请输入" readonly disabled></el-input>
            </el-form-item>
            <el-form-item label="动态详情" prop="content" class="col-12" :rules="[{ required: true, message: '动态详情不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
              <WtTinymce v-model="detailForm.content" readonly disabled></WtTinymce>
            </el-form-item>
            <el-form-item label="动态封面" prop="cover" :rules="[{ required: true, message: '动态封面不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
              <el-input v-model="detailForm.cover" placeholder="请输入" readonly disabled></el-input>
            </el-form-item>
            <el-form-item label="动态类型 0/默认" prop="type" :rules="[{ required: true, message: '动态类型 0/默认不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' }]">
              <el-select v-model="detailForm.type" placeholder="请选择" readonly disabled></el-select>
            </el-form-item>
            <el-form-item label="显示发布时间" prop="displayTime" :rules="[{ required: true, message: '显示发布时间不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
              <el-input v-model="detailForm.displayTime" placeholder="请输入" readonly disabled></el-input>
            </el-form-item>
            <el-form-item label="显示发布作者" prop="displayAvator" :rules="[{ required: true, message: '显示发布作者不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
              <el-input v-model="detailForm.displayAvator" placeholder="请输入" readonly disabled></el-input>
            </el-form-item>
            <el-form-item label="是否存在原文" prop="isSource" :rules="[{ required: true, message: '是否存在原文不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' }]">
              <el-select v-model="detailForm.isSource" placeholder="请选择" readonly disabled></el-select>
            </el-form-item>
            <el-form-item label="原文链接" prop="sourceUrl" :rules="[{ required: true, message: '原文链接不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
              <el-input v-model="detailForm.sourceUrl" placeholder="请输入" readonly disabled></el-input>
            </el-form-item>
            <el-form-item label="审核状态 0/默认" prop="status" :rules="[{ required: true, message: '审核状态 0/默认不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' }]">
              <el-select v-model="detailForm.status" placeholder="请选择" readonly disabled></el-select>
            </el-form-item>
            <el-form-item label="排序" prop="orderNum" :rules="[{ type: 'number', message: '排序字段必须为数字值'}]">
              <el-input v-model.number="detailForm.orderNum" placeholder="请输入" autocomplete="off" readonly disabled></el-input>
            </el-form-item>
              <el-form-item label="是否发布" prop="isPublish" :rules="[{ required: true, message: '请选择发布状态', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' }]">
                  <wt-form-simple-select-item v-model="detailForm.isPublish" readonly disabled/>
              </el-form-item>
              <el-form-item label="是否删除" prop="isDelete" :rules="[{ required: true, message: '请选择删除状态', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' }]">
                  <wt-form-simple-select-item v-model="detailForm.isDelete" readonly disabled/>
              </el-form-item>
            <el-form-item label="简介" prop="introduction" :rules="[{ required: true, message: '简介不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
              <el-input v-model="detailForm.introduction" placeholder="请输入" readonly disabled></el-input>
            </el-form-item>
        </el-form>
      </wt-detail-section>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'ExampleNewsView',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isFromLoading: false,
      detailForm: {}
    }
  },
  mounted() {
    this.requestDetail()
  },
  created() {
  },
  methods: {
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: '/modules/news/detail',
        method: 'post',
        data: { id: that.detailData.id }
      }).then((resp) => {
        that.detailForm = resp.data || {}
        that.isFromLoading = false
      }).catch(() => {
        that.isFromLoading = false
      })
    }
  },
  computed: {
    ...mapState({
    })
  }
}
</script>
