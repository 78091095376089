var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-detail-container"},[_c('wt-detail-header',{attrs:{"title":"详情"}},[_c('wt-button',{on:{"click":function($event){return _vm.$emit('on-close')}}},[_vm._v("返回")])],1),_c('div',{staticClass:"wt-detail-container"},[_c('wt-detail-section',{attrs:{"title":"基本信息"}},[_c('el-form',{ref:"form",staticClass:"wt-detail-section-form-center",attrs:{"model":_vm.detailForm,"size":"small","label-width":"30%"}},[_c('el-form-item',{attrs:{"label":"患者ID：","prop":"patinetId","rules":[
            {
              required: true,
              message: '患者ID不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择","readonly":"","disabled":""},model:{value:(_vm.detailForm.patinetId),callback:function ($$v) {_vm.$set(_vm.detailForm, "patinetId", $$v)},expression:"detailForm.patinetId"}})],1),_c('el-form-item',{attrs:{"label":"药品名称：","prop":"drug","rules":[
            {
              required: true,
              message: '药品名称不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_vm._v(" "+_vm._s(_vm.detailForm.drug)+" ")]),_c('el-form-item',{attrs:{"label":"药品规格：","prop":"specifications","rules":[
            {
              required: true,
              message: '药品规格不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_vm._v(" "+_vm._s(_vm.detailForm.specifications)+" ")]),_c('el-form-item',{attrs:{"label":"用药数量：","prop":"amount","rules":[
            {
              required: true,
              message: '用药数量不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择","readonly":"","disabled":""},model:{value:(_vm.detailForm.amount),callback:function ($$v) {_vm.$set(_vm.detailForm, "amount", $$v)},expression:"detailForm.amount"}})],1),_c('el-form-item',{attrs:{"label":"是否发生不良反应：","prop":"isAdverse","rules":[
            {
              required: true,
              message: '是否发生不良反应不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择","readonly":"","disabled":""},model:{value:(_vm.detailForm.isAdverse),callback:function ($$v) {_vm.$set(_vm.detailForm, "isAdverse", $$v)},expression:"detailForm.isAdverse"}})],1),_c('el-form-item',{attrs:{"label":"不良反应类型：","prop":"adverseType","rules":[
            {
              required: true,
              message: '不良反应类型不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择","readonly":"","disabled":""},model:{value:(_vm.detailForm.adverseType),callback:function ($$v) {_vm.$set(_vm.detailForm, "adverseType", $$v)},expression:"detailForm.adverseType"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }