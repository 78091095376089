import Vue from 'vue'
import Router from 'vue-router'
// import Layout from '@/components/WtLayout/WtLayout'
// in development-env not use lazy-loading, because lazy-loading too many pages will cause webpack hot update too slow. so only in production use lazy-loading;
// detail: https://panjiachen.github.io/vue-element-admin-site/#/lazy-loading

Vue.use(Router)

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
**/
const dynamicRouters = [
  { path: '/login', component: () => import('@/modules/login/index'), hidden: true },
  { path: '/404', component: () => import('@/modules/layout/404'), hidden: true },
  {
    path: '/',
    redirect: '/dashboard',
    name: '首页',
    hidden: true
  }
]

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new Router({
  // mode: 'history', //后端支持可开
  scrollBehavior: () => ({ y: 0 }),
  routes: dynamicRouters
})

// router.afterEach((to, from) => {
//   if (to !== null && to.matched !== null && to.matched.length >= 2) {
//     window.localStorage.setItem('LAYOUT_MENU_KEY', to.matched[1].name)
//   }
// })

export default router
