<template>
  <div class="app-tabs">
    <div class="app-tabs-control app-icon-prev" @click="onPageScroll('left')"><i class="el-icon-d-arrow-left"></i></div>
    <div class="app-tabs-control app-icon-next" @click="onPageScroll('right')"><i class="el-icon-d-arrow-right"></i></div>
    <el-dropdown class="app-tabs-control app-icon-down" trigger="click" @command="handleTabsCommand">
      <div><i class="el-icon-arrow-down"></i></div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-show="!isDashboardActive()" command="closeSelectedTag">关闭当前标签页</el-dropdown-item>
        <el-dropdown-item command="closeOthersTags">关闭其他标签页</el-dropdown-item>
        <el-dropdown-item command="closeAllTags">关闭全部标签页</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <div class="app-tab" ref="appTab">
      <ul class="app-tab-title" :style="{left: tabLeft + 'px'}" ref="appTabUl">
        <li :class="isDashboardActive() ? 'app-this': ''" @click="toLink('/dashboard')"><i class="el-icon-s-home"></i></li>
        <li v-for="tabItem in visitedViews" :key="tabItem.path" :class="isActive(tabItem) ? 'app-this': ''" >
            <div @click="toTabLink({ name: tabItem.name, path: tabItem.path, query: tabItem.query, fullPath: tabItem.fullPath, keepAlive: true })" ref="tabItems">{{tabItem.meta.title}}</div>
          <i class="el-icon-close app-tab-close" @click="closeSelectedTag(tabItem.name)"></i>
        </li>
      </ul>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'WtTabs',
  data() {
    return {
      tabLeft: 0
    }
  },
  created() {
    console.log('this.visitedViews', this.visitedViews)
  },
  methods: {
    isActive(route) {
      return route.name === this.$route.name
    },
    isDashboardActive() {
      return this.$route.path === '/dashboard'
    },
    handleTabsCommand(command) {
      if (command === 'closeSelectedTag') {
        this.closeSelectedTag(this.$route.name)
      } else if (command === 'closeOthersTags') {
        this.closeOthersTags(this.$route.name)
      } else if (command === 'closeAllTags') {
        this.closeAllTags(this.$route.name)
      }
    },
    moveToCurrentTag() {
      const tags = this.$refs.tabItems
      this.$nextTick(() => {
        for (const tag of tags) {
          if (tag.to.name === this.$route.name) {
            // this.$refs.scrollPane.moveToTarget(tag)
            if (tag.to.fullPath !== this.$route.fullPath) {
              this.$store.dispatch('wtTabs/updateVisitedView', this.$route)
            }
            break
          }
        }
      })
    },
    closeSelectedTag(viewName) {
      const view = this.findViewByName(viewName)
      this.$store.dispatch('wtTabs/delView', view).then(({ visitedViews }) => {
        if (this.isActive(view)) {
          this.toLastView(visitedViews, view)
        }
      })
    },
    closeOthersTags(viewName) {
      const view = this.findViewByName(viewName)
      this.$store.dispatch('wtTabs/delOthersViews', view).then(() => {
        this.moveToCurrentTag()
        this.tabLeft = 0
      })
    },
    closeAllTags(view) {
      this.$store.dispatch('wtTabs/delAllViews').then(({ visitedViews }) => {
        this.toLastView(visitedViews, view)
        this.tabLeft = 0
      })
    },
    toLastView(visitedViews, view) {
      const latestView = visitedViews.slice(-1)[0]
      if (latestView) {
        this.$router.push(latestView)
      } else {
        if (view && view.name === 'Dashboard') {
          this.$router.replace({ path: '/redirect' + view.fullPath })
        } else {
          this.$router.push('/')
        }
      }
    },
    toLink(path) {
      console.log('visitedViews', this.visitedViews)
      this.$router.push(path)
    },
    toTabLink(params) {
      const that = this
      this.$router.push(params)
      this.$nextTick(() => {
        that.onPageScroll('auto')
      })
    },
    findViewByName(viewName) {
      const views = this.visitedViews.filter((item) => {
        return (item.name === viewName)
      })
      return (views && views.length > 0 ? views[0] : null)
    },
    onPageScroll(type) {
      const tabWidth = this.$refs['appTab'].offsetWidth
      const lastChild = this.$refs['appTabUl'].lastChild
      const currentChilds = this.$refs['appTabUl'].getElementsByClassName('app-this')
      const currentChild = (currentChilds && currentChilds.length > 0 ? currentChilds[0] : lastChild)
      const lastOffsetX = lastChild.offsetLeft + lastChild.offsetWidth
      const currentOffsetLeft = currentChild.offsetLeft
      const currentOffsetRight = currentChild.offsetLeft + currentChild.offsetWidth
      if (type === 'auto') {
        if (currentOffsetLeft < -this.tabLeft) {
          this.tabLeft = -currentOffsetLeft + currentChild.offsetWidth / 2
          return
        }
        if (currentOffsetRight + this.tabLeft >= tabWidth) {
          this.tabLeft = this.tabLeft - (currentOffsetRight + this.tabLeft - tabWidth) - currentChild.offsetWidth / 2
        }
      } else if (type === 'left') {
        this.tabLeft = Math.min((this.tabLeft + tabWidth), 0)
      } else if (type === 'right' && (lastOffsetX + this.tabLeft) > tabWidth) {
        this.tabLeft = (this.tabLeft - tabWidth)
      }
    }
  },
  watch: {
    $route() {
      const { name } = this.$route
      const that = this
      if (name) {
        this.$store.dispatch('wtTabs/addView', this.$route)
        this.$nextTick(() => {
          that.onPageScroll('auto')
        })
      }
      return false
    }
  },
  computed: {
    ...mapState({
      visitedViews: state => state.wtTabs.visitedViews
    })
  }
}
</script>

<style scoped>

</style>
