<template>
  <div>
    <div class="wt-oss-upload">
      <el-upload
        class="upload-demo"
        :drag="drag"
        :list-type="listType"
        action
        :file-list="fileList"
        :show-file-list="showFileList"
        :before-upload="handleBeforeUpload"
        :http-request="handleUpload"
        :on-success="handleChange"
        :on-remove="handleRemove"
        :limit="limit"
        :disabled="disabled"
        multiple>
<!--        <el-button size="small" type="primary" v-if="!drag">点击上传</el-button>-->
        <i class="el-icon-plus" v-if="!drag"></i>
        <i class="el-icon-upload" v-if="drag"></i>
        <div class="el-upload__text" v-if="drag">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">只能上传{{accept}}文件</div>
      </el-upload>
    </div>
  </div>
</template>

<script>
import Emitter from 'element-ui/src/mixins/emitter'
import request from '@/utils/request'
import SparkMD5 from 'spark-md5'
import OSS from 'ali-oss'
export default {
  name: 'wt-oss-upload',
  mixins: [Emitter],
  props: {
    // 是否支持拖拽上传
    drag: {
      type: Boolean,
      default: true
    },
    // 上传个数限制
    limit: {
      type: Number,
      default: 9
    },
    // 文件列表
    fileList: {
      type: Array,
      default: function() {
        return []
      }
    },
    // 是否显示文件列表
    showFileList: {
      type: Boolean,
      default: true
    },
    // 上传按钮文字显示
    btnTxt: {
      type: String,
      default: '上传'
    },
    // 接受上传的文件类型(/分割) 支持：jpg','png','txt','zip', 'rar','pdf','doc','docx','xlsx', 'mp4'
    accept: {
      type: String,
      default: 'jpg/png'
    },
    // 列表展示类型  支持：text/picture/picture-card
    listType: {
      type: String,
      default: 'text'
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ossData: {},
      fileType: 0
    }
  },
  watch: {
  },
  activated() {
  },
  created() {
  },
  methods: {
    // 上传前进行格式检查
    handleBeforeUpload(file) {
      // 文件类型校验
      const fileExt = file.name.replace(/.+\./, '')
      if (this.accept.indexOf(fileExt.toLowerCase()) === -1) {
        this.$message({
          type: 'warning',
          message: `请上传格式为${this.accept}的文件`
        })
        return false
      }
      // 文件数量校验
      if (this.fileList.length === this.limit) {
        this.$message({
          type: 'warning',
          message: `最多可以上传${this.limit}个文件`
        })
        return false
      }
    },
    // 上传
    handleUpload(option) {
      const that = this
      // 这里获取OSS的配置信息
      request({
        url: '/api/resourceFile/getOssUploadStsGrantAuthorization',
        method: 'post',
        data: {}
      }).then(response => {
        that.ossData = response.data
        // 上传实现
        const client = new OSS({
          region: that.ossData.region,
          accessKeyId: that.ossData.accessKeyId,
          accessKeySecret: that.ossData.accessKeySecret,
          bucket: that.ossData.bucket,
          stsToken: that.ossData.securityToken
        })

        // 组织数据
        const file = option.file
        let fileNames = ''
        console.log(file)
        // 将文件进行md5
        that.getFileMd5(file, (file, result) => {
          if (result.resultCode === 'SUCCESS') {
            fileNames = result.fileMd5 + '.' + file.name.split('.').pop()
            console.log('fileNames', fileNames)
            // sdk上传
            client.put(fileNames, file).then(res => {
              // 文件类型判断 (约定：0/PDF 1/视频)
              if (res.name.indexOf('pdf') !== -1) {
                that.fileType = 0
              }
              if (res.name.indexOf('mp4') !== -1) {
                that.fileType = 1
              }
              that.fileList.push({
                name: res.name,
                url: res.res.requestUrls[0],
                type: that.fileType
              })
              this.$emit('update:fileList', that.fileList)
            })
          }
        })
        // TODO 上传失败未处理
      })
    },
    // 监听上传结果
    handleChange(e) {
    },
    handleRemove(fileItem) {
      if (fileItem && fileItem.uid) {
        this.fileList = this.fileList.filter((item) => item.uid !== fileItem.uid)
        const array = this.fileList.filter((item) => item.status === 'success')
        this.$emit('update:fileList', array)
      }
    },
    getFileMd5(file, callback) {
      const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
      const chunkSize = 1024 * 1024 * 2
      const chunks = Math.ceil(file.size / chunkSize)
      let currentChunk = 0
      const spark = new SparkMD5.ArrayBuffer()
      const fileReader = new FileReader()

      fileReader.onload = function(e) {
        spark.append(e.target.result)
        currentChunk++
        if (currentChunk < chunks) {
          loadNext()
        } else {
          const fileMd5 = spark.end()
          callback(file, { resultCode: 'SUCCESS', fileMd5: fileMd5 })
        }
      }

      fileReader.onerror = function(err) {
        callback(file, { resultCode: 'FAIL', msg: err })
      }

      function loadNext() {
        const start = currentChunk * chunkSize
        const end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize
        fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
      }

      loadNext()
    }
  },
  filters: {
  },
  computed: {
  }
}
</script>

<style scoped lang="scss">
  .el-icon-plus {
    font-size: 26px;
    color: #fff;
    border: 1px dashed rgba(0, 255, 255, 0.8470588235);
    padding: 5px;
    cursor: pointer;
  }
</style>
