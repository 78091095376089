var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-detail-container"},[_c('wt-detail-header',{attrs:{"title":"详情"}},[(_vm.viewType == 'add')?_c('wt-button',{directives:[{name:"has-any-premission",rawName:"v-has-any-premission",value:(['MEDICALRECORDS:ADD']),expression:"['MEDICALRECORDS:ADD']"}],attrs:{"type":"primary","loading":_vm.isButtonLoading},on:{"click":_vm.onClickAdd}},[_vm._v("保存")]):_vm._e(),(_vm.viewType == 'edit')?_c('wt-button',{directives:[{name:"has-any-premission",rawName:"v-has-any-premission",value:(['MEDICALRECORDS:EDIT']),expression:"['MEDICALRECORDS:EDIT']"}],attrs:{"type":"primary","loading":_vm.isButtonLoading},on:{"click":_vm.onClickSave}},[_vm._v("保存")]):_vm._e(),_c('wt-button',{on:{"click":function($event){return _vm.$emit('on-close')}}},[_vm._v("返回")])],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isFromLoading),expression:"isFromLoading"}],staticClass:"wt-detail-container"},[_c('wt-detail-section',{attrs:{"title":"基本信息"}},[_c('el-form',{ref:"form",staticClass:"wt-detail-section-form-center",attrs:{"model":_vm.detailForm,"size":"small","label-width":"30%"}},[_c('el-form-item',{attrs:{"label":"患者ID：","prop":"patinetId","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.detailForm.patinetId),callback:function ($$v) {_vm.$set(_vm.detailForm, "patinetId", $$v)},expression:"detailForm.patinetId"}})],1),_c('el-form-item',{attrs:{"label":"药品名称：","prop":"drug","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.detailForm.drug),callback:function ($$v) {_vm.$set(_vm.detailForm, "drug", $$v)},expression:"detailForm.drug"}})],1),_c('el-form-item',{attrs:{"label":"药品规格：","prop":"specifications","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.detailForm.specifications),callback:function ($$v) {_vm.$set(_vm.detailForm, "specifications", $$v)},expression:"detailForm.specifications"}})],1),_c('el-form-item',{attrs:{"label":"用药数量：","prop":"amount","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.detailForm.amount),callback:function ($$v) {_vm.$set(_vm.detailForm, "amount", $$v)},expression:"detailForm.amount"}})],1),_c('el-form-item',{attrs:{"label":"是否发生不良反应：","prop":"isAdverse","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.detailForm.isAdverse),callback:function ($$v) {_vm.$set(_vm.detailForm, "isAdverse", $$v)},expression:"detailForm.isAdverse"}})],1),_c('el-form-item',{attrs:{"label":"不良反应类型：","prop":"adverseType","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.detailForm.adverseType),callback:function ($$v) {_vm.$set(_vm.detailForm, "adverseType", $$v)},expression:"detailForm.adverseType"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }