import Vue from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'normalize.css'
import '@/styles/index.scss'

// 引用富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
// 引入富文本css
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import '@/assets/icons' // icon
import '@/utils/permission' // permission control
import wtConst from '@/utils/constants'
import wtUtil from '@/utils/wtUtil'
import wtAuthUtil from '@/utils/wtAuthUtil'
import wtValidator from '@/utils/wtValidator'
import request from '@/utils/request'
import wtUploadRequest from '@/utils/wtUploadRequest'
import components from '@/components'
import moduleComponents from '@/modules'
import '@/filters'
import moment from 'moment'
import 'moment/locale/zh-cn'
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
// 富文本编辑器添加实例
Vue.use(VueQuillEditor /* { default global options } */)

Vue.use(VideoPlayer)
Vue.prototype.$wtUtil = wtUtil
Vue.prototype.$wtAuthUtil = wtAuthUtil
Vue.prototype.$wtValidator = wtValidator
Vue.prototype.$wtConst = wtConst
Vue.prototype.$wtRequest = request
Vue.prototype.$wtUploadRequest = wtUploadRequest
Vue.prototype.$moment = moment
Object.keys(components).forEach(key => {
  const component = components[key]
  Vue.component(component.name, component)
})
Object.keys(moduleComponents).forEach(key => {
  const component = moduleComponents[key]
  Vue.component(component.name, component)
})

Vue.use(ElementUI)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
