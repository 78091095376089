import request from '@/utils/request'
import staticRoutes from '@/router/staticRoutes'
import { getToken, setToken, setStorgeItem, getStorgeItem, clearStorge } from '@/utils/auth'
import store from '../index'

const auth = {
  state: {
    token: getToken(),
    userInfo: getStorgeItem('userInfo'),
    isDynamicRoutesInit: false,
    roleList: [],
    moduleItem: {},
    moduleList: [],
    roleCodeList: [],
    moduleCodeList: [],
    permissionCodeList: []
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
      setToken(token)
    },
    SET_USERINFO: (state, data) => {
      state.userInfo = data
      setStorgeItem('userInfo', data)
    },
    SET_AUTHINFO: (state, data) => {
      state.roleCodeList = data.roleList
      state.moduleCodeList = data.moduleList
      state.permissionCodeList = data.permissionList
    },
    SET_DYNAMIC_ROUTES: (state, dynamicRoutes) => {
      state.dynamicRoutes = dynamicRoutes
      state.isDynamicRoutesInit = true
    },
    SET_ROLE_LIST: (state, roles) => {
      state.roleList = roles
    },
    SET_MODULE_ITEM: (state, moduleItem) => {
      state.moduleItem = moduleItem
    },
    SET_MODULE_LIST: (state, moduleList) => {
      state.moduleList = moduleList
      if (moduleList && moduleList.length > 0) {
        for (let i = 0; i < moduleList.length; i++) {
          if (!moduleList[i].hidden) {
            state.moduleItem = moduleList[i]
            break
          }
        }
      }
    }
  },

  actions: {
    // 登录
    requestLogin({ commit }, formData) {
      const username = formData.username.trim()
      const password = formData.password
      return new Promise((resolve, reject) => {
        request({
          url: '/modules/auth/login',
          method: 'post',
          data: {
            'username': username,
            'password': password
          }
        }).then(response => {
          commit('SET_TOKEN', response.data.token)
          commit('SET_USERINFO', response.data.loginUser)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 登出
    requestLogout({ commit, state }) {
      return new Promise((resolve, reject) => {
        commit('SET_TOKEN', '')
        commit('SET_ROLE_LIST', [])
        clearStorge()
        resolve()
      })
    },
    // 获取用户菜单权限
    requestDynamicRoutes({ commit, state }) {
      return new Promise((resolve, reject) => {
        request({
          url: '/modules/auth/authInfo',
          method: 'post',
          data: { }
        }).then(response => {
          try {
            // dispatch('imLogin', response.data.imMemberId || 'user-10')
          } catch (e) {
          }
          const resultDatas = []
          // const moduleResultMap = {}
          const resultRoutes = []
          // const makeChildrenFunction = (staticRoute) => {
          //   const children = []
          //   if (staticRoute.children && staticRoute.children.length > 0) {
          //     staticRoute.children.forEach((child) => {
          //       if (response.data.moduleSet && response.data.moduleSet.indexOf((child.name ? child.name : '').toUpperCase()) !== -1) {
          //         const item = Object.assign({}, child)
          //         item.children = makeChildrenFunction(child)
          //         children.push(item)
          //       } else {
          //         const item = Object.assign({}, child)
          //         item.children = makeChildrenFunction(child)
          //         children.push(item)
          //       }
          //     })
          //   }
          //   return children
          // }
          staticRoutes.forEach(staticRoute => {
            // const item = Object.assign({}, staticRoute)
            // item.children = makeChildrenFunction(staticRoute)
            // resultRoutes.push(item)
            resultRoutes.push(staticRoute)
          })
          store.dispatch('updateJdMenuListMap', response.data.menuListMap)
          commit('SET_MODULE_LIST', resultRoutes)
          commit('SET_DYNAMIC_ROUTES', resultDatas)
          commit('SET_AUTHINFO', response.data)
          resolve(resultRoutes)
        }).catch(error => {
          commit('SET_DYNAMIC_ROUTES', [])
          reject(error)
        })
      })
    },
    // 设置的默认模块
    updateModuleItem: ({ commit }, data) => {
      commit('SET_MODULE_ITEM', data)
    }
  }
}

export default auth
