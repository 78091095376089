<template>
  <div class="wt-detail-section">
    <div class="wt-detail-section-title" v-if="title">
      <span class="title">{{title}}</span>
      <slot name="title-right" ></slot>
    </div>
    <div class="wt-detail-section-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WtDetailSection',
  props: ['title'],
  data() {
    return {
      isExpanded: true
    }
  },
  created() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.wt-detail-section-title .title-right{flex: 1;height: 100%;display: flex;justify-content: flex-end;}
</style>
