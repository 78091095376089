<template>
  <div class="wt-detail-form-buttons-container">

    <div class="wt-detail-form-buttons-title">
      <div class="left">
        <template v-if="!$slots.left">
          <span>{{detailFormButtons.title}}</span>
        </template>
        <slot v-else name="left"></slot>
      </div>
      <div class="right">
        <template v-if="!$slots.right">
          <template  v-for="(item, index) in detailFormButtons.elements" v-if="!item.hidden || item.hidden === undefined || item.hidden == null" >
            <wt-auth v-if="!item.permission" class="search-form-btn" :key="index">
              <el-button size="small" :type="item.className ? item.className : 'default'" @click="toClickAction(item)">{{item.name}}</el-button>
            </wt-auth>
            <wt-auth v-else class="search-form-btn" :hasAnyPremission="item.permission" :key="index">
              <el-button size="small" :type="item.className ? item.className : 'default'" @click="toClickAction(item)">{{item.name}}</el-button>
            </wt-auth>
          </template>
        </template>
        <slot v-else name="right"></slot>
      </div>
    </div>
    <div class="wt-form-inputs-content">

    </div>
  </div>
</template>

<script>
export default {
  name: 'wt-detail-form-buttons',
  props: ['detail-form-buttons'],
  data() {
    return {
    }
  },
  methods: {
    toClickAction(item) {
      this.$emit('on-detail-button-action', item)
    }
  }
}
</script>

<style scoped>

</style>
