<template>
  <div class="page-container">
    <wt-data-form
      ref="dataForm"
      :form.sync="form"
      request-list-url="/modules/medicineRemind/list"
      request-delete-url="/modules/medicineRemind/delete"
      request-detail-url="/modules/medicineRemind/detail"
    >
      <template slot="search">
        <wt-search-form-item
          title="主键"
          v-model="form.formData.id"
          type="select"
        />
        <wt-search-form-item
          title="类型 1 用药提醒 2 复诊提醒"
          v-model="form.formData.type"
          type="select"
        />
        <wt-search-form-item
          title="提醒名称"
          v-model="form.formData.title"
          type="input"
        />
        <wt-search-form-item
          title="服药剂量"
          v-model="form.formData.dosage"
          type="input"
        />
        <wt-search-form-item
          title="提醒次数"
          v-model="form.formData.remindNum"
          type="select"
        />
        <wt-search-form-item
          title="提醒频率 1 每天 2每周 3每隔x天 4固定周期"
          v-model="form.formData.frequency"
          type="select"
        />
        <wt-search-form-item
          title="连续天数"
          v-model="form.formData.continuousDay"
          type="select"
        />
        <wt-search-form-item
          title="间隔天数"
          v-model="form.formData.intervalDay"
          type="select"
        />
        <wt-search-form-item
          title="周期"
          v-model="form.formData.period"
          type="input"
        />
        <wt-search-form-item
          title="是否开启"
          v-model="form.formData.isOpen"
          type="select"
        />
        <wt-search-form-item
          title="是否删除"
          v-model="form.formData.isDelete"
          type="select"
        />
        <wt-search-form-item
          title="结束时间"
          v-model="form.formData.endTime"
          type="daterange"
        />
        <wt-search-form-item
          title="创建时间"
          v-model="form.formData.createTime"
          type="daterange"
        />
        <wt-search-form-item
          title="更新时间"
          v-model="form.formData.updateTime"
          type="daterange"
        />
      </template>
      <template slot="toolbar">
        <wt-button
          type="primary"
          v-has-any-premission="['MEDICINEREMIND:ADD']"
          @click="$refs.dataForm.toAdd()"
          >新增</wt-button
        >
        <wt-button
          type="danger"
          v-has-any-premission="['MEDICINEREMIND:DELETE']"
          @click="$refs.dataForm.onClickMultipleDelete()"
          >删除</wt-button
        >
      </template>
      <template slot="table">
        <el-table-column type="selection" label="选择"></el-table-column>
        <el-table-column prop="id" label="主键" sortable></el-table-column>
        <el-table-column
          prop="type"
          label="类型 1 用药提醒 2 复诊提醒"
          sortable
        ></el-table-column>
        <el-table-column prop="title" label="提醒名称"></el-table-column>
        <el-table-column prop="dosage" label="服药剂量"></el-table-column>
        <el-table-column
          prop="remindNum"
          label="提醒次数"
          sortable
        ></el-table-column>
        <el-table-column
          prop="frequency"
          label="提醒频率 1 每天 2每周 3每隔x天 4固定周期"
          sortable
        ></el-table-column>
        <el-table-column
          prop="continuousDay"
          label="连续天数"
          sortable
        ></el-table-column>
        <el-table-column
          prop="intervalDay"
          label="间隔天数"
          sortable
        ></el-table-column>
        <el-table-column prop="period" label="周期"></el-table-column>
        <el-table-column
          prop="isOpen"
          label="是否开启"
          sortable
        ></el-table-column>
        <el-table-column
          prop="endTime"
          label="结束时间"
          sortable
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          sortable
        ></el-table-column>
        <el-table-column
          prop="updateTime"
          label="更新时间"
          sortable
        ></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <wt-text-button
              v-has-any-premission="['MEDICINEREMIND:VIEW']"
              @click="$refs.dataForm.toView(scope.row)"
              >查看详情</wt-text-button
            >
            <wt-text-button
              v-has-any-premission="['MEDICINEREMIND:EDIT']"
              @click="$refs.dataForm.toEdit(scope.row)"
              >编辑</wt-text-button
            >
          </template>
        </el-table-column>
      </template>
    </wt-data-form>
    <!--弹层 开始-->
    <wt-detail-page :visible.sync="form.isViewDialogVisible"
      ><MedicineRemindView
        :detailData="form.detailData"
        view-type="view"
        @on-close="form.isViewDialogVisible = false"
    /></wt-detail-page>
    <wt-detail-page :visible.sync="form.isEditDialogVisible"
      ><MedicineRemindEdit
        :detailData="form.detailData"
        :view-type="form.dialogViewType"
        @on-refresh="$refs.dataForm.requestDataList()"
        @on-close="form.isEditDialogVisible = false"
    /></wt-detail-page>
    <!--弹层 结束-->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'medicineRemindList',
  data() {
    return {
      form: {
        isFromLoading: false,
        isViewDialogVisible: false,
        isEditDialogVisible: false,
        dialogViewType: 'edit',
        detailData: {},
        extFormData: {},
        formData: {
          name: '',
          updateTimeRange: []
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        table: {},
        formResult: {}
      }
    }
  },
  mounted() {
    this.$refs.dataForm.onClickSearch()
  },
  created() {},
  methods: {},
  computed: {
    ...mapState({})
  }
}
</script>
