var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-detail-container"},[_c('wt-detail-header',{attrs:{"title":"详情"}},[(_vm.viewType == 'add')?_c('wt-button',{directives:[{name:"has-any-premission",rawName:"v-has-any-premission",value:(['GIFT:ADD']),expression:"['GIFT:ADD']"}],attrs:{"type":"primary","loading":_vm.isButtonLoading},on:{"click":_vm.onClickAdd}},[_vm._v("保存")]):_vm._e(),(_vm.viewType == 'edit')?_c('wt-button',{directives:[{name:"has-any-premission",rawName:"v-has-any-premission",value:(['GIFT:EDIT']),expression:"['GIFT:EDIT']"}],attrs:{"type":"primary","loading":_vm.isButtonLoading},on:{"click":_vm.onClickSave}},[_vm._v("保存")]):_vm._e(),_c('wt-button',{on:{"click":function($event){return _vm.$emit('on-close')}}},[_vm._v("返回")])],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isFromLoading),expression:"isFromLoading"}],staticClass:"wt-detail-container"},[_c('wt-detail-section',{attrs:{"title":"基本信息"}},[_c('el-form',{ref:"form",staticClass:"wt-detail-section-form-center",attrs:{"model":_vm.detailForm,"size":"small","label-width":"30%"}},[_c('el-form-item',{attrs:{"label":"商品编号：","prop":"giftNo","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.detailForm.giftNo),callback:function ($$v) {_vm.$set(_vm.detailForm, "giftNo", $$v)},expression:"detailForm.giftNo"}})],1),_c('el-form-item',{attrs:{"label":"商品名称：","prop":"giftName","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.detailForm.giftName),callback:function ($$v) {_vm.$set(_vm.detailForm, "giftName", $$v)},expression:"detailForm.giftName"}})],1),_c('el-form-item',{attrs:{"label":"商品图片：","prop":"cover","rules":[
            {
              required: true,
              message: '商品图片不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('wt-image-upload',{attrs:{"limit":1},model:{value:(_vm.detailForm.cover),callback:function ($$v) {_vm.$set(_vm.detailForm, "cover", $$v)},expression:"detailForm.cover"}})],1),_c('el-form-item',{attrs:{"label":"兑换所需积分：","prop":"point","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.detailForm.point),callback:function ($$v) {_vm.$set(_vm.detailForm, "point", $$v)},expression:"detailForm.point"}})],1),_c('el-form-item',{attrs:{"label":"现有库存数：","prop":"inventoryNum","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.detailForm.inventoryNum),callback:function ($$v) {_vm.$set(_vm.detailForm, "inventoryNum", $$v)},expression:"detailForm.inventoryNum"}})],1),_c('el-form-item',{attrs:{"label":"是否发布：","prop":"isPublish","rules":[
            {
              required: true,
              message: '请选择发布状态',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('wt-form-simple-select-item',{model:{value:(_vm.detailForm.isPublish),callback:function ($$v) {_vm.$set(_vm.detailForm, "isPublish", $$v)},expression:"detailForm.isPublish"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }