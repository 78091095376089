<template>
  <div class="page-detail-container">
    <!--顶部标题和页面全局按钮-->
    <wt-detail-header title="详情">
      <wt-button @click="$emit('on-close')">返回</wt-button>
    </wt-detail-header>
    <!--内容区域-->
    <div class="wt-detail-container">
      <wt-detail-section title="基本信息">
        <el-form
          :model="detailForm"
          ref="form"
          size="small"
          class="wt-detail-section-form-center"
          label-width="30%"
        >
          <el-form-item
            label="首字母："
            prop="initial"
            :rules="[
              {
                required: true,
                message: '首字母不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            {{ detailForm.initial }}
          </el-form-item>
          <el-form-item
            label="手机号："
            prop="phone"
            :rules="[
              {
                required: true,
                message: '手机号不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            {{ detailForm.phone }}
          </el-form-item>
          <el-form-item
            label="密码："
            prop="password"
            :rules="[
              {
                required: true,
                message: '密码不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            {{ detailForm.password }}
          </el-form-item>
          <el-form-item
            label="姓名："
            prop="realname"
            :rules="[
              {
                required: true,
                message: '姓名不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            {{ detailForm.realname }}
          </el-form-item>
          <el-form-item
            label="性别："
            prop="gender"
            :rules="[
              {
                required: true,
                message: '性别不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'change'
              }
            ]"
          >
            <el-select
              v-model="detailForm.gender"
              placeholder="请选择"
              readonly
              disabled
            ></el-select>
          </el-form-item>
          <el-form-item
            label="积分："
            prop="point"
            :rules="[
              {
                required: true,
                message: '积分不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'change'
              }
            ]"
          >
            <el-select
              v-model="detailForm.point"
              placeholder="请选择"
              readonly
              disabled
            ></el-select>
          </el-form-item>
          <el-form-item
            label="医院："
            prop="age"
            :rules="[
              {
                required: true,
                message: '医院不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'change'
              }
            ]"
          >
            <el-select
              v-model="detailForm.age"
              placeholder="请选择"
              readonly
              disabled
            ></el-select>
          </el-form-item>
          <el-form-item
            label="头像："
            prop="head"
            :rules="[
              {
                required: true,
                message: '头像不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            {{ detailForm.head }}
          </el-form-item>
          <el-form-item
            label="所在城市："
            prop="city"
            :rules="[
              {
                required: true,
                message: '所在城市不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            {{ detailForm.city }}
          </el-form-item>
          <el-form-item
            label="家属姓名："
            prop="relationName"
            :rules="[
              {
                required: true,
                message: '家属姓名不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            {{ detailForm.relationName }}
          </el-form-item>
          <el-form-item
            label="家属联系人电话："
            prop="relationPhone"
            :rules="[
              {
                required: true,
                message: '家属联系人电话不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            {{ detailForm.relationPhone }}
          </el-form-item>
          <el-form-item
            label="是否发布："
            prop="isPublish"
            :rules="[
              {
                required: true,
                message: '请选择发布状态',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'change'
              }
            ]"
          >
            <wt-form-simple-select-item
              v-model="detailForm.isPublish"
              readonly
              disabled
            />
          </el-form-item>
          <el-form-item
            label="二维码地址："
            prop="code"
            :rules="[
              {
                required: true,
                message: '二维码地址不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            {{ detailForm.code }}
          </el-form-item>
          <el-form-item
            label="详细地址："
            prop="address"
            :rules="[
              {
                required: true,
                message: '详细地址不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            {{ detailForm.address }}
          </el-form-item>
          <el-form-item
            label="邮政编码："
            prop="postalCode"
            :rules="[
              {
                required: true,
                message: '邮政编码不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            {{ detailForm.postalCode }}
          </el-form-item>
        </el-form>
      </wt-detail-section>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PatientView',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isFromLoading: false,
      detailForm: {}
    }
  },
  mounted() {
    this.requestDetail()
  },
  created() {},
  methods: {
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that
        .$wtRequest({
          url: '/modules/patient/detail',
          method: 'post',
          data: { id: that.detailData.id }
        })
        .then(resp => {
          that.detailForm = resp.data || {}
          that.isFromLoading = false
        })
        .catch(() => {
          that.isFromLoading = false
        })
    }
  },
  computed: {
    ...mapState({})
  }
}
</script>
