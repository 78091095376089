<template>
  <div class="page-detail-container">
    <!--顶部标题和页面全局按钮-->
    <wt-detail-header title="详情">
      <wt-button
        v-if="viewType == 'add'"
        v-has-any-premission="['ROLELIST:ADD']"
        type="primary"
        :loading="isButtonLoading"
        @click="onClickAdd"
        >保存
      </wt-button>
      <wt-button
        v-if="viewType == 'edit'"
        v-has-any-premission="['ROLELIST:EDIT']"
        type="primary"
        :loading="isButtonLoading"
        @click="onClickSave"
        >保存
      </wt-button>
      <wt-button @click="$emit('on-close')">返回</wt-button>
    </wt-detail-header>
    <!--内容区域-->
    <div class="wt-detail-container">
      <wt-detail-section title="基本信息" v-loading="isFromLoading">
        <el-form
          :model="detailForm"
          ref="form"
          size="small"
          class="wt-detail-section-form-center"
          label-width="30%"
        >
          <el-form-item
            label="文章标题:"
            prop="title"
            :rules="[
              {
                required: true,
                message: '输入内容不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            <el-input
              v-model="detailForm.title"
              maxlength="50"
              placeholder="请输入"
              :disabled="viewType === 'view'"
            ></el-input>
          </el-form-item>
          <el-form-item
            :rules="[
              {
                required: true,
                message: '输入内容不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
            label="栏目:"
            prop="programaGroupId2"
          >
            <div
              class="yijilanmu"
              v-if="bianhua"
              style="float: left; width: 49%"
            >
              <el-select
                @blur="huoqujiaodian"
                v-model="detailForm.programaGroupId2"
                placeholder="请选择一级栏目"
                @change="changelanmu"
              >
                <el-option
                  v-for="(item, index) in dangqianyijilanmu"
                  :key="index"
                  :label="item.title"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
            <div
              class="erjilanmu"
              v-if="bianhua"
              style="float: right; width: 49%"
            >
              <el-select
                @focus="huoqujiaodian2"
                v-model="detailForm.programaGroupId"
                placeholder="请选择二级栏目"
                @change="changelanmu"
              >
                <el-option
                  v-for="(item, index) in dangqianyijilanmu2"
                  :key="index"
                  :label="item.title"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="封面:" prop="cover">
            <el-upload
              class="avatar-uploader"
              action="https://meining.100api.com.cn/server/api/file/upload"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            注：图片比例为：16:9，图片格式为jpg/png/jpeg，大小不超过10M
          </el-form-item>
          <el-form-item label="视频上传" prop="Video">
            <!-- action必选参数, 上传的地址 -->
            <el-upload
              class="avatar-uploader el-upload--text"
              :action="
                'https://meining.100api.com.cn/server/api/file/upload'
              "
              :show-file-list="false"
              :on-success="handleVideoSuccess"
              :before-upload="beforeUploadVideo"
              :on-progress="uploadVideoProcess"
            >
              <video
                v-if="detailForm.Video != '' && videoFlag == false"
                :src="detailForm.Video"
                class="avatar"
                controls="controls"
              >
                您的浏览器不支持视频播放
              </video>
              <i
                v-else-if="detailForm.Video == '' && videoFlag == false"
                class="el-icon-plus avatar-uploader-icon"
              ></i>
              <!-- <el-progress
                v-if="videoFlag == true"
                type="circle"
                :percentage="videoUploadPercent"
                style="margin-top:30px;"
              ></el-progress> -->
            </el-upload>
            <P class="text">请保证视频格式正确，且不超过1G</P>
          </el-form-item>
          <!-- <el-progress
            v-if="videoFlag == true"
            type="circle"
            :percentage="videoUploadPercent"
            style="margin-top:30px;"
          ></el-progress> -->
          <el-form-item
            label="内容:"
          >
          <i style="position: absolute;left: -54px;color: red;">*</i>
            <!-- <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="detailForm.content"
            >
            </el-input> -->
          </el-form-item>
          <div class="quill-wrap">
            <quill-editor
              v-loading="xuanran"
              v-model="detailForm.content"
              ref="myQuillEditor"
              :options="editorOption"
            >
            </quill-editor>
          </div>
          <div></div>
          <!--  -->
          <div style="height: 150px"></div>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">保存</el-button>
            <el-button @click="quxiao">取消</el-button>
          </el-form-item>
        </el-form>
      </wt-detail-section>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import staticRoutes from '@/router/staticRoutes.js'
import {
  articleGroupList,
  articleAdd,
  articleUpdate,
  articleDetail
} from '@/api/course.js'

import { Quill } from 'vue-quill-editor'
import { container, ImageExtend, QuillWatch } from 'quill-image-extend-module'

Quill.register('modules/ImageExtend', ImageExtend)

export default {
  name: 'RoleEdit',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      xuanran: false,
      dangqianjieko: '',
      content: '',
      dangqianyijilanmu: [],
      dangqianyijilanmu2: [],
      isPermissionItemVisibility: true,
      staticRoutes: staticRoutes,
      defaultCheckedKeys: [],
      checkedModuleCodes: [],
      checkedPermissionCodes: [],
      modulePermissionMap: {},
      isButtonLoading: false,
      isFromLoading: false,
      videoFlag: false,
      bianhua: true,
      detailForm: {
        orderNum: 0,
        isPublish: 1
      },
      imageUrl: '',
      // 文本
      editorOption: {
        modules: {
          ImageExtend: {
            loading: true,
            name: 'file',
            action:
              'https://meining.100api.com.cn/server/api/file/upload',
            response: res => {
              return 'https://meining.100api.com.cn/files/' + res.data.url
            }
          },
          toolbar: {
            container: container,
            handlers: {
              image: function() {
                QuillWatch.emit(this.quill.id)
              }
            }
          }
        }
      }
    }
  },
  mounted() {
    this.requestPermissionList()
    if (this.viewType !== 'add') {
      console.log(this.viewType)
    }
  },
  watch: {
    'detailForm.programaGroupId'(data) {
      this.detailForm.programaGroupId = data
    }
  },
  created() {
    if (this.detailData) {
      this.dangqianjieko = articleUpdate
      this.detailForm = this.detailData
      this.imageUrl =
        'https://meining.100api.com.cn/files/' + this.detailForm.cover
      // 一级栏目
      this.detailForm.programaGroupId2 = this.detailData.groupOneId
      this.huoqujiaodian2()
      // 二级栏目
      this.detailForm.programaGroupId = this.detailData.groupTwoId
    } else {
      this.dangqianjieko = articleAdd
    }
    this.xuanran = true

    if (this.detailForm.title === undefined) {
      this.xuanran = false
    }
    // modules/articleGroup/list
    articleGroupList({
      formData: { isAddChild: 1, title: '' },
      pageData: {
        currentPage: 1,
        pageSize: 20,
        sortName: ' ',
        sortOrder: ' '
      }
    })
      .then(res => {
        this.dangqianyijilanmu = res.data.list
      })
      .catch(res => {
        console.log(res)
      })
    articleDetail({ id: this.detailData.id })
      .then(res => {
        this.detailForm.content = res.data.content
        this.xuanran = false
      })
      .catch(res => {
        console.log(res)
      })
  },
  methods: {
    changelanmu(data) {
      this.bianhua = false
      this.bianhua = true
    },
    beforeUploadVideo(file) {
      console.log(file.size)

      const isLt10M = file.size < 1048576
      console.log(isLt10M)
      if (
        [
          'video/mp4',
          'video/ogg',
          'video/flv',
          'video/avi',
          'video/wmv',
          'video/rmvb'
        ].indexOf(file.type) == -1
      ) {
        this.$message.error('请上传正确的视频格式')
        return false
      }
      if (!isLt10M) {
        this.$message.error('上传视频大小不能超过1G哦!')
        return false
      }
    },
    uploadVideoProcess(event, file, fileList) {
      this.videoFlag = true
      // this.videoUploadPercent = file.percentage.toFixed(0)
    },
    handleVideoSuccess(res, file) {
      // 获取上传图片地址
      this.videoFlag = false
      // this.videoUploadPercent = 0
      if (res.code === '0') {
        this.detailForm.videoUploadId = res.data.url
        this.detailForm.Video =
          'https://meining.100api.com.cn/files/' + res.data.url
      } else {
        this.$message.error('视频上传失败，请重新上传！')
      }
    },
    quxiao() {
      this.detailForm = {}
      this.detailData = {}
      this.$emit('on-close')
    },
    onSubmit() {
      articleGroupList({
        formData: { parientId: this.detailForm.programaGroupId2, title: '' },
        pageData: {
          currentPage: 1,
          pageSize: 20,
          sortName: ' ',
          sortOrder: ' '
        }
      })
        .then(res => {
          this.dangqianyijilanmu2 = res.data.list
          if (this.dangqianyijilanmu2.length === 0) {
            this.detailForm.programaGroupId = this.detailForm.programaGroupId2
            this.baocun()
          } else {
            console.log(this.detailForm.programaGroupId)
            if (this.detailForm.programaGroupId === undefined) {
              // this.detailForm.programaGroupId = this.detailForm.programaGroupId2
              this.$message.error('请选择二级栏目')
              this.detailForm.programaGroupId = ''
              return
            }
            if (this.detailForm.programaGroupId === '') {
              // this.detailForm.programaGroupId = this.detailForm.programaGroupId2
              this.$message.error('请选择二级栏目')
              this.detailForm.programaGroupId = ''
              return
            }
            this.baocun()
          }
        })
        .catch(res => {
          console.log(res)
        })
    },
    baocun() {
      if (this.detailForm.title === undefined) {
        this.$message.error('请输入文章标题')
        this.detailForm.programaGroupId = ''
        return
      }
      if (this.detailForm.title.length > 50) {
        this.$message.error('请输入文章标题最多50字')
        this.detailForm.programaGroupId = ''
        return
      }
      if (this.detailForm.programaGroupId === undefined) {
        this.$message.error('请选择文章栏目')
        this.detailForm.programaGroupId = ''
        return
      }
      if (this.detailForm.content === undefined) {
        this.$message.error('请输入文章内容')
        this.detailForm.programaGroupId = ''
        return
      }
      this.dangqianjieko(this.detailForm)
        .then(res => {
          if (res.code === '0') {
            this.$message({
              message: '保存成功',
              type: 'success'
            })
            this.requestUpdate()
          }
        })
        .catch(res => {
          console.log(res)
        })
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
      this.detailForm.cover = res.data.url
    },
    huoqujiaodian() {
      this.detailForm.programaGroupId = ''
    },
    huoqujiaodian2() {
      articleGroupList({
        formData: { parientId: this.detailForm.programaGroupId2, title: '' },
        pageData: {
          currentPage: 1,
          pageSize: 20,
          sortName: ' ',
          sortOrder: ' '
        }
      })
        .then(res => {
          this.dangqianyijilanmu2 = res.data.list
        })
        .catch(res => {
          console.log(res)
        })
    },
    onClickAdd() {
      const that = this
      this.$wtUtil
        .validateDetailFormsAndConfirm(this, '是否保存?')
        .then(() => {
          that.requestAdd()
        })
        .catch(() => {})
    },
    onClickSave() {
      const that = this
      this.$wtUtil
        .validateDetailFormsAndConfirm(this, '是否保存?')
        .then(() => {
          that.requestUpdate()
        })
        .catch(() => {})
    },
    onClickTag(code) {
      const tempCheckedPermissionCodes = this.checkedPermissionCodes || []
      const index = tempCheckedPermissionCodes.indexOf(code)
      if (index !== -1) {
        this.checkedPermissionCodes.splice(index, 1)
      } else {
        this.checkedPermissionCodes.push(code)
      }
    },
    onClickItemAction({ action, data }) {
      const moduleName = data.name.toUpperCase()
      if (action === 'currentCheckAll') {
        this.checkAllModulePermission(moduleName)
      } else if (action === 'currentUnCheckAll') {
        this.unCheckAllModulePermission(moduleName)
      } else if (action === 'checkAll') {
        const that = this
        this.staticRoutes.forEach(item => {
          const moduleName = item.name.toUpperCase()
          if (
            !item.hidden &&
            that.checkedModuleCodes.indexOf(moduleName) !== -1
          ) {
          }
        })
      } else if (action === 'unCheckAll') {
      }
    },
    checkAllModulePermission(moduleName) {
      const codes = ['VIEW', 'ADD', 'EDIT', 'DELETE']
      if (
        this.modulePermissionMap[moduleName] &&
        this.modulePermissionMap[moduleName].length > 0
      ) {
        for (let i = 0; i < this.modulePermissionMap[moduleName].length; i++) {
          codes.push(
            this.modulePermissionMap[moduleName][i].permissionCode.toUpperCase()
          )
        }
      }
      for (let i = 0; i < codes.length; i++) {
        const permissionCode = codes[i]
        if (
          this.checkedPermissionCodes.indexOf(
            moduleName + ':' + permissionCode
          ) === -1
        ) {
          this.checkedPermissionCodes.push(moduleName + ':' + permissionCode)
        }
      }
    },
    unCheckAllModulePermission(moduleName) {
      const codes = ['VIEW', 'ADD', 'EDIT', 'DELETE']
      if (
        this.modulePermissionMap[moduleName] &&
        this.modulePermissionMap[moduleName].length > 0
      ) {
        for (let i = 0; i < this.modulePermissionMap[moduleName].length; i++) {
          codes.push(
            this.modulePermissionMap[moduleName][i].permissionCode.toUpperCase()
          )
        }
      }
      for (let i = 0; i < codes.length; i++) {
        const permissionCode = codes[i]
        const permissionIndex = this.checkedPermissionCodes.indexOf(
          moduleName + ':' + permissionCode
        )
        if (permissionIndex !== -1) {
          this.checkedPermissionCodes.splice(permissionIndex, 1)
        }
      }
    },
    onModuleCurrentChanged(nodeData, node) {
      this.checkedModuleCodes = node.checkedKeys.concat(node.halfCheckedKeys)
    },
    requestPermissionList() {
      const that = this
      that
        .$wtRequest({
          url: '/modules/auth/permissionList',
          method: 'post',
          data: {}
        })
        .then(resp => {
          const tempMap = {}
          resp.data.forEach(item => {
            if (!tempMap[item.moduleName]) {
              tempMap[item.moduleName] = []
            }
            tempMap[item.moduleName].push(item)
          })
          that.modulePermissionMap = tempMap
        })
        .catch(() => {})
    },
    requestUpdate() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that
        .$wtRequest({
          url: 'modules/article/list',
          method: 'post',
          data: {
            formData: { name: '', updateTimeRange: [] },
            pageData: {
              currentPage: 1,
              pageSize: 20,
              sortName: '',
              sortOrder: ''
            }
          }
        })
        .then(resp => {
          that.isButtonLoading = false
          that.isFromLoading = false
          // that.$message({ type: 'success', message: '保存成功2' })
          that.$emit('on-refresh')
          that.$emit('on-close')
        })
        .catch(() => {
          that.isButtonLoading = false
          that.isFromLoading = false
        })
    },
    requestAdd() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that
        .$wtRequest({
          url: '/modules/auth/roleAdd',
          method: 'post',
          data: Object.assign(that.detailForm, {
            moduleList: this.checkedModuleCodes
          })
        })
        .then(resp => {
          that.isButtonLoading = false
          that.isFromLoading = false
          that.$message({ type: 'success', message: '保存成功' })
          that.$emit('on-refresh')
          that.$emit('on-close')
        })
        .catch(() => {
          that.isButtonLoading = false
          that.isFromLoading = false
        })
    }
  },
  computed: {
    moduleTreeData() {
      const that = this
      const treeData = []
      const makeChildrenFunction = staticRoute => {
        const children = []
        if (!staticRoute.hidden) {
          if (staticRoute.children && staticRoute.children.length > 0) {
            const parentRouteId = staticRoute.name.toUpperCase()
            if (that.defaultCheckedKeys.indexOf(parentRouteId) !== -1) {
              that.defaultCheckedKeys.splice(
                that.defaultCheckedKeys.indexOf(parentRouteId),
                1
              )
            }
            staticRoute.children.forEach(child => {
              const routeId = child.name.toUpperCase()
              children.push({
                id: routeId,
                label: child.meta ? child.meta.title : '',
                disabled: that.isReadMode,
                children: makeChildrenFunction(child)
              })
            })
          }
        }
        return children
      }
      this.staticRoutes.forEach(staticRoute => {
        if (!staticRoute.hidden) {
          const routeId = staticRoute.name.toUpperCase()
          treeData.push({
            id: routeId,
            label: staticRoute.meta ? staticRoute.meta.title : '',
            disabled: that.isReadMode,
            children: makeChildrenFunction(staticRoute)
          })
        }
      })
      return treeData
    },
    isReadMode() {
      return this.viewType === 'view'
    },
    ...mapState({})
  }
}
</script>
<style scoped>
.module-permission-content {
  display: flex;
  width: 100%;
}
.module-permission-content .left-content {
  width: 250px;
  padding: 0 24px;
}
.module-permission-content .right-content {
  flex: 1;
}
.module-menu-content {
  display: flex;
  width: 100%;
}
.module-menu-content .left-content {
  width: 300px;
  padding: 0 24px;
}
.module-menu-content .right-content {
  flex: 1;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.quill-wrap {
  width: 50%;
  margin-top: -40px;
  margin-left: 30%;
  height: 300px;
}
/deep/ .ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0px;
  height: 300px !important;
}
/deep/ .ql-container.ql-snow {
  border: 1px solid #ccc;
  height: 300px !important;
}
</style>
