var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-detail-container"},[_c('wt-detail-header',[(_vm.viewType == 'add')?_c('wt-button',{directives:[{name:"has-any-premission",rawName:"v-has-any-premission",value:(['ACCOUNTLIST:ADD']),expression:"['ACCOUNTLIST:ADD']"}],attrs:{"type":"primary","loading":_vm.isButtonLoading},on:{"click":_vm.onClickAdd}},[_vm._v("保存")]):_vm._e(),(_vm.viewType == 'edit')?_c('wt-button',{directives:[{name:"has-any-premission",rawName:"v-has-any-premission",value:(['ACCOUNTLIST:EDIT']),expression:"['ACCOUNTLIST:EDIT']"}],attrs:{"type":"primary","loading":_vm.isButtonLoading},on:{"click":_vm.onClickSave}},[_vm._v("保存")]):_vm._e(),_c('wt-button',{on:{"click":function($event){return _vm.$emit('on-close')}}},[_vm._v("返回")])],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isFromLoading),expression:"isFromLoading"}],staticClass:"wt-detail-container"},[_c('wt-detail-section',{attrs:{"title":"基本信息"}},[_c('el-form',{ref:"form",staticClass:"wt-detail-section-form-center",attrs:{"model":_vm.detailForm,"size":"small","label-width":"30%"}},[_c('el-form-item',{attrs:{"label":"患者姓名:","prop":"realname","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-input',{attrs:{"placeholder":"请输入","disabled":_vm.viewType == 'view'},model:{value:(_vm.detailForm.realname),callback:function ($$v) {_vm.$set(_vm.detailForm, "realname", $$v)},expression:"detailForm.realname"}})],1),_c('el-form-item',{attrs:{"label":"患者性别","prop":"gender","rules":[
            {
              required: true,
              message: '选择内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-radio-group',{model:{value:(_vm.detailForm.gender),callback:function ($$v) {_vm.$set(_vm.detailForm, "gender", $$v)},expression:"detailForm.gender"}},[_c('el-radio',{attrs:{"label":1}},[_vm._v("男")]),_c('el-radio',{attrs:{"label":2}},[_vm._v("女")])],1)],1),_c('el-form-item',{attrs:{"label":"患者年龄","prop":"age","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-input',{attrs:{"placeholder":"请输入","disabled":_vm.viewType == 'view'},model:{value:(_vm.detailForm.age),callback:function ($$v) {_vm.$set(_vm.detailForm, "age", $$v)},expression:"detailForm.age"}})],1),_c('el-form-item',{attrs:{"label":"患者手机号码","prop":"phone","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-input',{attrs:{"placeholder":"请输入","disabled":_vm.viewType == 'view'},model:{value:(_vm.detailForm.phone),callback:function ($$v) {_vm.$set(_vm.detailForm, "phone", $$v)},expression:"detailForm.phone"}})],1),_c('el-form-item',{attrs:{"label":"所在城市","prop":"city","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-input',{attrs:{"placeholder":"请输入","disabled":_vm.viewType == 'view'},model:{value:(_vm.detailForm.city),callback:function ($$v) {_vm.$set(_vm.detailForm, "city", $$v)},expression:"detailForm.city"}})],1),_c('el-form-item',{attrs:{"label":"家属姓名","prop":"relationName"}},[_c('el-input',{attrs:{"placeholder":"请输入","disabled":_vm.viewType == 'view'},model:{value:(_vm.detailForm.relationName),callback:function ($$v) {_vm.$set(_vm.detailForm, "relationName", $$v)},expression:"detailForm.relationName"}})],1),_c('el-form-item',{attrs:{"label":"家属联系人电话","prop":"relationPhone"}},[_c('el-input',{attrs:{"placeholder":"请输入","disabled":_vm.viewType == 'view'},model:{value:(_vm.detailForm.relationPhone),callback:function ($$v) {_vm.$set(_vm.detailForm, "relationPhone", $$v)},expression:"detailForm.relationPhone"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitForm(_vm.detailForm)}}},[_vm._v("保存")]),_c('el-button',{on:{"click":function($event){return _vm.$emit('on-close')}}},[_vm._v("取消")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }