import Vue from 'vue'
import Router from 'vue-router'
import WtLayout from '@/components/WtLayout/WtLayout'
// import WtContainer from '@/components/WtLayout/WtContainer'
// in development-env not use lazy-loading, because lazy-loading too many pages will cause webpack hot update too slow. so only in production use lazy-loading;
// detail: https://panjiachen.github.io/vue-element-admin-site/#/lazy-loading

Vue.use(Router)

/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
 *                                if not set alwaysShow, only more than one route under the children
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
 **/
const staticRoutes = [
  {
    hidden: true,
    path: '/dashboard',
    name: 'dashboard',
    component: WtLayout,
    meta: { title: '首页', icon: '' },
    children: [
      {
        path: '',
        name: 'dashboardIndex',
        component: () => import('@/modules/dashboard/index.vue'),
        meta: { title: '首页', icon: '' }
      }
    ]
  },
  {
    path: '',
    name: 'dashboardIndex',
    component: () => import('@/modules/dashboard/index.vue'),
    meta: { title: '首页', icon: '' }
  },

  {
    path: '/system',
    name: 'system',
    hidden: true,

    component: WtLayout,
    meta: { title: '', icon: '' },
    children: [
      {
        path: '/accountList',
        name: 'accountList',
        component: () => import('@/modules/auth/account/accountList.vue'),
        meta: { title: '医生管理', icon: '' }
      },
      {
        path: '/patientList',
        name: 'patientList',
        component: () => import('@/modules/auth/patientList/patientList.vue'),
        meta: { title: '患者管理', icon: '' }
      }
    ]
  },

  {
    path: '/patientList',
    name: 'patientList',
    component: () => import('@/modules/auth/patientList/patientList.vue'),
    meta: { title: '患者管理', icon: '' }
  },
  {
    path: '/accountList',
    name: 'accountList',
    component: () => import('@/modules/auth/account/accountList.vue'),
    meta: { title: '医生管理', icon: '' }
  },
  {
    path: '/roleList',
    name: 'roleList',
    component: WtLayout,
    meta: { title: '内容管理', icon: '' },

    children: [
      {
        path: '/setList',
        name: 'setList',
        component: () => import('@/modules/auth/role/setList.vue'),
        meta: { title: '栏目设置', icon: '' }
      },
      {
        path: '/roleList',
        name: 'roleList',
        component: () => import('@/modules/auth/role/roleList.vue'),
        meta: { title: '内容设置', icon: '' }
      }
    ]
  },
  {
    path: '/patient',
    name: 'patient',
    component: WtLayout,
    meta: { title: '商城管理', icon: '' },

    children: [
      {
        path: '/patient',
        name: 'patient',
        component: () => import('@/modules/auth/patient/patientList.vue'),

        meta: { title: '商品管理', icon: '' }
      },
      {
        path: '/order',
        name: 'order',
        component: () => import('@/modules/auth/patient/orderList.vue'),

        meta: { title: '订单管理', icon: '' }
      }
    ]
  },
  { path: '*', redirect: '/404', hidden: true }
]

export default staticRoutes
