<template>
  <div>
    <!--查询条件 开始-->
    <wt-search-form
      v-show="form.search ? form.search.visiable != 'none' : true"
    >
      <slot name="search"></slot>
      <slot name="search-buttons">
        <wt-search-form-item class="wt-search-form-buttons">
          <wt-button
            type="primary"
            :loading="form.isFromLoading"
            @click="onClickSearch"
            >查询</wt-button
          >
          <wt-button @click="onClickReset">重置</wt-button>
        </wt-search-form-item>
      </slot>
    </wt-search-form>
    <!--查询条件 结束-->
    <!--表格按钮 开始-->
    <wt-form-toolbar :form="form">
      <slot name="toolbar"></slot>
    </wt-form-toolbar>
    <!--表格按钮 结束-->
    <!--表格 开始-->
    <wt-form-table :form="form" @selection-change="handleSelectionChange">
      <slot name="table"></slot>
    </wt-form-table>
    <!--表格 结束-->
    <!--分页 开始-->
    <wt-form-pagination :form="form" @request-form-list="requestDataList" />
    <!--分页 结束-->
  </div>
</template>

<script>
export default {
  name: 'WtDataForm',
  isAutoComponent: true,
  props: [
    'form',
    'height',
    'isFromLoading',
    'requestListUrl',
    'requestDeleteUrl'
  ],
  data() {
    return {
      formData: {}
    }
  },
  created() {},
  methods: {
    exportData(fileName) {
      console.log(fileName)
      this.exportUrl = '/modules/patient/patientExport'
      console.log(this.form)
      const dataIdList = []
      if (this.form.formSelectedList == undefined) {
      } else {
        for (let i = 0; i < this.form.formSelectedList.length; i++) {
          dataIdList.push(this.form.formSelectedList[i].id)
          // console.log(i);
        }
      }
      var parent = {
        formData: this.form.formData,
        pageData: this.form.pageData
      }
      parent.pageData.pageSize = 10000

      console.log(this.form)

      console.log(parent)
      this.$wtUtil
        .downloadFile({
          url: this.exportUrl,
          data: parent,
          fileName: `${fileName}.xls`
        })
        .then(res => {
          console.log('导出成功')
        })
        .catch(() => {})
    },
    exportData2(fileName) {
      console.log(fileName)
      this.exportUrl = '/modules/doctor/patientExport'
      console.log(this.form.formSelectedList)
      const dataIdList = []
      if (this.form.formSelectedList == undefined) {
      } else {
        for (let i = 0; i < this.form.formSelectedList.length; i++) {
          dataIdList.push(this.form.formSelectedList[i].id)
          // console.log(i);
        }
      }
      var parent = {
        formData: this.form.formData,
        pageData: this.form.pageData
      }
      parent.pageData.pageSize = 10000
      console.log(parent)
      this.$wtUtil
        .downloadFile({
          url: this.exportUrl,
          data: parent,
          fileName: `${fileName}.xls`
        })
        .then(res => {
          console.log('导出成功')
        })
        .catch(() => {})
    },
    onClickSearch() {
      this.formData = Object.assign(this.form.formData, this.form.extFormData)
      this.form.pageData.currentPage = 1
      this.requestDataList()
    },
    onClickReset() {
      this.form.formData = Object.assign({}, this.form.extFormData)
      this.formData = this.form.formData
      this.requestDataList()
    },
    toView(rowData) {
      this.form.detailData = rowData
      this.form.isViewDialogVisible = true
    },
    toAdd() {
      this.form.dialogViewType = 'add'
      this.form.isEditDialogVisible = true
    },
    toEdit(rowData) {
      this.form.detailData = rowData
      this.form.dialogViewType = 'edit'
      this.form.isEditDialogVisible = true
    },
    onClickDelete(dataId) {
      const that = this
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          that.requestDataDelete([dataId])
        })
        .catch(() => {})
    },
    onClickMultipleDelete() {
      if (
        !this.form.formSelectedList ||
        this.form.formSelectedList.length <= 0
      ) {
        this.$alert('请选中至少一列数据', '提示', {
          type: 'error',
          confirmButtonText: '确定'
        })
          .then(() => {})
          .catch(() => {})
        return
      }
      const that = this
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const dataIdList = []
          that.form.formSelectedList.forEach(item => {
            dataIdList.push(item.id)
          })
          that.requestDataDelete(dataIdList)
        })
        .catch(() => {})
    },
    handleSelectionChange(val) {
      this.form.formSelectedList = val
    },
    requestDataDelete(dataIdList) {
      if (this._events && this._events['on-request-delete']) {
        this.$emit('on-request-delete')
        return
      }
      const that = this
      that.form.isFromLoading = true
      that
        .$wtRequest({
          url: this.requestDeleteUrl,
          method: 'post',
          data: {
            list: dataIdList
          }
        })
        .then(resp => {
          if (resp.code === '0') {
            that.$message({ type: 'success', message: '删除成功' })
            that.requestDataList()
          } else {
            that.form.isFromLoading = false
          }
        })
        .catch(() => {
          that.form.isFromLoading = false
        })
    },
    requestDataList() {
      if (this._events && this._events['on-request-list']) {
        this.$emit('on-request-list')
        return
      }
      const that = this
      try {
        var zhongjian = JSON.parse(
          JSON.stringify(that.formData.programaGroupId)
        )
      } catch (e) {}
      console.log(zhongjian)
      console.log(that.formData)
      // return
      // if (this.requestListUrl === '/modules/article/list') {
      //   if (that.formData.roleCode !== undefined) {
      //     that.formData.programaGroupId = JSON.parse(
      //       JSON.stringify(that.formData.roleCode)
      //     )
      //   }
      //   console.log('************255')
      //   console.log(this.requestListUrl)
      // }
      console.log(that.formData)
      that.form.isFromLoading = true
      that
        .$wtRequest({
          url: this.requestListUrl,
          method: 'post',
          data: {
            formData: that.formData,
            pageData: that.form.pageData
          }
        })
        .then(resp => {
          that.formData.programaGroupId = zhongjian
          that.form.isFromLoading = false
          that.form.formResult = resp.data
        })
        .catch(() => {
          that.form.isFromLoading = false
        })
    }
  },
  computed: {
    dataList() {
      if (this.form == null || this.form.formResult == null) {
        return []
      }
      return this.form.formResult.list
    }
  }
}
</script>

<style></style>
