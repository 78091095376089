<template>
  <div class="page-detail-container">
    <!--顶部标题和页面全局按钮-->
    <wt-detail-header title="详情">
      <wt-button
        v-if="viewType == 'add'"
        v-has-any-premission="['CONSULTRECORD:ADD']"
        type="primary"
        :loading="isButtonLoading"
        @click="onClickAdd"
        >保存</wt-button
      >
      <wt-button
        v-if="viewType == 'edit'"
        v-has-any-premission="['CONSULTRECORD:EDIT']"
        type="primary"
        :loading="isButtonLoading"
        @click="onClickSave"
        >保存</wt-button
      >
      <wt-button @click="$emit('on-close')">返回</wt-button>
    </wt-detail-header>
    <!--内容区域-->
    <div class="wt-detail-container" v-loading="isFromLoading">
      <wt-detail-section title="基本信息">
        <el-form
          :model="detailForm"
          ref="form"
          size="small"
          class="wt-detail-section-form-center"
          label-width="30%"
        >
          <el-form-item
            label="患者ID："
            prop="patientId"
            :rules="[
              {
                required: true,
                message: '输入内容不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'change'
              }
            ]"
          >
            <el-select
              v-model="detailForm.patientId"
              placeholder="请选择"
            ></el-select>
          </el-form-item>
          <el-form-item
            label="医生ID："
            prop="doctorId"
            :rules="[
              {
                required: true,
                message: '输入内容不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'change'
              }
            ]"
          >
            <el-select
              v-model="detailForm.doctorId"
              placeholder="请选择"
            ></el-select>
          </el-form-item>
          <el-form-item
            label="患者提问："
            prop="question"
            :rules="[
              {
                required: true,
                message: '输入内容不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            <el-input
              v-model="detailForm.question"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="患病时长："
            prop="fallIllTime"
            :rules="[
              {
                required: true,
                message: '输入内容不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            <el-input
              v-model="detailForm.fallIllTime"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="病情描述："
            prop="disease Description"
            class="col-editor"
            :rules="[
              {
                required: true,
                validator: $wtValidator.ValidatorWtEditor,
                message: '输入内容不能为空',
                trigger: 'change'
              }
            ]"
          >
            <WtTinymce v-model="detailForm.disease"></WtTinymce>
          </el-form-item>
          <el-form-item
            label="疾病名称："
            prop="diseaseName"
            :rules="[
              {
                required: true,
                message: '输入内容不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            <el-input
              v-model="detailForm.diseaseName"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="医生回复："
            prop="answer"
            class="col-editor"
            :rules="[
              {
                required: true,
                validator: $wtValidator.ValidatorWtEditor,
                message: '输入内容不能为空',
                trigger: 'change'
              }
            ]"
          >
            <WtTinymce v-model="detailForm.answer"></WtTinymce>
          </el-form-item>
          <el-form-item
            label="回复时间："
            prop="replyTime"
            :rules="[
              {
                required: true,
                message: '输入内容不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            <el-date-picker
              v-model="detailForm.replyTime"
              type="datetime"
              placeholder="请选择"
            ></el-date-picker>
          </el-form-item>
        </el-form>
      </wt-detail-section>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ConsultRecordEdit',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isButtonLoading: false,
      isFromLoading: false,
      detailForm: {}
    }
  },
  mounted() {
    if (this.viewType !== 'add') {
      this.requestDetail()
    }
  },
  created() {},
  methods: {
    onClickAdd() {
      const that = this
      this.$wtUtil
        .validateDetailFormsAndConfirm(this, '是否保存?')
        .then(() => {
          that.requestAdd()
        })
        .catch(() => {})
    },
    onClickSave() {
      const that = this
      this.$wtUtil
        .validateDetailFormsAndConfirm(this, '是否保存?')
        .then(() => {
          that.requestUpdate()
        })
        .catch(() => {})
    },
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that
        .$wtRequest({
          url: '/modules/consultRecord/detail',
          method: 'post',
          data: { id: that.detailData.id }
        })
        .then(resp => {
          that.detailForm = resp.data || {}
          that.isFromLoading = false
        })
        .catch(() => {
          that.isFromLoading = false
        })
    },
    requestUpdate() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that
        .$wtRequest({
          url: '/modules/consultRecord/update',
          method: 'post',
          data: that.detailForm
        })
        .then(resp => {
          that.isButtonLoading = false
          that.isFromLoading = false
          that.$message({ type: 'success', message: '保存成功' })
          that.$emit('on-refresh')
          that.$emit('on-close')
        })
        .catch(() => {
          that.isButtonLoading = false
          that.isFromLoading = false
        })
    },
    requestAdd() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that
        .$wtRequest({
          url: '/modules/consultRecord/add',
          method: 'post',
          data: that.detailForm
        })
        .then(resp => {
          that.isButtonLoading = false
          that.isFromLoading = false
          that.$message({ type: 'success', message: '保存成功' })
          that.$emit('on-refresh')
          that.$emit('on-close')
        })
        .catch(() => {
          that.isButtonLoading = false
          that.isFromLoading = false
        })
    }
  },
  computed: {
    ...mapState({})
  }
}
</script>
