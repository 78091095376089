import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import app from './system/app'
import auth from './system/auth'
import wtTabs from './system/wtTabs'

/*  插件 STORE 开始 */
const plugins = []
const pluginsStores = require.context('@/plugins', true, /store\/index\.js$/)
pluginsStores.keys().forEach(key => {
  const objs = pluginsStores(key).default
  if (objs && objs.length > 0) {
    objs.forEach(obj => {
      if (obj && obj.name) {
        plugins[obj.name] = obj
      }
    })
  }
})
/*  插件 STORE 结束 */
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    auth,
    wtTabs
  },
  getters
})

export default store
