var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-detail-container"},[_c('wt-detail-header',{attrs:{"title":"详情"}},[(_vm.viewType == 'add')?_c('wt-button',{directives:[{name:"has-any-premission",rawName:"v-has-any-premission",value:(['CONSULTRECORD:ADD']),expression:"['CONSULTRECORD:ADD']"}],attrs:{"type":"primary","loading":_vm.isButtonLoading},on:{"click":_vm.onClickAdd}},[_vm._v("保存")]):_vm._e(),(_vm.viewType == 'edit')?_c('wt-button',{directives:[{name:"has-any-premission",rawName:"v-has-any-premission",value:(['CONSULTRECORD:EDIT']),expression:"['CONSULTRECORD:EDIT']"}],attrs:{"type":"primary","loading":_vm.isButtonLoading},on:{"click":_vm.onClickSave}},[_vm._v("保存")]):_vm._e(),_c('wt-button',{on:{"click":function($event){return _vm.$emit('on-close')}}},[_vm._v("返回")])],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isFromLoading),expression:"isFromLoading"}],staticClass:"wt-detail-container"},[_c('wt-detail-section',{attrs:{"title":"基本信息"}},[_c('el-form',{ref:"form",staticClass:"wt-detail-section-form-center",attrs:{"model":_vm.detailForm,"size":"small","label-width":"30%"}},[_c('el-form-item',{attrs:{"label":"患者ID：","prop":"patientId","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.detailForm.patientId),callback:function ($$v) {_vm.$set(_vm.detailForm, "patientId", $$v)},expression:"detailForm.patientId"}})],1),_c('el-form-item',{attrs:{"label":"医生ID：","prop":"doctorId","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.detailForm.doctorId),callback:function ($$v) {_vm.$set(_vm.detailForm, "doctorId", $$v)},expression:"detailForm.doctorId"}})],1),_c('el-form-item',{attrs:{"label":"患者提问：","prop":"question","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.detailForm.question),callback:function ($$v) {_vm.$set(_vm.detailForm, "question", $$v)},expression:"detailForm.question"}})],1),_c('el-form-item',{attrs:{"label":"患病时长：","prop":"fallIllTime","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.detailForm.fallIllTime),callback:function ($$v) {_vm.$set(_vm.detailForm, "fallIllTime", $$v)},expression:"detailForm.fallIllTime"}})],1),_c('el-form-item',{staticClass:"col-editor",attrs:{"label":"病情描述：","prop":"disease Description","rules":[
            {
              required: true,
              validator: _vm.$wtValidator.ValidatorWtEditor,
              message: '输入内容不能为空',
              trigger: 'change'
            }
          ]}},[_c('WtTinymce',{model:{value:(_vm.detailForm.disease),callback:function ($$v) {_vm.$set(_vm.detailForm, "disease", $$v)},expression:"detailForm.disease"}})],1),_c('el-form-item',{attrs:{"label":"疾病名称：","prop":"diseaseName","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.detailForm.diseaseName),callback:function ($$v) {_vm.$set(_vm.detailForm, "diseaseName", $$v)},expression:"detailForm.diseaseName"}})],1),_c('el-form-item',{staticClass:"col-editor",attrs:{"label":"医生回复：","prop":"answer","rules":[
            {
              required: true,
              validator: _vm.$wtValidator.ValidatorWtEditor,
              message: '输入内容不能为空',
              trigger: 'change'
            }
          ]}},[_c('WtTinymce',{model:{value:(_vm.detailForm.answer),callback:function ($$v) {_vm.$set(_vm.detailForm, "answer", $$v)},expression:"detailForm.answer"}})],1),_c('el-form-item',{attrs:{"label":"回复时间：","prop":"replyTime","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-date-picker',{attrs:{"type":"datetime","placeholder":"请选择"},model:{value:(_vm.detailForm.replyTime),callback:function ($$v) {_vm.$set(_vm.detailForm, "replyTime", $$v)},expression:"detailForm.replyTime"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }