<template>
  <div class="page-container">
    <wt-data-form
      ref="dataForm"
      :form.sync="form"
      request-list-url="/modules/gift/list"
      request-delete-url="/modules/gift/delete"
      request-detail-url="/modules/gift/detail"
    >
      <template slot="search">
        <wt-search-form-item
          title="商品名称"
          v-model="form.formData.giftName"
          type="input"
        />
        <wt-search-form-item
          title="编号"
          v-model="form.formData.giftNo"
          type="input"
        />
      </template>
      <template slot="toolbar">
        <!-- <wt-button
          type="primary"
          @click="$refs.dataForm.exportData2('医生信息')"
          >导出医生信息</wt-button
        > -->
        <wt-button type="primary" @click="toAdddoy">添加商品</wt-button>
      </template>
      <template slot="table">
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column
          prop="giftNo"
          label="编号"
          width="70"
        ></el-table-column>
        <el-table-column
          prop="giftName"
          label="商品名称"
          width="100"
        ></el-table-column>
        <el-table-column prop="cover" label="商品图片" width="100">
          <template slot-scope="scope">
            <img
              style="width: 96px;height: 96px;"
              :src="
                'https://meining.100api.com.cn/files/' +
                  scope.row.cover
              "
              alt=""
            />
            <!-- <img
              style="width: 96px;height: 96px;"
              src="../../../assets/logo.png"
              alt=""
            /> -->
            <!-- {{ scope.row.cover }} -->
          </template>
        </el-table-column>
        <el-table-column
          prop="point"
          label="兑换所需积分"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="inventoryNum"
          label="库存数量"
          width="100"
        ></el-table-column>
        <el-table-column prop="createTime" label="添加时间"></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <span @click="bianji(scope.row)" class="chouzuospan">编辑</span>
            <span
              style="color:#FF5B5B"
              @click="$refs.dataForm.onClickDelete(scope.row.id)"
              class="chouzuospan"
              >删除</span
            >
          </template>
        </el-table-column>
      </template>
    </wt-data-form>
    <!--弹层 开始-->
    <!-- 新增 -->
    <wt-detail-page :visible.sync="form.isViewDialogVisible">
      <patientList3
        :detailData="form.detailData"
        @on-close="form.isViewDialogVisible = false"
        view-type="add"
      />
    </wt-detail-page>
    <!-- 修改 -->
    <wt-detail-page :visible.sync="form.isEditDialogVisible">
      <patientList2
        :detailData="form.detailData"
        :view-type="form.dialogViewType"
        @on-refresh="$refs.dataForm.requestDataList()"
        @on-close="form.isEditDialogVisible = false"
      />
    </wt-detail-page>
    <wt-detail-page :visible.sync="form.isEditDialogVisible3">
      <patienArchivesa
        :detailData="form.detailData"
        :view-type="form.dialogViewType"
        @on-refresh="$refs.dataForm.requestDataList()"
        @on-close="form.isEditDialogVisible3 = false"
      />
    </wt-detail-page>
    <!--弹层 结束-->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { doctorDelete } from '@/api/course.js'
export default {
  name: 'accountList',
  data() {
    return {
      areaList: [],
      form: {
        isFromLoading: false,
        isViewDialogVisible: false,
        isEditDialogVisible: false,
        isEditDialogVisible2: false,
        isEditDialogVisible3: false,
        isEditDialogVisible4: false,
        dialogViewType: 'edit',
        detailData: {},
        formData: {
          list: [],
          phone: '',
          realname: ''
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        formResult: {}
      }
    }
  },
  mounted() {
    this.$refs.dataForm.requestDataList()
  },
  created() {},
  methods: {
    handleClick(row) {
      console.log(row)
    },
    toAdddoy() {
      this.form.isViewDialogVisible = true
    },

    bianji(data) {
      this.form.isEditDialogVisible = true
      console.log(data)
      this.form.detailData = data
    },
    del(data) {
      var dellist = {
        list: [data]
      }
      console.log(dellist)
      doctorDelete(dellist)
        .then(res => {
          console.log(res)
          if (res.code === '0') {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            // this.$router.push({ path: '/system/patientList' })
            this.$refs.dataForm.requestDataList()
          }
        })
        .catch(res => {
          console.log(res)
        })
      console.log(data)
    }
  },
  computed: {
    ...mapState({})
  }
}
</script>

<style lang="scss" scoped>
.chouzuospan {
  color: #0180cc;
  font-size: 14px;
  margin-right: 14px;
  cursor: pointer;
}
</style>
