<template>
  <div :class="{fullscreen:fullscreen}" class="tinymce-container editor-container" v-loading="isLoading">
    <textarea :id="tinymceId" class="tinymce-textarea" />
    <div class="editor-custom-btn-container">
      <div class="upload-container editor-upload-btn">
        <input :ref="'file-' + tinymceId" type="file" style="display: none;" accept="image/png,image/jpeg,image/gif,image/jpg" @change="onWtImageFilePicker" multiple/>
      </div>
    </div>
  </div>
</template>

<script>
import plugins from './plugins'
import toolbar from './toolbar'
import request from '@/utils/request'
import wtUploadRequest from '@/utils/wtUploadRequest'

export default {
  name: 'WtTinymce',
  components: { },
  props: {
    id: {
      type: String,
      default: function() {
        return 'vue-tinymce-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '')
      }
    },
    value: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    toolbar: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    menubar: {
      type: String,
      default: 'file edit insert view format table'
    },
    height: {
      type: Number,
      required: false,
      default: 360
    }
  },
  data() {
    return {
      wtBaseApi: process.env.BASE_API,
      wtBaseFileServer: process.env.FILE_SERVER_URL,
      isLoading: false,
      hasChange: false,
      hasInit: false,
      tinymceId: this.id,
      fullscreen: false,
      languageTypeList: {
        'en': 'en',
        'zh': 'zh_CN'
      }
    }
  },
  computed: {
    language() {
      return this.languageTypeList[this.$store.getters.language]
    }
  },
  watch: {
    value(val, oldVal) {
      if (val !== oldVal && (!oldVal || oldVal === '')) {
        this.$nextTick(() =>
          window.tinymce.get(this.tinymceId).setContent(val || ''))
        return
      }
      if (!this.hasChange && this.hasInit) {
        this.$nextTick(() =>
          window.tinymce.get(this.tinymceId).setContent(val || ''))
      }
      if (this.hasChange && this.hasInit) {
        this.$nextTick(() =>
          window.tinymce.get(this.tinymceId).setContent(val || ''))
      }
    },
    readonly(val, oldVal) {
      if (val !== oldVal) {
        this.$nextTick(() => {
          this.destroyTinymce()
          this.$nextTick(() => this.initTinymce())
        })
      }
    },
    language() {
      this.destroyTinymce()
      this.$nextTick(() => this.initTinymce())
    }
  },
  mounted() {
    this.initTinymce()
  },
  activated() {
    this.initTinymce()
  },
  deactivated() {
    this.destroyTinymce()
  },
  destroyed() {
    this.destroyTinymce()
  },
  methods: {
    initTinymce() {
      const _this = this
      window.tinymce.init({
        readonly: this.readonly,
        language: this.language,
        selector: `#${this.tinymceId}`,
        height: this.height,
        body_class: 'panel-body ',
        object_resizing: false,
        toolbar: this.toolbar.length > 0 ? this.toolbar : toolbar,
        menubar: this.menubar,
        plugins: plugins,
        end_container_on_empty_block: true,
        code_dialog_height: 450,
        code_dialog_width: 1000,
        advlist_bullet_styles: 'square',
        advlist_number_styles: 'default',
        // imagetools_cors_hosts: [],
        default_link_target: '_blank',
        link_title: false,
        powerpaste_word_import: 'propmt', //  clean,propmt,merge
        powerpaste_html_import: 'propmt', //  clean,propmt,merge
        powerpaste_allow_local_images: true,
        paste_data_images: true,
        // images_upload_handler: (blobInfo, success, failure) => {
        //   this.$refs['wtUpload'].uploadFile(blobInfo, success, failure)
        // },
        wtImagePicker: (wtImageCallback) => {
          _this.wtImageCallback = wtImageCallback
          _this.$refs['file-' + this.tinymceId].click()
        },
        nonbreaking_force_tab: true,
        paste_preprocess: (plugin, args) => {
          console.log('paste_preprocess', plugin, args)
        },
        // init_instance_callback: editor => {
        //   if (_this.value) {
        //     editor.setContent(_this.value)
        //   }
        //   _this.hasInit = true
        //   editor.on('NodeChange Change KeyUp SetContent', () => {
        //     this.hasChange = true
        //     this.$emit('input', editor.getContent())
        //   })
        // },
        setup(editor) {
          editor.on('FullscreenStateChanged', (e) => {
            _this.fullscreen = e.state
          })
          editor.on('change', function(e) {
            const val = editor.getContent()
            _this.$emit('input', val)
          })
        }
        // content_style: `
        //   *                         { padding:0; margin:0; }
        //   html, body                { height:100%; }
        //   img                       { max-width:100%; display:block;height:auto; }
        //   a                         { text-decoration: none; }
        //   iframe                    { width: 100%; }
        //   p                         { line-height:1.6; margin: 0px; }
        //   table                     { word-wrap:break-word; word-break:break-all; max-width:100%; border:none; border-color:#999; }
        //   .mce-object-iframe        { width:100%; box-sizing:border-box; margin:0; padding:0; }
        //   ul,ol                     { list-style-position:inside; }
        // `
      }).then(resolve => {
        if (_this.value) {
          _this.setContent(_this.value)
        }
      })
    },
    destroyTinymce() {
      const tinymce = window.tinymce.get(this.tinymceId)
      if (this.fullscreen) {
        tinymce.execCommand('mceFullScreen')
      }

      if (tinymce) {
        tinymce.destroy()
      }
    },
    setContent(value) {
      window.tinymce.get(this.tinymceId).setContent(value)
    },
    getContent() {
      return window.tinymce.get(this.tinymceId).getContent()
    },
    //  上传图片完成
    handleOnCustomFinish(el, file) {
      const _this = this
      if (this.$wtUtil.isNotBlank(file.url)) {
        window.tinymce.get(_this.tinymceId).insertContent(`<img style="width: 100%;height: auto;" src="${file.url}" >`)
      }
    },
    onWtImageFilePicker(event) {
      const _this = this
      _this.isLoading = true
      _this.$wtUtil.getFileListMd5(event.target.files).then((files) => {
        //  获取文件MD5，检查是否可以秒传
        return _this.requestCheckFiles(files)
      }).then((files) => {
        //  检查秒传结束，上传未上传的文件
        _this.requestUploadFiles(files)
      }).catch(err => {
        _this.isLoading = false
        _this.$message.warning('上传失败')
        console.log('err', err)
      })
      _this.$refs['file-' + this.tinymceId].value = ''
    },
    requestCheckFiles(files) {
      const fileMd5Array = []
      files.forEach((file) => {
        fileMd5Array.push(file.md5)
      })
      const fileMd5String = fileMd5Array.join(',')
      return new Promise((resolve, reject) => {
        request({
          url: '/modules/authFile/checkFiles',
          method: 'post',
          data: { fileMd5: fileMd5String }
        }).then(resp => {
          if (resp.code === '0' && resp.data && resp.data.length > 0) {
            const md5Map = {}
            for (let i = 0; i < resp.data.length; i++) {
              const md5Data = resp.data[i]
              md5Map[md5Data.fileMd5] = md5Data
            }
            files.forEach((sourceFile) => {
              const md5Data = md5Map[sourceFile.md5]
              if (md5Data) {
                const md5Data = md5Map[sourceFile.md5]
                sourceFile.fileUrl = md5Data.fileUrl
                sourceFile.fileName = md5Data.fileName
              }
            })
          }
          resolve(files)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    requestUploadFiles(files) {
      const _this = this
      const tempDict = {}
      files.forEach(file => {
        if (!file.fileUrl) {
          tempDict[file.md5] = file
        }
      })
      if (tempDict.length <= 0) {
        _this.wtImageCompleteUploadFiles(files)
        return
      }
      let promise = Promise.resolve()
      Object.keys(tempDict).forEach((key, index) => {
        const file = tempDict[key]
        promise = promise.then(() => {
          return new Promise((resolve, reject) => {
            const formData = new FormData()
            formData.append('file', file, file.name)
            wtUploadRequest.post('/modules/authFile/uploadFile', formData).then((resp) => {
              if (resp.code === '0' && resp.data) {
                files.forEach(sourceFile => {
                  if (sourceFile.md5 && sourceFile.md5 === file.md5) {
                    sourceFile.fileUrl = resp.data.fileUrl
                    sourceFile.fileName = file.name
                  }
                })
              }
              resolve(files)
            }).catch(() => {
              console.log('wtUploadRequest fail')
              resolve(files)
            })
          })
        })
      })
      promise.then((data) => {
        _this.wtImageCompleteUploadFiles(files)
        _this.isLoading = false
      }).catch(() => {
        this.isLoading = false
        _this.$message.warning('上传失败')
      })
    },
    wtImageCompleteUploadFiles(files) {
      const fileList = []
      const _this = this
      files.forEach((file) => {
        const item = {}
        item.name = file.name
        if (file && file.fileUrl) {
          item.fileUrl = _this.wtBaseFileServer + '/' + file.fileUrl
        }
        fileList.push(item)
      })
      this.wtImageCallback(fileList)
    }
  }
}
</script>

<style scoped>
  .tinymce-container {
    position: relative;
  }
  .tinymce-container>>>.mce-fullscreen {
    z-index: 10000;
  }
  .tinymce-textarea {
    visibility: hidden;
    z-index: -1;
  }
  .editor-custom-btn-container {
    position: absolute;
    right: 4px;
    top: 4px;
    /*z-index: 2005;*/
  }
  .fullscreen .editor-custom-btn-container {
    z-index: 10000;
    position: fixed;
  }
  .editor-upload-btn {
    display: inline-block;
  }
</style>
