import { render, staticRenderFns } from "./patienArchives2.vue?vue&type=template&id=141e8abb&scoped=true&"
import script from "./patienArchives2.vue?vue&type=script&lang=js&"
export * from "./patienArchives2.vue?vue&type=script&lang=js&"
import style1 from "./patienArchives2.vue?vue&type=style&index=1&id=141e8abb&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "141e8abb",
  null
  
)

export default component.exports