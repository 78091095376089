import WtBreadcrumb from './WtBreadcrumb'
import WtFormInputs from './WtFormInputs'
import WtFormSimpleSelectItem from './WtFormItem/WtFormSimpleSelectItem'
import WtFormButtons from './WtFormButtons'
import WtFormTable from './WtFormTable'
import WtFormTableList from './WtFormTableList'
import WtFormPagination from './WtFormPagination'
import WtDetailPage from './WtDetailPage'
import WtDetailFormSection from './WtDetailFormSection'
import WtDetailFormBlankSection from './WtDetailFormBlankSection'
import WtDetailBlankSection from './WtDetailBlankSection'
import WtSection from './WtSection'
import WtDialog from './WtDialog'
import WtDictSelect from './WtDictSelect'
import WtDetailFormButtons from './WtDetailFormButtons'
import WtAuth from './WtAuth'
import WtUpload from './WtUpload'
import WtImageUpload from './WtImageUpload'
import WtFileUpload from './WtFileUpload'
import WtChunkFileUpload from './WtChunkFileUpload'
import WtOssUpload from './WtOssUpload'
import WtTinymce from './WtTinymce'
import WtHeader from './WtLayout/WtHeader'
import WtSide from './WtLayout/WtSide'
import WtSideMenuItem from './WtLayout/WtSideMenuItem'
import WtTabs from './WtLayout/WtTabs'
import WtButton from './WtButton'
import WtTextButton from './WtTextButton'
import WtSearchForm from './WtSearchForm'
import WtSearchFormItem from './WtSearchFormItem'
import WtFormToolbar from './WtFormToolbar'
import WtFrame from './WtFrame'
import WtDetailHeader from './WtDetailHeader'
import WtDetailTabHeader from './WtDetailTabHeader'
import WtDataForm from './WtDataForm'
import WtTableColumn from './WtFormTable/WtTableColumn'
import WtTableColumnImage from './WtFormTable/WtTableColumnImage'
import WtDetailSection from './WtDetailSection'
import WtDetailSectionItem from './WtDetailSection/WtDetailSectionItem'
import WtDetailForm from './WtDetailForm'

export default {
  WtBreadcrumb,
  WtFormInputs,
  WtFormSimpleSelectItem,
  WtFormButtons,
  WtFormTable,
  WtFormTableList,
  WtFormPagination,
  WtDetailPage,
  WtDetailFormSection,
  WtDetailFormBlankSection,
  WtDetailBlankSection,
  WtSection,
  WtDialog,
  WtDictSelect,
  WtDetailFormButtons,
  WtAuth,
  WtUpload,
  WtTinymce,
  WtImageUpload,
  WtFileUpload,
  WtChunkFileUpload,
  WtOssUpload,
  WtHeader,
  WtSide,
  WtSideMenuItem,
  WtTabs,
  WtButton,
  WtTextButton,
  WtSearchForm,
  WtSearchFormItem,
  WtFormToolbar,
  WtFrame,
  WtDetailHeader,
  WtDetailTabHeader,
  WtDataForm,
  WtTableColumn,
  WtTableColumnImage,
  WtDetailSection,
  WtDetailSectionItem,
  WtDetailForm
}
