var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-detail-container"},[_c('wt-detail-header',{attrs:{"title":"详情"}},[_c('wt-button',{on:{"click":function($event){return _vm.$emit('on-close')}}},[_vm._v("返回")])],1),_c('div',{staticClass:"wt-detail-container"},[_c('wt-detail-section',{attrs:{"title":"基本信息"}},[_c('el-form',{ref:"form",staticClass:"wt-detail-section-form-center",attrs:{"model":_vm.detailForm,"size":"small","label-width":"30%"}},[_c('el-form-item',{attrs:{"label":"患者ID：","prop":"patientId","rules":[
            {
              required: true,
              message: '患者ID不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择","readonly":"","disabled":""},model:{value:(_vm.detailForm.patientId),callback:function ($$v) {_vm.$set(_vm.detailForm, "patientId", $$v)},expression:"detailForm.patientId"}})],1),_c('el-form-item',{attrs:{"label":"医生ID：","prop":"doctorId","rules":[
            {
              required: true,
              message: '医生ID不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择","readonly":"","disabled":""},model:{value:(_vm.detailForm.doctorId),callback:function ($$v) {_vm.$set(_vm.detailForm, "doctorId", $$v)},expression:"detailForm.doctorId"}})],1),_c('el-form-item',{attrs:{"label":"患者提问：","prop":"question","rules":[
            {
              required: true,
              message: '患者提问不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_vm._v(" "+_vm._s(_vm.detailForm.question)+" ")]),_c('el-form-item',{attrs:{"label":"患病时长：","prop":"fallIllTime","rules":[
            {
              required: true,
              message: '患病时长不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_vm._v(" "+_vm._s(_vm.detailForm.fallIllTime)+" ")]),_c('el-form-item',{staticClass:"col-editor",attrs:{"label":"病情描述：","prop":"disease Description","rules":[
            {
              required: true,
              message: '病情描述不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('WtTinymce',{attrs:{"readonly":"","disabled":""},model:{value:(_vm.detailForm.disease),callback:function ($$v) {_vm.$set(_vm.detailForm, "disease", $$v)},expression:"detailForm.disease"}})],1),_c('el-form-item',{attrs:{"label":"疾病名称：","prop":"diseaseName","rules":[
            {
              required: true,
              message: '疾病名称不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_vm._v(" "+_vm._s(_vm.detailForm.diseaseName)+" ")]),_c('el-form-item',{staticClass:"col-editor",attrs:{"label":"医生回复：","prop":"answer","rules":[
            {
              required: true,
              message: '医生回复不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('WtTinymce',{attrs:{"readonly":"","disabled":""},model:{value:(_vm.detailForm.answer),callback:function ($$v) {_vm.$set(_vm.detailForm, "answer", $$v)},expression:"detailForm.answer"}})],1),_c('el-form-item',{attrs:{"label":"回复时间：","prop":"replyTime","rules":[
            {
              required: true,
              message: '回复时间不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_vm._v(" "+_vm._s(_vm.detailForm.replyTime)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }