<template>
  <div>
    <el-upload v-if="!isReadMode" class="avatar-uploader"
               :accept="accept"
               :on-success="onFileUploadSuccess"
               :action="uploadUrl" :show-file-list="false"  :before-upload="beforeUpload">
      <el-button size="small" type="primary">点击上传</el-button>
    </el-upload>
    <div v-if="!isReadMode" class="el-upload__tip">{{tip}}</div>
    <div class="upload-image-list">
      <div v-for="(item, index) in fileList" :key="index" class="upload-image-item">
        <el-image class="upload-image-item-img" fit="cover" :src="item.url" :preview-src-list="[item.url]"></el-image>
        <div class="upload-image-item-ext">
          <span>{{item.name}}</span>
        </div>
        <div v-if="!isReadMode" class="upload-image-item-delete" @click="onDeleteItem(index)"><i class="el-icon-close"></i></div>
      </div>
    </div>
  </div>
</template>

<script>
import Emitter from 'element-ui/src/mixins/emitter'
import SparkMD5 from 'spark-md5'
export default {
  name: 'WtImageUpload',
  mixins: [Emitter],
  props: {
    value: {},
    type: {
      type: String,
      default: 'string'// 支持string（单独的url）、list（url的列表）、json（json的列表）
    },
    readonly: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: null
    },
    tip: {
      type: String,
      default: '只能上传jpg/png文件，且不超过500kb'
    },
    accept: {
      type: String,
      default: 'image/*'
    }
  },
  data() {
    return {
      isInited: false,
      uploadUrl: window.wtConst.BASE_API + '/modules/resourceFile/uploadFile',
      fileList: [],
      imageUrl: ''
    }
  },
  watch: {
    value: {
      handler() {
        if (typeof (this.value) === 'string' && !this.isInited && this.fileList.length <= 0) {
          this.isInited = true
          this.fileList.push({ name: '', url: this.$wtUtil.buildImageUrl(this.value), fileUrl: this.value })
          this.updateValue()
        }
        this.isInited = true
        this.dispatch('ElFormItem', 'el.form.change', this.value)
      },
      deep: true
    }
  },
  methods: {
    onFileUploadSuccess(response, file, fileList) {
      if (response.code !== '0') {
        this.$message({ type: 'error', message: '上传失败' })
        return
      }
      const that = this
      const fileResult = response.data
      that.fileList.push({ name: fileResult.name, url: this.$wtUtil.buildImageUrl(fileResult.url), fileUrl: fileResult.url })
      that.updateValue()
    },
    beforeUpload(file) {
      const that = this
      if (this.fileList && this.fileList.length >= this.limit) {
        this.$message({ type: 'warning', message: `最多可以上传${this.limit}个文件` })
        return false
      }
      return that.checkFileMd5(file).then((md5File) => {
        return that.requestCheckFile([md5File])
      })
    },
    onDeleteItem(index) {
      this.fileList.splice(index, 1)
      this.updateValue()
    },
    updateValue() {
      if (this.type === 'string') {
        if (this.fileList && this.fileList.length > 0) {
          this.$emit('input', this.fileList[0].fileUrl)
        } else {
          this.$emit('input', '')
        }
        return
      }
      this.$emit('input', this.fileList)
    },
    checkFileMd5(file) {
      const that = this
      return new Promise((resolve, reject) => {
        try {
          that.getFileMd5(file, (md5File, data) => {
            if (data.resultCode === 'SUCCESS') {
              file.md5 = data.fileMd5
              resolve(file)
            } else {
              reject(new Error('文件检查失败'))
            }
          })
        } catch (e) {
          reject(new Error(e))
        }
      })
    },
    requestCheckFile(checkFileList) {
      const that = this
      const singleMd5 = checkFileList[0].md5
      return new Promise((resolve, reject) => {
        that.$wtRequest({
          url: '/modules/resourceFile/checkFileMD5s',
          method: 'post',
          data: { list: [singleMd5] }
        }).then((resp) => {
          if (resp.code === '0' && resp.data && resp.data[singleMd5]) {
            const fileResult = resp.data[singleMd5]
            that.fileList.push({ name: fileResult.name, url: that.$wtUtil.buildImageUrl(fileResult.url), fileUrl: fileResult.url })
            that.updateValue()
            return reject(new Error(''))
          }
          return resolve()
        }).catch((e) => {
          return reject(new Error(e))
        })
      })
    },
    requestFileUpload(arg) {
      console.log('requestFileUpload', arg)
    },
    getFileMd5(file, callback) {
      const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
      const chunkSize = 1024 * 1024 * 2
      const chunks = Math.ceil(file.size / chunkSize)
      let currentChunk = 0
      const spark = new SparkMD5.ArrayBuffer()
      const fileReader = new FileReader()

      fileReader.onload = function(e) {
        spark.append(e.target.result)
        currentChunk++
        if (currentChunk < chunks) {
          loadNext()
        } else {
          const fileMd5 = spark.end()
          callback(file, { resultCode: 'SUCCESS', fileMd5: fileMd5 })
        }
      }

      fileReader.onerror = function(err) {
        callback(file, { resultCode: 'FAIL', msg: err })
      }

      function loadNext() {
        const start = currentChunk * chunkSize
        const end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize
        fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
      }

      loadNext()
    }
  },
  computed: {
    isReadMode() {
      return (this.readonly === '') || this.readonly
    }
  }
}
</script>

<style lang="scss" scoped>
  .upload-image-list{display: flex;flex-wrap: wrap;}
  .upload-image-item{
    position: relative;
    width: 100%; height: 100px;max-width: 250px;background-color: #FFFFFF;border-radius: 4px;border: 1px solid #c0ccda;margin: 8px 16px;display: flex;align-items: center;justify-content: space-between;
  }
  .upload-image-item-delete{
    position: absolute;right: 10px;top: 0;cursor: pointer;
    :hover,:active{opacity: 0.75;}
  }
  .upload-image-item-img{width: 80px; height: 80px;margin: 10px;}
  .upload-image-item-ext{
    width: calc(100% - 110px);display: flex;flex-direction: column;justify-content: center;margin-right: 10px;
    > span{line-height: 1;}
  }
</style>
