<template>
  <div class="page-container">
    <wt-data-form
      ref="dataForm"
      :form.sync="form"
      request-list-url="/modules/article/list"
      request-delete-url="/modules/article/delete"
      request-detail-url="/modules/article/detail"
    >
      <template slot="search">
        <wt-search-form-item
          title="标题"
          v-model="form.formData.title"
          type="input"
        />
        <wt-search-form-item
          title="一级栏目"
          v-model="form.formData.programaGroupId"
          :data-list="stateList"
          type="select"
          blur="bluryiji"
        />
        <wt-search-form-item
          title="二级栏目"
          v-model="form.formData.id"
          :data-list="stateList2"
          type="select"
        />
      </template>
      <template slot="toolbar">
        <wt-button type="primary" @click="add">新增</wt-button>
        <wt-button type="danger" @click="$refs.dataForm.onClickMultipleDelete()"
          >删除
        </wt-button>
      </template>
      <template slot="table" v-if="isButtonLoading">
        <el-table-column
          type="selection"
          label="选择"
          :selectable="onSelectable"
        ></el-table-column>
        <el-table-column prop="title" label="标题"></el-table-column>
        <el-table-column prop="groupOne" label="一级栏目"></el-table-column>
        <el-table-column prop="groupTwo" label="二级栏目"></el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <!-- <el-table-column prop="isDefault" label="二级栏目">
          <template slot-scope="scope">{{
            scope.row.isDefault | FILTER_YesOrNo
          }}</template>
        </el-table-column>
        <el-table-column prop="isPublish" label="发布状态">
          <template slot-scope="scope"
            ><span :class="scope.row.isPublish | FILTER_IsPublishClass">{{
              scope.row.isPublish | FILTER_IsPublish
            }}</span>
          </template>
        </el-table-column> -->
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <wt-text-button @click="$refs.dataForm.toEdit(scope.row)"
              >编辑</wt-text-button
            >
            <wt-text-button
              @click="$refs.dataForm.onClickDelete(scope.row.id)"
              style="color: red"
              >删除</wt-text-button
            >
            <!-- <wt-text-button @click="del(scope.row)">删除</wt-text-button> -->
          </template>
        </el-table-column>
      </template>
    </wt-data-form>
    <!--弹层 开始-->
    <wt-detail-page :visible.sync="form.isViewDialogVisible">
      <RoleEdit
        :detailData="form.detailData"
        view-type="view"
        @on-close="form.isViewDialogVisible = false"
      />
    </wt-detail-page>
    <wt-detail-page :visible.sync="form.isEditDialogVisible">
      <RoleEdit
        :detailData="form.detailData"
        :view-type="form.dialogViewType"
        @on-refresh="$refs.dataForm.requestDataList()"
        @on-close="form.isEditDialogVisible = false"
      />
    </wt-detail-page>
    <!--弹层 结束-->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { articleDelete, articleList, articleGroupList } from '@/api/course.js'
export default {
  name: 'roleList',
  data() {
    return {
      isButtonLoading: true,
      form: {
        isFromLoading: false,
        isViewDialogVisible: false,
        isEditDialogVisible: false,
        dialogViewType: 'edit',
        detailData: {},
        extFormData: {},
        formData: {
          name: '',
          updateTimeRange: []
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        table: {},
        formResult: {}
      },
      stateList: [],
      stateList2: []
    }
  },
  mounted() {
    this.$refs.dataForm.requestDataList()
  },
  created() {
    articleGroupList({
      formData: { isAddChild: 1, title: '' },
      pageData: { currentPage: 1, pageSize: 20, sortName: ' ', sortOrder: ' ' }
    })
      .then(res => {
        console.log(res.data.list)
        this.stateList = res.data.list
        this.stateList.map(val => {
          val.label = val.title
          val.value = val.id
        })
        console.log(this.stateList)
      })
      .catch(res => {
        console.log(res)
      })
  },
  watch: {
    'form.formData.roleCode2'(data) {
      console.log(data)
      // return
      console.log(123231)
      articleGroupList({
        formData: { parientId: data, title: '' },
        pageData: {
          currentPage: 1,
          pageSize: 20,
          sortName: ' ',
          sortOrder: ' '
        }
      })
        .then(res => {
          console.log(res.data.list)
          this.stateList2 = res.data.list
          this.stateList2.map(val => {
            val.label = val.title
            val.value = val.id
          })
          console.log(this.stateList2)
        })
        .catch(res => {
          console.log(res)
        })
    },
    'form.formData.programaGroupId'(data) {
      this.stateList2 = []
      // this.form.formData.id = ''
      console.log(this.form.formData.id)
      console.log(data)
      articleGroupList({
        formData: { parientId: data, title: '' },
        pageData: {
          currentPage: 1,
          pageSize: 20,
          sortName: ' ',
          sortOrder: ' '
        }
      })
        .then(res => {
          console.log(this.stateList)
          this.stateList2 = res.data.list
          this.stateList2.map(val => {
            val.label = val.title
            val.value = val.id
          })
          console.log(this.stateList2)
          // console.log(res.data.list)
        })
        .catch(res => {
          console.log(res)
        })
    }
  },
  methods: {
    add() {
      // this.$refs.dataForm.toAdd()
      this.$refs.dataForm.toEdit()
      this.$refs.detailData = {}
    },
    onSelectable(row, index) {
      return !row.isDefault
    },
    del(data) {
      console.log(data.id)
      articleDelete({
        list: [data.id]
      })
        .then(res => {
          console.log(res)
          articleList({
            formData: { name: '', updateTimeRange: [] },
            pageData: {
              currentPage: 1,
              pageSize: 20,
              sortName: '',
              sortOrder: ''
            }
          })
            .then(res => {
              console.log(res)
              this.dataForm = res.data.list
            })
            .catch(res => {
              console.log(res)
            })
        })
        .catch(res => {
          console.log(res)
        })
    }
  },
  computed: {
    ...mapState({})
  }
}
</script>
