<template>
  <div class="page-detail-container">
    <!--顶部标题和页面全局按钮-->
    <wt-detail-header title="详情">
      <wt-button v-if="viewType == 'add'" v-has-any-premission="['DOCTOR:ADD']" type="primary" :loading="isButtonLoading" @click="onClickAdd">保存</wt-button>
      <wt-button v-if="viewType == 'edit'" v-has-any-premission="['DOCTOR:EDIT']" type="primary" :loading="isButtonLoading" @click="onClickSave">保存</wt-button>
      <wt-button @click="$emit('on-close')">返回</wt-button>
    </wt-detail-header>
    <!--内容区域-->
    <div class="wt-detail-container" v-loading="isFromLoading">
      <wt-detail-section title="基本信息">
        <el-form :model="detailForm" ref="form" size="small" class="wt-detail-section-form-center" label-width="30%">
          <el-form-item label="手机号：" prop="phone" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <el-input v-model="detailForm.phone" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="密码：" prop="password" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <el-input v-model="detailForm.password" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="姓名：" prop="realname" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <el-input v-model="detailForm.realname" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="性别：" prop="gender" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' }]">
            <el-select v-model="detailForm.gender" placeholder="请选择"></el-select>
          </el-form-item>
          <el-form-item label="医院：" prop="hospital" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <el-input v-model="detailForm.hospital" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="积分：" prop="point" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' }]">
            <el-select v-model="detailForm.point" placeholder="请选择"></el-select>
          </el-form-item>
          <el-form-item label="头像：" prop="head" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <el-input v-model="detailForm.head" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="科室：" prop="department" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <el-input v-model="detailForm.department" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="职称：" prop="systemData" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <el-input v-model="detailForm.systemData" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="从业时间：" prop="jobTime" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <el-date-picker v-model="detailForm.jobTime" type="datetime" placeholder="请选择"></el-date-picker>
          </el-form-item>
          <el-form-item label="擅长疾病：" prop="goodDisease" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <el-input v-model="detailForm.goodDisease" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="医生关联人1手机号码：" prop="affiliatedPhoneOne" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <el-input v-model="detailForm.affiliatedPhoneOne" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="医生关联人2手机号码：" prop="affiliatedPhoneTwo" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <el-input v-model="detailForm.affiliatedPhoneTwo" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="是否发布：" prop="isPublish" :rules="[{ required: true, message: '请选择发布状态', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' }]">
            <wt-form-simple-select-item v-model="detailForm.isPublish"/>
          </el-form-item>
          <el-form-item label="二维码地址：" prop="code" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <el-input v-model="detailForm.code" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="所在城市：" prop="city" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <el-input v-model="detailForm.city" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="详细地址：" prop="address" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <el-input v-model="detailForm.address" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="邮政编码：" prop="postalCode" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <el-input v-model="detailForm.postalCode" placeholder="请输入"></el-input>
          </el-form-item>
        </el-form>
      </wt-detail-section>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'DoctorEdit',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isButtonLoading: false,
      isFromLoading: false,
      detailForm: {}
    }
  },
  mounted() {
    if (this.viewType !== 'add') {
      this.requestDetail()
    }
  },
  created() {
  },
  methods: {
    onClickAdd() {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestAdd()
      }).catch(() => {
      })
    },
    onClickSave() {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestUpdate()
      }).catch(() => {
      })
    },
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: '/modules/doctor/detail',
        method: 'post',
        data: { id: that.detailData.id }
      }).then((resp) => {
        that.detailForm = resp.data || {}
        that.isFromLoading = false
      }).catch(() => {
        that.isFromLoading = false
      })
    },
    requestUpdate() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that.$wtRequest({
        url: '/modules/doctor/update',
        method: 'post',
        data: that.detailForm
      }).then((resp) => {
        that.isButtonLoading = false
        that.isFromLoading = false
        that.$message({ type: 'success', message: '保存成功' })
        that.$emit('on-refresh')
        that.$emit('on-close')
      }).catch(() => {
        that.isButtonLoading = false
        that.isFromLoading = false
      })
    },
    requestAdd() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that.$wtRequest({
        url: '/modules/doctor/add',
        method: 'post',
        data: that.detailForm
      }).then((resp) => {
        that.isButtonLoading = false
        that.isFromLoading = false
        that.$message({ type: 'success', message: '保存成功' })
        that.$emit('on-refresh')
        that.$emit('on-close')
      }).catch(() => {
        that.isButtonLoading = false
        that.isFromLoading = false
      })
    }
  },
  computed: {
    ...mapState({
    })
  }
}
</script>
