<template>
  <div class="page-container">
    <wt-data-form
      ref="dataForm"
      :form.sync="form"
      request-list-url="/modules/doctor/list"
      request-delete-url="/modules/doctor/delete"
      request-detail-url="/modules/doctor/detail"
    >
      <template slot="search">
        <wt-search-form-item
          title="医生姓名"
          v-model="form.formData.realname"
          type="input"
        />
        <wt-search-form-item
          title="医生手机号码"
          v-model="form.formData.phone"
          type="input"
          placeholder="请输入完整的手机号码"
        />
      </template>
      <template slot="toolbar">
        <wt-button
          type="primary"
          @click="$refs.dataForm.exportData2('医生信息')"
          >导出医生信息</wt-button
        >
        <wt-button type="primary" @click="toAdddoy">添加医生</wt-button>
      </template>
      <template slot="table">
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column
          prop="realname"
          label="医生姓名"
          width="130"
        ></el-table-column>
        <el-table-column
          prop="phone"
          label="医生手机号码"
          width="130"
        ></el-table-column>
        <el-table-column
          prop="hospital"
          label="医院"
          width="200"
        ></el-table-column>
        <el-table-column
          prop="systemData"
          label="职称"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="point"
          label="医生积分"
          width="100"
        ></el-table-column>
        <el-table-column prop="createTime" label="添加时间" width="150"></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <span @click="bianji(scope.row.id)" class="chouzuospan">编辑</span>
            <span @click="dangan(scope.row.id)" class="chouzuospan"
              >关联患者</span
            >
            <span @click="duijiang(scope.row.id)" class="chouzuospan"
              >兑奖情况</span
            >
            <span @click="jilu(scope.row.code)" class="chouzuospan"
              >医生二维码名片下载</span
            >
            <span
              style="color:#FF5B5B"
              @click="$refs.dataForm.onClickDelete(scope.row.id)"
              class="chouzuospan"
              >删除</span
            >
          </template>
        </el-table-column>
      </template>
    </wt-data-form>
    <!--弹层 开始-->
    <!-- 新增 -->
    <wt-detail-page :visible.sync="form.isViewDialogVisible">
      <accountEdit3
        :detailData="form.detailData"
        @on-close="form.isViewDialogVisible = false"
        view-type="add"
      />
    </wt-detail-page>
    <wt-detail-page :visible.sync="form.isEditDialogVisible123">
      <accountEdit123
        :detailData="form.detailData"
        :view-type="form.dialogViewType"
        @on-refresh="$refs.dataForm.requestDataList()"
        @on-close="form.isEditDialogVisible123 = false"
      />
    </wt-detail-page>
    <!-- 修改 -->
    <wt-detail-page :visible.sync="form.isEditDialogVisible">
      <accountEdit3
        :detailData="form.detailData"
        :view-type="form.dialogViewType"
        @on-refresh="$refs.dataForm.requestDataList()"
        @on-close="form.isEditDialogVisible = false"
      />
    </wt-detail-page>
    <wt-detail-page :visible.sync="form.isEditDialogVisible3">
      <patienArchivesa
        :detailData="form.detailData"
        :view-type="form.dialogViewType"
        @on-refresh="$refs.dataForm.requestDataList()"
        @on-close="form.isEditDialogVisible3 = false"
      />
    </wt-detail-page>
    <!-- 兑奖记录 -->
    <wt-detail-page :visible.sync="form.isEditDialogVisible321">
      <patienArchives321
        :detailData="form.detailData"
        :view-type="form.dialogViewType"
        @on-refresh="$refs.dataForm.requestDataList()"
        @on-close="form.isEditDialogVisible321 = false"
      />
    </wt-detail-page>
    <!--弹层 结束-->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { doctorDelete } from '@/api/course.js'
export default {
  name: 'accountList',
  data() {
    return {
      areaList: [],
      form: {
        isFromLoading: false,
        isViewDialogVisible: false,
        isEditDialogVisible123: false,
        isEditDialogVisible: false,
        isEditDialogVisible2: false,
        isEditDialogVisible3: false,
        isEditDialogVisible4: false,
        isEditDialogVisible321: false,
        dialogViewType: 'edit',
        detailData: {},
        formData: {
          list: [],
          phone: '',
          realname: ''
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        formResult: {}
      }
    }
  },
  mounted() {
    this.$refs.dataForm.requestDataList()
  },
  created() {},
  methods: {
    toAdddoy() {
      this.form.isViewDialogVisible = true
    },

    bianji(data) {
      this.form.isEditDialogVisible123 = true
      console.log(data)
      this.form.detailData.id = data
    },
    dangan(data) {
      this.form.detailData.id = data
      this.form.isEditDialogVisible3 = true
      console.log(data)
      console.log(this.form.detailData.id)
    },
    duijiang(data) {
      this.form.detailData.id = data
      this.form.isEditDialogVisible321 = true
      console.log(data)
      console.log(this.form.detailData.id)
    },
    jilu(data) {
      console.log(data)
      window.open(data)
    },
    del(data) {
      var dellist = {
        list: [data]
      }
      console.log(dellist)
      doctorDelete(dellist)
        .then(res => {
          console.log(res)
          if (res.code === '0') {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            // this.$router.push({ path: '/system/patientList' })
            this.$refs.dataForm.requestDataList()
          }
        })
        .catch(res => {
          console.log(res)
        })
      console.log(data)
    }
  },
  computed: {
    ...mapState({})
  }
}
</script>

<style lang="scss" scoped>
.chouzuospan {
  color: #0180cc;
  font-size: 14px;
  margin-right: 14px;
  cursor: pointer;
}
</style>
