import Cookies from 'js-cookie'

const kTokenKey = 'x-access-token'
const kIsRemember = 'x-is-remember'

function getStorgeInstance() {
  const isRemember = getStorge(kIsRemember) === 'true'
  return isRemember ? localStorage : sessionStorage
}

export function getToken() {
  const token = getStorgeInstance().getItem(kTokenKey)
  return token
}

export function setToken(token) {
  getStorgeInstance().setItem(kTokenKey, token)
}

export function removeToken() {
  getStorgeInstance().remove(kTokenKey)
}

export function getStorge(key) {
  return Cookies.get(key)
}

export function setStorge(key, value) {
  return Cookies.set(key, value)
}

export function removeStorge(key) {
  return Cookies.remove(key)
}

export function getStorgeItem(key) {
  const value = getStorgeInstance().getItem(key)
  if (value === null || value === undefined) {
    return null
  }
  try {
    return JSON.parse(value)
  } catch (e) {
    console.error(e)
  }
  return {}
}

export function setStorgeItem(key, value) {
  if (typeof value === 'string' || typeof value === 'number') {
    getStorgeInstance().setItem(key, value)
  } else {
    getStorgeInstance().setItem(key, JSON.stringify(value))
  }
}

export function removeStorgeItem(key) {
  return getStorgeInstance().removeItem(key)
}

export function clearStorge() {
  localStorage.clear()
  sessionStorage.clear()
}
