/*
  全局过期器定义
  注意：1. 使用字符类型表示key值；2. 使用'default'表示默认值；3.当没有定义'default'值时，将返回空字符串
 */
const filters = {
  FILTER_IsPublish: {
    '0': '未发布',
    '1': '已发布',
    'default': '-'
  },
  FILTER_IsPublishClass: {
    '0': 'span-danger',
    '1': 'span-success',
    'default': ''
  },
  FILTER_YesOrNo: {
    '0': '否',
    '1': '是'
  },
  FILTER_UserState: {
    '0': '待认证',
    '1': '已通过',
    '2': '未通过',
    'default': '-'
  },
  FILTER_Gender: {
    '1': '男',
    '2': '女',
    'default': '-'
  }
}

export default filters
