<template>
  <div class="app-container">
    <el-form ref="form" :model="form" :rules="rules" label-width="120px" size="small" class="wt-detail-form">
      <el-form-item label="基本信息" class="wt-section"></el-form-item>
      <el-form-item class="wt-col-6" label="名称：" prop="name">
        <el-input v-model="form.name" placeholder="请输入菜单名称"></el-input>
      </el-form-item>
      <el-form-item class="wt-col-6" label="区域：" prop="region">
        <el-input v-model="form.region" placeholder="请输入区域"></el-input>
      </el-form-item>
      <el-form-item class="wt-col-6" label="图片：" prop="imageFileList">
        <wt-upload class="wt-image-upload" :value.sync="form.imageFileList" :multiple="true" :limit="1" :file-size="1024*500"/>
      </el-form-item>
      <el-form-item class="wt-col-6" label="文件：" prop="fileList">
        <wt-upload class="wt-image-upload" :value.sync="form.fileList" :file-type="'file'" :multiple="true" :limit="3" :file-size="1024*1024"/>
      </el-form-item>
    </el-form>
    <div class="wt-detail-btns">
      <el-button size="small" type="primary" :loading="isSubmitLoading" @click="onSave">
        保存
      </el-button>
    </div>
  </div>
</template>

<script>
import WtUpload from '@/components/WtUpload'

export default {
  name: 'PluginUpload',
  data() {
    return {
      isSubmitLoading: false,
      fileListString: '',
      form: {
        name: '',
        region: '',
        imageFileList: [],
        fileList: [],
        summary: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        imageFileList: [
          { required: true, validator: this.$wtValidator.ValidatorWtUpload, message: '请上传图片', trigger: 'change' }
        ],
        fileList: [
          { required: true, validator: this.$wtValidator.ValidatorWtUpload, message: '请上传文件', trigger: 'change' }
        ],
        region: [
          { required: true, message: '请选择活动区域', trigger: 'change' }
        ],
        summary: [
          { required: true, message: '请输入活动描述', trigger: 'blur' }
        ]
      }
    }
  },
  components: {
    WtUpload
  },
  methods: {
    onClickTest() {
    },
    handleOnPreview(el, file) {
      console.log('handleOnPreview', el, file, el.getFiles())
    },
    onSave() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$message.success('校验通过')
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
