var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-detail-container"},[_c('wt-detail-header',{attrs:{"title":"详情"}},[(_vm.viewType == 'add')?_c('wt-button',{directives:[{name:"has-any-premission",rawName:"v-has-any-premission",value:(['PATIENT:ADD']),expression:"['PATIENT:ADD']"}],attrs:{"type":"primary","loading":_vm.isButtonLoading},on:{"click":_vm.onClickAdd}},[_vm._v("保存")]):_vm._e(),(_vm.viewType == 'edit')?_c('wt-button',{directives:[{name:"has-any-premission",rawName:"v-has-any-premission",value:(['PATIENT:EDIT']),expression:"['PATIENT:EDIT']"}],attrs:{"type":"primary","loading":_vm.isButtonLoading},on:{"click":_vm.onClickSave}},[_vm._v("保存")]):_vm._e(),_c('wt-button',{on:{"click":function($event){return _vm.$emit('on-close')}}},[_vm._v("返回")])],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isFromLoading),expression:"isFromLoading"}],staticClass:"wt-detail-container"},[_c('wt-detail-section',{attrs:{"title":"基本信息"}},[_c('el-form',{ref:"form",staticClass:"wt-detail-section-form-center",attrs:{"model":_vm.detailForm,"size":"small","label-width":"30%"}},[_c('el-form-item',{attrs:{"label":"首字母：","prop":"initial","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.detailForm.initial),callback:function ($$v) {_vm.$set(_vm.detailForm, "initial", $$v)},expression:"detailForm.initial"}})],1),_c('el-form-item',{attrs:{"label":"手机号：","prop":"phone","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.detailForm.phone),callback:function ($$v) {_vm.$set(_vm.detailForm, "phone", $$v)},expression:"detailForm.phone"}})],1),_c('el-form-item',{attrs:{"label":"密码：","prop":"password","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.detailForm.password),callback:function ($$v) {_vm.$set(_vm.detailForm, "password", $$v)},expression:"detailForm.password"}})],1),_c('el-form-item',{attrs:{"label":"姓名：","prop":"realname","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.detailForm.realname),callback:function ($$v) {_vm.$set(_vm.detailForm, "realname", $$v)},expression:"detailForm.realname"}})],1),_c('el-form-item',{attrs:{"label":"性别：","prop":"gender","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.detailForm.gender),callback:function ($$v) {_vm.$set(_vm.detailForm, "gender", $$v)},expression:"detailForm.gender"}})],1),_c('el-form-item',{attrs:{"label":"积分：","prop":"point","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.detailForm.point),callback:function ($$v) {_vm.$set(_vm.detailForm, "point", $$v)},expression:"detailForm.point"}})],1),_c('el-form-item',{attrs:{"label":"医院：","prop":"age","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.detailForm.age),callback:function ($$v) {_vm.$set(_vm.detailForm, "age", $$v)},expression:"detailForm.age"}})],1),_c('el-form-item',{attrs:{"label":"头像：","prop":"head","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.detailForm.head),callback:function ($$v) {_vm.$set(_vm.detailForm, "head", $$v)},expression:"detailForm.head"}})],1),_c('el-form-item',{attrs:{"label":"所在城市：","prop":"city","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.detailForm.city),callback:function ($$v) {_vm.$set(_vm.detailForm, "city", $$v)},expression:"detailForm.city"}})],1),_c('el-form-item',{attrs:{"label":"家属姓名：","prop":"relationName","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.detailForm.relationName),callback:function ($$v) {_vm.$set(_vm.detailForm, "relationName", $$v)},expression:"detailForm.relationName"}})],1),_c('el-form-item',{attrs:{"label":"家属联系人电话：","prop":"relationPhone","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.detailForm.relationPhone),callback:function ($$v) {_vm.$set(_vm.detailForm, "relationPhone", $$v)},expression:"detailForm.relationPhone"}})],1),_c('el-form-item',{attrs:{"label":"是否发布：","prop":"isPublish","rules":[
            {
              required: true,
              message: '请选择发布状态',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('wt-form-simple-select-item',{model:{value:(_vm.detailForm.isPublish),callback:function ($$v) {_vm.$set(_vm.detailForm, "isPublish", $$v)},expression:"detailForm.isPublish"}})],1),_c('el-form-item',{attrs:{"label":"二维码地址：","prop":"code","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.detailForm.code),callback:function ($$v) {_vm.$set(_vm.detailForm, "code", $$v)},expression:"detailForm.code"}})],1),_c('el-form-item',{attrs:{"label":"详细地址：","prop":"address","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.detailForm.address),callback:function ($$v) {_vm.$set(_vm.detailForm, "address", $$v)},expression:"detailForm.address"}})],1),_c('el-form-item',{attrs:{"label":"邮政编码：","prop":"postalCode","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.detailForm.postalCode),callback:function ($$v) {_vm.$set(_vm.detailForm, "postalCode", $$v)},expression:"detailForm.postalCode"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }