<template>
  <div class="page-detail-container">
    <!--顶部标题和页面全局按钮-->
    <wt-detail-header title="详情">
      <wt-button @click="$emit('on-close')">返回</wt-button>
    </wt-detail-header>
    <!--内容区域-->
    <div class="wt-detail-container">
      <wt-detail-section title="基本信息">
        <el-form
          :model="detailForm"
          ref="form"
          size="small"
          class="wt-detail-section-form-center"
          label-width="30%"
        >
          <el-form-item
            label="患者ID："
            prop="patinetId"
            :rules="[
              {
                required: true,
                message: '患者ID不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'change'
              }
            ]"
          >
            <el-select
              v-model="detailForm.patinetId"
              placeholder="请选择"
              readonly
              disabled
            ></el-select>
          </el-form-item>
          <el-form-item
            label="药品名称："
            prop="drug"
            :rules="[
              {
                required: true,
                message: '药品名称不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            {{ detailForm.drug }}
          </el-form-item>
          <el-form-item
            label="药品规格："
            prop="specifications"
            :rules="[
              {
                required: true,
                message: '药品规格不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            {{ detailForm.specifications }}
          </el-form-item>
          <el-form-item
            label="用药数量："
            prop="amount"
            :rules="[
              {
                required: true,
                message: '用药数量不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'change'
              }
            ]"
          >
            <el-select
              v-model="detailForm.amount"
              placeholder="请选择"
              readonly
              disabled
            ></el-select>
          </el-form-item>
          <el-form-item
            label="是否发生不良反应："
            prop="isAdverse"
            :rules="[
              {
                required: true,
                message: '是否发生不良反应不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'change'
              }
            ]"
          >
            <el-select
              v-model="detailForm.isAdverse"
              placeholder="请选择"
              readonly
              disabled
            ></el-select>
          </el-form-item>
          <el-form-item
            label="不良反应类型："
            prop="adverseType"
            :rules="[
              {
                required: true,
                message: '不良反应类型不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'change'
              }
            ]"
          >
            <el-select
              v-model="detailForm.adverseType"
              placeholder="请选择"
              readonly
              disabled
            ></el-select>
          </el-form-item>
        </el-form>
      </wt-detail-section>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'MedicalRecordsView',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isFromLoading: false,
      detailForm: {}
    }
  },
  mounted() {
    this.requestDetail()
  },
  created() {},
  methods: {
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that
        .$wtRequest({
          url: '/modules/medicalRecords/detail',
          method: 'post',
          data: { id: that.detailData.id }
        })
        .then(resp => {
          that.detailForm = resp.data || {}
          that.isFromLoading = false
        })
        .catch(() => {
          that.isFromLoading = false
        })
    }
  },
  computed: {
    ...mapState({})
  }
}
</script>
