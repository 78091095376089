<template>
  <div class="page-container">
    <wt-data-form ref="dataForm" :form.sync="form" request-list-url="/modules/dict/list" request-delete-url="/modules/dict/delete"
                  request-detail-url="/modules/dict/detail" :tree-props="{children: 'children', hasChildren: 'hasChildren'}" row-key="id">
      <template slot="search">
          <wt-search-form-item title="名称" v-model="form.formData.dictName" type="input" />
          <wt-search-form-item title="数值" v-model="form.formData.dictValue" type="input" />
      </template>
      <template slot="toolbar">
        <wt-button type="primary" v-has-any-premission="['DICT:ADD']" @click="$refs.dataForm.toAdd()">新增</wt-button>
        <wt-button type="danger" v-has-any-premission="['DICT:DELETE']" @click="$refs.dataForm.onClickMultipleDelete()">删除</wt-button>
      </template>
      <template slot="table">
        <el-table-column type="selection" label="选择"></el-table-column>
        <el-table-column prop="dictName" label="名称"></el-table-column>
        <el-table-column prop="dictKey" label="标识"></el-table-column>
        <el-table-column prop="dictValue" label="数值"></el-table-column>
        <el-table-column prop="ext" label="扩展信息" min-width="200"></el-table-column>
        <el-table-column prop="isPublish" label="发布状态"><template slot-scope="scope"><span :class="scope.row.isPublish|FILTER_IsPublishClass">{{scope.row.isPublish|FILTER_IsPublish}}</span></template></el-table-column>
        <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <wt-text-button v-has-any-premission="['DICT:VIEW']" @click="$refs.dataForm.toView(scope.row)">查看详情</wt-text-button>
              <wt-text-button v-has-any-premission="['DICT:EDIT']" @click="$refs.dataForm.toEdit(scope.row)">编辑</wt-text-button>
              <wt-text-button v-if="scope.row.parentId == 0" v-has-any-premission="['DICT:ADD']" @click="toSubAdd(scope.row)">新增子项</wt-text-button>
            </template>
        </el-table-column>
      </template>
    </wt-data-form>
    <!--弹层 开始-->
    <wt-detail-page :visible.sync="form.isViewDialogVisible"><DictView :detailData="form.detailData" view-type="view" @on-close="form.isViewDialogVisible = false"/></wt-detail-page>
    <wt-detail-page :visible.sync="form.isEditDialogVisible"><DictEdit :detailData="form.detailData" :view-type="form.dialogViewType" @on-refresh="$refs.dataForm.requestDataList()" @on-close="form.isEditDialogVisible = false"/></wt-detail-page>
    <!--弹层 结束-->
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'dictList',
  data() {
    return {
      form: {
        isFromLoading: false,
        isViewDialogVisible: false,
        isEditDialogVisible: false,
        dialogViewType: 'edit',
        detailData: {},
        extFormData: {},
        formData: {
          name: '',
          updateTimeRange: []
        },
        table: {
          rowKey: 'id'
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: 'orderNum', sortOrder: 'desc' },
        formResult: {}
      }
    }
  },
  mounted() {
    this.$refs.dataForm.onClickSearch()
  },
  created() {
  },
  methods: {
    toSubAdd(rowData) {
      this.form.dialogViewType = 'sub-add'
      this.form.detailData = rowData || {}
      this.form.detailData.parentName = rowData.dictName
      this.form.isEditDialogVisible = true
    }
  },
  computed: {
    ...mapState({
    })
  }
}
</script>
