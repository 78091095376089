<template>
  <div class="page-container">
    <wt-data-form ref="dataForm" :form.sync="form" request-list-url="/modules/articleGroup/list" request-delete-url="/modules/articleGroup/delete"
                  request-detail-url="/modules/articleGroup/detail">
      <template slot="search">
          <wt-search-form-item title="主键" v-model="form.formData.id" type="select" />
          <wt-search-form-item title="一级栏目" v-model="form.formData.parientId" type="select" />
          <wt-search-form-item title="栏目名称" v-model="form.formData.title" type="input" />
          <wt-search-form-item title="排序" v-model="form.formData.sortNum" type="select" />
          <wt-search-form-item title="是否添加二级栏目" v-model="form.formData.isAddChild" type="select" />
          <wt-search-form-item title="是否发布 0否1是" v-model="form.formData.isPublish" type="select" />
          <wt-search-form-item title="是否删除" v-model="form.formData.isDelete" type="select" />
          <wt-search-form-item title="创建时间" v-model="form.formData.createTime" type="daterange" />
          <wt-search-form-item title="更新时间" v-model="form.formData.updateTime" type="daterange" />
      </template>
      <template slot="toolbar">
        <wt-button type="primary" v-has-any-premission="['ARTICLEGROUP:ADD']" @click="$refs.dataForm.toAdd()">新增</wt-button>
        <wt-button type="danger" v-has-any-premission="['ARTICLEGROUP:DELETE']" @click="$refs.dataForm.onClickMultipleDelete()">删除</wt-button>
      </template>
      <template slot="table">
        <el-table-column type="selection" label="选择"></el-table-column>
        <el-table-column prop="id" label="主键" sortable></el-table-column>
        <el-table-column prop="parientId" label="一级栏目" sortable></el-table-column>
        <el-table-column prop="title" label="栏目名称"></el-table-column>
        <el-table-column prop="sortNum" label="排序" sortable></el-table-column>
        <el-table-column prop="isAddChild" label="是否添加二级栏目" sortable></el-table-column>
        <el-table-column prop="isPublish" label="发布状态"><template slot-scope="scope"><span :class="scope.row.isPublish|FILTER_IsPublishClass">{{scope.row.isPublish|FILTER_IsPublish}}</span></template></el-table-column>
        <el-table-column prop="createTime" label="创建时间" sortable></el-table-column>
        <el-table-column prop="updateTime" label="更新时间" sortable></el-table-column>
        <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <wt-text-button v-has-any-premission="['ARTICLEGROUP:VIEW']" @click="$refs.dataForm.toView(scope.row)">查看详情</wt-text-button>
              <wt-text-button v-has-any-premission="['ARTICLEGROUP:EDIT']" @click="$refs.dataForm.toEdit(scope.row)">编辑</wt-text-button>
            </template>
        </el-table-column>
      </template>
    </wt-data-form>
    <!--弹层 开始-->
    <wt-detail-page :visible.sync="form.isViewDialogVisible"><ArticleGroupView :detailData="form.detailData" view-type="view" @on-close="form.isViewDialogVisible = false"/></wt-detail-page>
    <wt-detail-page :visible.sync="form.isEditDialogVisible"><ArticleGroupEdit :detailData="form.detailData" :view-type="form.dialogViewType" @on-refresh="$refs.dataForm.requestDataList()" @on-close="form.isEditDialogVisible = false"/></wt-detail-page>
    <!--弹层 结束-->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'articleGroupList',
  data() {
    return {
      form: {
        isFromLoading: false,
        isViewDialogVisible: false,
        isEditDialogVisible: false,
        dialogViewType: 'edit',
        detailData: {},
        extFormData: {},
        formData: {
          name: '',
          updateTimeRange: []
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        table: {},
        formResult: {}
      }
    }
  },
  mounted() {
    this.$refs.dataForm.onClickSearch()
  },
  created() {
  },
  methods: {
  },
  computed: {
    ...mapState({
    })
  }
}
</script>
