<template>
  <div class="page-detail-container">
    <!--顶部标题和页面全局按钮-->
    <wt-detail-header>
      <wt-button
        v-if="viewType == 'add'"
        v-has-any-premission="['ACCOUNTLIST:ADD']"
        type="primary"
        :loading="isButtonLoading"
        @click="onClickAdd"
        >保存</wt-button
      >
      <wt-button
        v-if="viewType == 'edit'"
        v-has-any-premission="['ACCOUNTLIST:EDIT']"
        type="primary"
        :loading="isButtonLoading"
        @click="onClickSave"
        >保存</wt-button
      >
      <wt-button @click="$emit('on-close')">返回</wt-button>
    </wt-detail-header>
    <!--内容区域-->
    <div class="wt-detail-container" v-loading="isFromLoading">
      <wt-detail-section title="用药记录">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column
            header-align="center"
            prop="drug"
            label="药品名称"
          >
          </el-table-column>
          <el-table-column header-align="center" prop="buyTime" label="购药时间">
          </el-table-column>
          <el-table-column header-align="center" label="详情">
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row.id)"
                type="text"
                size="small"
                >查看详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </wt-detail-section>
    </div>
    <wt-detail-page :visible.sync="form.isEditDialogVisible">
      <patienArchivesEdit2
        :detailData="detailForm"
        @on-refresh="$refs.dataForm.requestDataList()"
        @on-close="form.isEditDialogVisible = false"
      />
    </wt-detail-page>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapState } from 'vuex'
import staticRoutes from '@/router/staticRoutes.js'
import { patientUpdate } from '@/api/course.js'
export default {
  name: 'patienArchives2',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      roleList: [],
      staticRoutes: staticRoutes,
      defaultCheckedKeys: [],
      isButtonLoading: false,
      isFromLoading: false,
      detailForm: {
        isPublish: 1,
        roleList: []
      },
      form: {
        isEditDialogVisible: false
      },
      // 表单
      tableData: []
    }
  },
  mounted() {
    this.requestManagerRoleList()
    if (this.viewType !== 'add') {
      this.requestDetail()
    }
  },
  created() {},
  methods: {
    // 表单
    handleClick(data) {
      console.log(data)
      window.localStorage.setItem('param', data)
      this.form.isEditDialogVisible = true
      this.form.detailData.id = data
      // 保存

      console.log(data)
    },
    // 表单

    submitForm(data) {
      console.log(data)
      if (data.age === '') {
        console.log(data.age)
        this.$message('请输入年龄')
        return
      }
      if (data.city === '') {
        console.log(data.city)
        this.$message('请输入所有在城市')
        return
      }
      if (data.gender === '') {
        console.log(data.gender)
        this.$message('请选中性别')
        return
      }
      if (data.phone === '') {
        console.log(data.phone)
        this.$message('请输入手机号')
        return
      }
      if (data.realname === '') {
        console.log(data.realname)
        this.$message('请输入姓名')
        return
      }
      patientUpdate(data)
        .then(res => {
          console.log(res)
          if (res.msg === '请求成功') {
            this.$message({
              message: '保存成功',
              type: 'success'
            })
            this.$emit('on-close')
          }
        })
        .catch(res => {
          console.log(res)
        })
      console.log('aaa')
    },
    onClickAdd() {
      const that = this
      this.$wtUtil
        .validateDetailFormsAndConfirm(this, '是否保存?')
        .then(() => {
          that.requestAdd()
        })
        .catch(() => {})
    },
    onClickSave() {
      const that = this
      this.$wtUtil
        .validateDetailFormsAndConfirm(this, '是否保存?')
        .then(() => {
          that.requestUpdate()
        })
        .catch(() => {})
    },
    requestDetail() {
      const that = this
      that.isFromLoading = true
      console.log(that.detailData)
      that
        .$wtRequest({
          url: '/modules/medicalRecords/list',
          method: 'post',
          data: {
            formData: {
              patientId: that.detailData.id
            },
            pageData: {
              currentPage: 1,
              pageSize: 20,
              sortName: ' ',
              sortOrder: ' '
            }
          }
        })
        .then(resp => {
          that.detailForm = resp.data || {}
          that.tableData = that.detailForm.list
          console.log(that.detailForm)
          that.isFromLoading = false
        })
        .catch(() => {
          that.isFromLoading = false
        })
    },
    requestManagerRoleList() {
      const that = this
      that.isFromLoading = true
      that
        .$wtRequest({
          url: '/modules/auth/accountRoleList',
          method: 'post',
          data: {}
        })
        .then(resp => {
          const tempRoleList = [].concat(resp.data)
          that.roleList = tempRoleList
          console.log(that.roleList)
          that.isFromLoading = false
        })
        .catch(() => {
          that.isFromLoading = false
        })
    },
    requestUpdate() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that
        .$wtRequest({
          url: '/modules/auth/accountUpdate',
          method: 'post',
          data: that.detailForm
        })
        .then(resp => {
          that.isButtonLoading = false
          that.isFromLoading = false
          that.$message({ type: 'success', message: '保存成功' })
          that.$emit('on-refresh')
          that.$emit('on-close')
        })
        .catch(() => {
          that.isButtonLoading = false
          that.isFromLoading = false
        })
    },
    requestAdd() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that
        .$wtRequest({
          url: '/modules/auth/accountAdd',
          method: 'post',
          data: that.detailForm
        })
        .then(resp => {
          that.isButtonLoading = false
          that.isFromLoading = false
          that.$message({ type: 'success', message: '保存成功' })
          that.$emit('on-refresh')
          that.$emit('on-close')
        })
        .catch(() => {
          that.isButtonLoading = false
          that.isFromLoading = false
        })
    }
  },
  computed: {
    moduleTreeData() {
      const that = this
      const treeData = []
      const makeChildrenFunction = staticRoute => {
        const children = []
        if (!staticRoute.hidden) {
          if (staticRoute.children && staticRoute.children.length > 0) {
            const parentRouteId = staticRoute.name.toUpperCase()
            if (that.defaultCheckedKeys.indexOf(parentRouteId) !== -1) {
              that.defaultCheckedKeys.splice(
                that.defaultCheckedKeys.indexOf(parentRouteId),
                1
              )
            }
            staticRoute.children.forEach(child => {
              const routeId = child.name.toUpperCase()
              children.push({
                id: routeId,
                label: child.meta ? child.meta.title : '',
                children: makeChildrenFunction(child)
              })
            })
          }
        }
        return children
      }
      this.staticRoutes.forEach(staticRoute => {
        if (!staticRoute.hidden) {
          const routeId = staticRoute.name.toUpperCase()
          treeData.push({
            id: routeId,
            label: staticRoute.meta ? staticRoute.meta.title : '',
            children: makeChildrenFunction(staticRoute)
          })
        }
      })
      return treeData
    }
    // ...mapState({
    //   groupList: state => state.ops.groupList
    // })
  }
}
</script>

<style lang="scss" scoped></style>
<style scoped>
/deep/ .wt-detail-section-content {
  padding: 16px 16px 16px 16px !important;
}
/deep/ .el-table td,
.el-table th.is-leaf {
  border-bottom: 1px solid #ebeef5;
  text-align: center;
}
</style>
