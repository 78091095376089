import wtUtil from '@/utils/wtUtil'

const wtValidator = {
  ValidatorNotBlank: (rule, value, callback) => {
    if (wtUtil.isBlank(value)) {
      callback(new Error(''))
    } else {
      callback()
    }
  },
  ValidatorWtUpload: (rule, value, callback) => {
    if (!value || value.length <= 0) {
      const text = rule.name ? `请上传${rule.name}` : '请上传文件'
      return callback(new Error(text))
    }
    if (rule && value.length > rule.limit) {
      return callback(new Error(`最多上传${rule.limit}个文件`))
    }
    return callback()
  },
  ValidatorWtEditor: (rule, value, callback) => {
    if (wtUtil.isBlank(value)) {
      callback(new Error(''))
    } else {
      callback()
    }
  },
  ValidatorWtPhone: (rule, value, callback) => {
    if (value != null && value !== '') {
      if (!(/^1\d{10}$/.test(value))) {
        callback(new Error('您输入的手机号不正确!'))
      } else {
        callback()
      }
    } else {
      callback()
    }
  },
  ValidatorWtEdtId: (rule, value, callback) => {
    if (value != null && value !== '') {
      if (!(/^\d{8}$/.test(value))) {
        callback(new Error('您输入的教育ID不正确!'))
      } else {
        callback()
      }
    } else {
      callback()
    }
  },
  ValidatorWtTeaId: (rule, value, callback) => {
    if (value != null && value !== '') {
      if (!(/^(0|[1-9]\d{0,9})$/.test(value))) {
        callback(new Error('您输入的教职工号不正确!'))
      } else {
        callback()
      }
    } else {
      callback()
    }
  },
  ValidatorWtCertificateNo: (rule, value, callback) => {
    if (value != null && value !== '') {
      if (!(/(^\d{15}$)|(^\d{17}([0-9]|(X|x))$)/.test(value))) {
        callback(new Error('您输入的身份证号不正确!'))
      } else {
        callback()
      }
    } else {
      callback()
    }
  },
  ValidatorWtNumber: (rule, value, callback) => {
    if (value != null && value !== '') {
      if (!(/(^[0-9]*$)/.test(value))) {
        callback(new Error('您输入的值不是纯数字!'))
      } else {
        callback()
      }
    } else {
      callback()
    }
  }
}

export default wtValidator
