<template>
  <div class="page-detail-container">
    <!--顶部标题和页面全局按钮-->
    <wt-detail-header title="详情">
      <wt-button
        v-if="viewType == 'add'"
        v-has-any-premission="['DOCTORPATIENT:ADD']"
        type="primary"
        :loading="isButtonLoading"
        @click="onClickAdd"
        >保存</wt-button
      >
      <wt-button
        v-if="viewType == 'edit'"
        v-has-any-premission="['DOCTORPATIENT:EDIT']"
        type="primary"
        :loading="isButtonLoading"
        @click="onClickSave"
        >保存</wt-button
      >
      <wt-button @click="$emit('on-close')">返回</wt-button>
    </wt-detail-header>
    <!--内容区域-->
    <div class="wt-detail-container" v-loading="isFromLoading">
      <wt-detail-section title="基本信息">
        <el-form
          :model="detailForm"
          ref="form"
          size="small"
          class="wt-detail-section-form-center"
          label-width="30%"
        >
          <el-form-item
            label="患者ID："
            prop="patientId"
            :rules="[
              {
                required: true,
                message: '输入内容不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'change'
              }
            ]"
          >
            <el-select
              v-model="detailForm.patientId"
              placeholder="请选择"
            ></el-select>
          </el-form-item>
          <el-form-item
            label="医生ID："
            prop="doctorId"
            :rules="[
              {
                required: true,
                message: '输入内容不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'change'
              }
            ]"
          >
            <el-select
              v-model="detailForm.doctorId"
              placeholder="请选择"
            ></el-select>
          </el-form-item>
          <el-form-item
            label="是否发布："
            prop="isPublish"
            :rules="[
              {
                required: true,
                message: '请选择发布状态',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'change'
              }
            ]"
          >
            <wt-form-simple-select-item v-model="detailForm.isPublish" />
          </el-form-item>
          <el-form-item
            label="发起类型人  1 医生  2 患者："
            prop="sponsorType"
            :rules="[
              {
                required: true,
                message: '输入内容不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'change'
              }
            ]"
          >
            <el-select
              v-model="detailForm.sponsorType"
              placeholder="请选择"
            ></el-select>
          </el-form-item>
          <el-form-item
            label="咨询次数："
            prop="advisoryNum"
            :rules="[
              {
                required: true,
                message: '输入内容不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            <el-input
              v-model="detailForm.advisoryNum"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="最后一次咨询时间："
            prop="endTime"
            :rules="[
              {
                required: true,
                message: '输入内容不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            <el-date-picker
              v-model="detailForm.endTime"
              type="datetime"
              placeholder="请选择"
            ></el-date-picker>
          </el-form-item>
        </el-form>
      </wt-detail-section>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'DoctorPatientEdit',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isButtonLoading: false,
      isFromLoading: false,
      detailForm: {}
    }
  },
  mounted() {
    if (this.viewType !== 'add') {
      this.requestDetail()
    }
  },
  created() {},
  methods: {
    onClickAdd() {
      const that = this
      this.$wtUtil
        .validateDetailFormsAndConfirm(this, '是否保存?')
        .then(() => {
          that.requestAdd()
        })
        .catch(() => {})
    },
    onClickSave() {
      const that = this
      this.$wtUtil
        .validateDetailFormsAndConfirm(this, '是否保存?')
        .then(() => {
          that.requestUpdate()
        })
        .catch(() => {})
    },
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that
        .$wtRequest({
          url: '/modules/doctorPatient/detail',
          method: 'post',
          data: { id: that.detailData.id }
        })
        .then(resp => {
          that.detailForm = resp.data || {}
          that.isFromLoading = false
        })
        .catch(() => {
          that.isFromLoading = false
        })
    },
    requestUpdate() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that
        .$wtRequest({
          url: '/modules/doctorPatient/update',
          method: 'post',
          data: that.detailForm
        })
        .then(resp => {
          that.isButtonLoading = false
          that.isFromLoading = false
          that.$message({ type: 'success', message: '保存成功' })
          that.$emit('on-refresh')
          that.$emit('on-close')
        })
        .catch(() => {
          that.isButtonLoading = false
          that.isFromLoading = false
        })
    },
    requestAdd() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that
        .$wtRequest({
          url: '/modules/doctorPatient/add',
          method: 'post',
          data: that.detailForm
        })
        .then(resp => {
          that.isButtonLoading = false
          that.isFromLoading = false
          that.$message({ type: 'success', message: '保存成功' })
          that.$emit('on-refresh')
          that.$emit('on-close')
        })
        .catch(() => {
          that.isButtonLoading = false
          that.isFromLoading = false
        })
    }
  },
  computed: {
    ...mapState({})
  }
}
</script>
