var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-detail-container"},[_c('wt-detail-header',{attrs:{"title":"详情"}},[(_vm.viewType == 'add')?_c('wt-button',{directives:[{name:"has-any-premission",rawName:"v-has-any-premission",value:(['GIFTORDER:ADD']),expression:"['GIFTORDER:ADD']"}],attrs:{"type":"primary","loading":_vm.isButtonLoading},on:{"click":_vm.onClickAdd}},[_vm._v("保存")]):_vm._e(),(_vm.viewType == 'edit')?_c('wt-button',{directives:[{name:"has-any-premission",rawName:"v-has-any-premission",value:(['GIFTORDER:EDIT']),expression:"['GIFTORDER:EDIT']"}],attrs:{"type":"primary","loading":_vm.isButtonLoading},on:{"click":_vm.onClickSave}},[_vm._v("保存")]):_vm._e(),_c('wt-button',{on:{"click":function($event){return _vm.$emit('on-close')}}},[_vm._v("返回")])],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isFromLoading),expression:"isFromLoading"}],staticClass:"wt-detail-container"},[_c('wt-detail-section',{attrs:{"title":"基本信息"}},[_c('el-form',{ref:"form",staticClass:"wt-detail-section-form-center",attrs:{"model":_vm.detailForm,"size":"small","label-width":"30%"}},[_c('el-form-item',{attrs:{"label":"订单号：","prop":"orderNo","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.detailForm.orderNo),callback:function ($$v) {_vm.$set(_vm.detailForm, "orderNo", $$v)},expression:"detailForm.orderNo"}})],1),_c('el-form-item',{attrs:{"label":"角色 1 医生 2 患者：","prop":"type","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.detailForm.type),callback:function ($$v) {_vm.$set(_vm.detailForm, "type", $$v)},expression:"detailForm.type"}})],1),_c('el-form-item',{attrs:{"label":"用户：","prop":"userId","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.detailForm.userId),callback:function ($$v) {_vm.$set(_vm.detailForm, "userId", $$v)},expression:"detailForm.userId"}})],1),_c('el-form-item',{attrs:{"label":"商品ID：","prop":"giftId","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.detailForm.giftId),callback:function ($$v) {_vm.$set(_vm.detailForm, "giftId", $$v)},expression:"detailForm.giftId"}})],1),_c('el-form-item',{attrs:{"label":"兑换数量：","prop":"exchangeNum","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.detailForm.exchangeNum),callback:function ($$v) {_vm.$set(_vm.detailForm, "exchangeNum", $$v)},expression:"detailForm.exchangeNum"}})],1),_c('el-form-item',{attrs:{"label":"消耗积分：","prop":"point","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.detailForm.point),callback:function ($$v) {_vm.$set(_vm.detailForm, "point", $$v)},expression:"detailForm.point"}})],1),_c('el-form-item',{attrs:{"label":"状态 1 待审核 2审核通过，待发货 3审核通过，已发货 4 拒绝：","prop":"status","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.detailForm.status),callback:function ($$v) {_vm.$set(_vm.detailForm, "status", $$v)},expression:"detailForm.status"}})],1),_c('el-form-item',{attrs:{"label":"拒绝原因：","prop":"reason","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.detailForm.reason),callback:function ($$v) {_vm.$set(_vm.detailForm, "reason", $$v)},expression:"detailForm.reason"}})],1),_c('el-form-item',{attrs:{"label":"物流公司：","prop":"logisticsCompany","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.detailForm.logisticsCompany),callback:function ($$v) {_vm.$set(_vm.detailForm, "logisticsCompany", $$v)},expression:"detailForm.logisticsCompany"}})],1),_c('el-form-item',{attrs:{"label":"快递单号：","prop":"trackingNumber","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.detailForm.trackingNumber),callback:function ($$v) {_vm.$set(_vm.detailForm, "trackingNumber", $$v)},expression:"detailForm.trackingNumber"}})],1),_c('el-form-item',{attrs:{"label":"是否发布：","prop":"isPublish","rules":[
            {
              required: true,
              message: '请选择发布状态',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('wt-form-simple-select-item',{model:{value:(_vm.detailForm.isPublish),callback:function ($$v) {_vm.$set(_vm.detailForm, "isPublish", $$v)},expression:"detailForm.isPublish"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }