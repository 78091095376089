<template>
  <div v-if="isItemVisibility">
    <div class="wt-permission-item">
      <div class="title">
        <i class="el-icon-caret-right" :style="{paddingLeft: (level * 16) + 'px'}"></i>
        {{item.meta ? item.meta.title : item.name}}
      </div>
      <div class="content" v-if="isPickerMode">
        <div class="tag picker-tag" :class="{'active': checkedPermissionCodeArray.indexOf(moduleName + ':VIEW') != -1, 'picker-tag-pointer': !isReadMode }" @click="onClickTagItem('VIEW')"><span class="tag-title">查看</span><span class="tag-ext">{{moduleName}}:VIEW</span></div>
        <div class="tag picker-tag" :class="{'active': checkedPermissionCodeArray.indexOf(moduleName + ':ADD') != -1, 'picker-tag-pointer': !isReadMode }" @click="onClickTagItem('ADD')"><span class="tag-title">增加</span><span class="tag-ext">{{moduleName}}:ADD</span></div>
        <div class="tag picker-tag" :class="{'active': checkedPermissionCodeArray.indexOf(moduleName + ':EDIT') != -1, 'picker-tag-pointer': !isReadMode }" @click="onClickTagItem('EDIT')"><span class="tag-title">编辑</span><span class="tag-ext">{{moduleName}}:EDIT</span></div>
        <div class="tag picker-tag" :class="{'active': checkedPermissionCodeArray.indexOf(moduleName + ':DELETE') != -1, 'picker-tag-pointer': !isReadMode }" @click="onClickTagItem('DELETE')"><span class="tag-title">删除</span><span class="tag-ext">{{moduleName}}:DELETE</span></div>
        <template v-if="modulePermissionMap && modulePermissionMap[moduleName]">
          <div class="tag picker-tag" :class="{'active': checkedPermissionCodeArray.indexOf(moduleName + ':' + customItem.permissionCode) != -1, 'picker-tag-pointer': !isReadMode }" v-for="customItem in modulePermissionMap[moduleName]" @click="onClickTagItem(customItem.permissionCode)">
            <span class="tag-title">{{customItem.permissionName}}</span><span class="tag-ext">{{moduleName}}:{{customItem.permissionCode}}</span>
          </div>
        </template>
      </div>
      <div class="content" v-else>
        <div class="tag"><span class="tag-title">查看</span><span class="tag-ext">{{moduleName}}:VIEW</span></div>
        <div class="tag"><span class="tag-title">增加</span><span class="tag-ext">{{moduleName}}:ADD</span></div>
        <div class="tag"><span class="tag-title">编辑</span><span class="tag-ext">{{moduleName}}:EDIT</span></div>
        <div class="tag"><span class="tag-title">删除</span><span class="tag-ext">{{moduleName}}:DELETE</span></div>
        <template v-if="modulePermissionMap && modulePermissionMap[moduleName]">
          <div class="custom-tag" v-for="customItem in modulePermissionMap[moduleName]">
            <div class="custom-tag-content"><span class="tag-title">{{customItem.permissionName}}</span><span class="tag-ext">{{moduleName}}:{{customItem.permissionCode}}</span></div>
            <div class="custom-tag-btn" @click="onCustomItemDelete(customItem.id)" v-has-any-premission="['PERMISSIONLIST:DELETE']"><i class="el-icon-close"></i></div>
          </div>
        </template>
      </div>
      <div class="panel" v-if="!isReadMode">
        <el-dropdown v-has-any-premission="['PERMISSIONLIST:EDIT']" class="panel-btn" @command="onDropdown" trigger="click">
            <span class="el-dropdown-link">
              操作<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-has-any-premission="['PERMISSIONLIST:ADD']" v-if="type !== 'picker'" command="create">创建自定义权限</el-dropdown-item>
            <el-dropdown-item v-if="type === 'picker'" command="currentCheckAll">本级全选</el-dropdown-item>
            <el-dropdown-item v-if="type === 'picker'" command="currentUnCheckAll">本级反选</el-dropdown-item>
            <el-dropdown-item v-if="type === 'picker'" command="checkAll">全选</el-dropdown-item>
            <el-dropdown-item v-if="type === 'picker'" command="unCheckAll">反选</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <permission-item v-if="!childItem.hidden" :key="'child_' + index" v-for="(childItem, index) in item.children"
                     :is-read-mode="isReadMode"
                     :item="childItem" :level="level + 1" :type="type" :module-permission-map="modulePermissionMap"
                     :checked-module-codes="checkedModuleCodes" :checked-permission-codes="checkedPermissionCodes"
                     @on-click-tag="onClickTag" @on-click-item-action="onClickItemAction"
                     @on-delete-custom="onDeleteCustomPermission"
                     @on-create-custom="onCreateCustomPermission"></permission-item>
  </div>
</template>

<script>
export default {
  name: 'permissionItem',
  isAutoComponent: true,
  props: ['item', 'level', 'checkedModuleCodes', 'checkedPermissionCodes', 'modulePermissionMap', 'type', 'isReadMode'],
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
    onDropdown(command) {
      if (command === 'create') {
        this.$emit('on-create-custom', this.item)
      } else if (command === 'currentCheckAll') {
        this.$emit('on-click-item-action', { action: 'currentCheckAll', data: this.item })
      } else if (command === 'currentUnCheckAll') {
        this.$emit('on-click-item-action', { action: 'currentUnCheckAll', data: this.item })
      } else if (command === 'checkAll') {
        this.$emit('on-click-item-action', { action: 'checkAll', data: this.item })
      } else if (command === 'unCheckAll') {
        this.$emit('on-click-item-action', { action: 'unCheckAll', data: this.item })
      }
    },
    onClickItemAction({ action, data }) {
      this.$emit('on-click-item-action', { action, data })
    },
    onCreateCustomPermission(data) {
      this.$emit('on-create-custom', data)
    },
    onDeleteCustomPermission(permissionId) {
      this.$emit('on-delete-custom', permissionId)
    },
    onCustomItemDelete(permissionId) {
      this.$emit('on-delete-custom', permissionId)
    },
    onClickTagItem(permissionCode) {
      if (!this.isReadMode) {
        this.$emit('on-click-tag', this.moduleName + ':' + permissionCode.toUpperCase())
      }
    },
    onClickTag(code) {
      this.$emit('on-click-tag', code)
    }
  },
  computed: {
    moduleName() {
      return this.item.name.toUpperCase()
    },
    checkedPermissionCodeArray() {
      return this.checkedPermissionCodes || []
    },
    isItemVisibility() {
      if (!this.isPickerMode) {
        return true
      }
      const result = this.checkedModuleCodes.indexOf(this.moduleName) !== -1
      // console.log('this.checkedModuleCodes', this.checkedModuleCodes, this.moduleName)
      return result
    },
    isPickerMode() {
      return (this.type === 'picker')
    }
  }
}
</script>

<style scoped>
.wt-permission-item{display: flex; min-height: 40px;align-items: center;border-bottom: 1px solid #F4F4F4;color: #333;}
.wt-permission-item:hover{background-color: #FAFAFA;}
.wt-permission-item .title{width: 250px;padding-left: 16px;font-size: 14px;}
.wt-permission-item .content{display: flex;flex: 1;flex-wrap: wrap;}
.wt-permission-item .panel{min-width: 100px;padding: 0 16px;}
.wt-permission-item .panel .panel-btn{cursor: pointer;}
.wt-permission-item .tag{display: flex;flex-direction: column; margin: 5px 16px 5px 0; border-radius: 4px;padding: 2px 4px;    background-color: #f4f4f5;border-color: #e9e9eb;color: #909399;}
.wt-permission-item .picker-tag{}
.wt-permission-item .picker-tag-pointer{cursor: pointer;}
.wt-permission-item .picker-tag.active{background-color: #409eff;}
.wt-permission-item .tag .tag-title{font-size: 14px;color: #333333;}
.wt-permission-item .tag .tag-ext{font-size: 12px;color: #999999;margin-top: 4px;}
.wt-permission-item .picker-tag.active .tag-title{font-size: 14px;color: #FFFFFF;}
.wt-permission-item .picker-tag.active .tag-ext{font-size: 12px;color: #FFFFFF;margin-top: 4px;}
.wt-permission-item .custom-tag {display: flex;margin: 5px 16px 5px 0; border-radius: 4px;padding: 2px 4px;background-color: #f0f9eb;border-color: #e1f3d8;color: #67c23a;align-items: center;}
.wt-permission-item .custom-tag .custom-tag-content{display: flex; flex-direction: column;}
.wt-permission-item .custom-tag .custom-tag-btn{display: flex;align-items: center;justify-content: center; width: 20px;height: 20px;border-radius: 10px;margin-left: 8px;}
.wt-permission-item .custom-tag .custom-tag-btn:hover{cursor: pointer;background-color: #e1f3d8;}
.wt-permission-item .custom-tag .tag-title{font-size: 14px;}
.wt-permission-item .custom-tag .tag-ext{font-size: 12px;font-weight: bold;}

</style>
