<template>
  <div class="page-container page-side-container">
    <div class="leftside-container">
      <div class="header">组结构</div>
      <div class="content">
        <el-tree :props="defaultProps" :data="groupList" :default-expanded-keys="[1]"
                 @current-change="onGroupTreeCurrentChanged"
                 node-key="id" highlight-current :expand-on-click-node="false">
        </el-tree>
      </div>
    </div>
    <div class="right-container">
      <wt-data-form ref="dataForm" :form.sync="form" request-list-url="/modules/auth/groupList" request-delete-url="/modules/auth/groupDelete"
                    request-detail-url="/modules/auth/groupDetail">
        <template slot="search">
          <wt-search-form-item title="组名称" v-model="form.formData.name" type="input" />
        </template>
        <template slot="toolbar">
          <wt-button type="primary" v-has-any-premission="['GROUP:ADD']" @click="toAdd">新增</wt-button>
          <wt-button type="danger" v-has-any-premission="['GROUP:DELETE']" @click="$refs.dataForm.onClickMultipleDelete()">删除</wt-button>
        </template>
        <template slot="table">
          <el-table-column type="selection" label="选择"></el-table-column>
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column prop="name" label="组名称"></el-table-column>
          <el-table-column prop="isPublish" label="发布状态"><template slot-scope="scope"><span :class="scope.row.isPublish|FILTER_IsPublishClass">{{scope.row.isPublish|FILTER_IsPublish}}</span></template></el-table-column>
          <el-table-column prop="updateTime" label="更新时间" sortable></el-table-column>
          <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <wt-text-button v-has-any-premission="['NEWS:VIEW']" @click="$refs.dataForm.toView(scope.row)">查看详情</wt-text-button>
              <wt-text-button v-has-any-premission="['NEWS:EDIT']" @click="$refs.dataForm.toEdit(scope.row)">编辑</wt-text-button>
            </template>
          </el-table-column>
        </template>
      </wt-data-form>
      <!--弹层 开始-->
      <wt-detail-page :visible.sync="form.isViewDialogVisible"><group-view :detailData="form.detailData" view-type="view" @on-close="form.isViewDialogVisible = false"/></wt-detail-page>
      <wt-detail-page :visible.sync="form.isEditDialogVisible"><group-edit :detailData="form.detailData" :parentData="parentData" :view-type="form.dialogViewType" @on-refresh="onEditRefresh" @on-close="form.isEditDialogVisible = false"/></wt-detail-page>
      <!--弹层 结束-->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'groupList',
  data() {
    return {
      groupList: [],
      activeTab: 'basic',
      parentData: {},
      detailForm: {},
      defaultProps: {
        children: 'children',
        label: 'label',
        isLeaf: 'leaf'
      },
      form: {
        isFromLoading: false,
        isViewDialogVisible: false,
        isEditDialogVisible: false,
        dialogViewType: 'edit',
        detailData: {},
        extFormData: {
          parentId: 1
        },
        formData: {
          name: '',
          updateTimeRange: []
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        formResult: {}
      }
    }
  },
  mounted() {
    // this.$store.dispatch('requestGroupList')
  },
  methods: {
    toAdd() {
      this.form.dialogViewType = 'add'
      this.form.isEditDialogVisible = true
    },
    onGroupTreeCurrentChanged(nodeData, node) {
      this.form.extFormData.parentId = nodeData.id
      this.parentData = nodeData
      this.$refs.dataForm.onClickSearch()
    },
    loadNode(node, resolve) {
      if (node.level === 0) {
        return resolve([{ label: '根节点', id: 1, path: '/1/' }])
      }
      const that = this
      that.$wtRequest({
        url: '/modules/auth/groupTreeList',
        method: 'post',
        data: { id: node.data.id }
      }).then((resp) => {
        resolve(resp.data.filter(item => {
          item.label = item.name
          return true
        }))
      }).catch(() => {
      })
    },
    onEditRefresh() {
      this.$refs.dataForm.requestDataList()
      this.$store.dispatch('requestGroupList', { cacheEnable: false })
    }
  },
  computed: {
    ...mapState({
    })
  }
}
</script>

<style scoped>
  .leftside-container { width: 300px; flex-shrink: 0; min-height: calc(100% - 40px); margin: 20px 0 20px 20px; border-radius: 4px; background-color: #FFFFFF; }
  .leftside-container > .header{height: 50px;border-bottom: 1px solid #F4F4F4;color: #666666;display: flex;align-items: center;justify-content: center; font-size: 16px;}
  .right-container { flex: 1; min-height: calc(100% - 40px);}
  .right-container .group-content{margin: 20px; border-radius: 4px; background-color: #FFFFFF;height: calc(100% - 40px);padding: 20px;}
  .right-container .group-content .header{height: 50px;border-bottom: 1px solid #F4F4F4;color: #666666;display: flex;align-items: center;justify-content: center; font-size: 16px;}
</style>
