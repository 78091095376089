import Vue from 'vue'
import filters from '@/filters/filters'
/*  插件 FILTER 开始 */
// const pluginsFilters = require.context('@/plugins', true, /filter\/index\.js$/)
// pluginsFilters.keys().forEach(key => {
//   const objs = pluginsFilters(key).default
//   Object.keys(objs).forEach(key => {
//     Vue.filter(key, objs[key])
//   })
// })
/*  插件 FILTER 结束 */
// Object.keys(filters).forEach(key => {
//   Vue.filter(key, filters[key])
// })

//  示例：自定义特别filter
// Vue.filter('FILTER_NUM_STATUS_TEXT', (value) => {
//   if (value && value > 0) {
//     return '有'
//   }
//   return '无'
// })
//
// Vue.filter('FILTER_FILENO_ELLIPSIS', (value) => {
//   const resultTextLength = 20
//   if (value && value.length > resultTextLength) {
//     const halfTextLength = Math.ceil(resultTextLength / 2 - 3)
//     return value.substr(0, halfTextLength) + '...' + value.substr(-1 * halfTextLength, halfTextLength)
//   }
//   return value
// })

Object.keys(filters).forEach(key => {
  Vue.filter(key, (value) => {
    return filters[key][value + ''] || filters[key]['default'] || '-'
  })
})

export default {}
