<template>
  <div class="chronic-picker">
    <wt-button class="node-picker-btn" type="primary" size="small" @click="dialogFormVisible = true">选择用户</wt-button>
    <el-dialog :visible.sync="dialogFormVisible" width="950px" :destroy-on-close="true" :append-to-body="true" custom-class="wt-picker">
      <div class="node-picker-content">
        <div class="node-picker-list">
          <div class="node-picker-inputs" style="margin-bottom: 10px;">
            <span>姓名：</span>
            <el-input v-model="form.formData.realname" size="small" type="input" style="width: 200px;margin-right: 8px;"></el-input>
            <span>身份证号：</span>
            <el-input v-model="form.formData.idcardNumber" size="small" type="input" style="width: 200px;margin-right: 8px;"></el-input>
            <span>手机号：</span>
            <el-input v-model="form.formData.mobile" size="small" type="input" style="width: 200px;margin-right: 8px;"></el-input>
            <wt-button type="primary" size="small" :loading="form.isFromLoading" @click="onClickSearch" style="margin-left: 16px;">查询</wt-button>
          </div>
          <div class="node-picker-table" style="height: 500px;">
            <wt-form-table ref="teacherTable" :form.sync="form" height="100%" size="mini">
              <el-table-column type="index" label="序号"></el-table-column>
              <el-table-column prop="realname" label="姓名"></el-table-column>
              <el-table-column prop="idcardNumber" label="身份证号" min-width="130"></el-table-column>
              <el-table-column prop="mobile" label="手机号" min-width="100"></el-table-column>
              <el-table-column prop="miCenterName" label="医保中心" min-width="150"></el-table-column>
              <el-table-column prop="gender" label="性别">
                <template slot-scope="scope">{{scope.row.gender|FILTER_Gender}}</template>
              </el-table-column>
              <el-table-column prop="age" label="年龄"></el-table-column>
              <el-table-column label="操作" fixed="right" width="80">
                <template slot-scope="scope">
                  <wt-text-button v-if="selectDataIdList.indexOf(scope.row.id) != -1" v-has-any-premission="['MANAGER_LIST:VIEW']" @click="onClickRowUnSelect(scope.row)">取消选择</wt-text-button>
                  <wt-text-button v-else v-has-any-premission="['MANAGER_LIST:VIEW']" @click="onClickRowSelect(scope.row)">选择</wt-text-button>
                </template>
              </el-table-column>
            </wt-form-table>
          </div>
          <wt-form-pagination :form="form" @request-form-list="requestDataList"/>
        </div>
      </div>
      <div slot="footer">
        <div class="picker-tags">
          <template v-if="selectDataList.length > 3">
            <el-tag class="picker-tag" :disable-transitions="true" v-for="(selectData, index) in selectDataList" v-if="index < 3" :key="selectData.id"  size="medium" @close="onClickTagClose(selectData)" closable>{{selectData.realname}}</el-tag>
            <span style="font-size: 12px;color: #333;margin-left: 6px;">等{{selectDataList.length}}条数据</span>
          </template>
          <el-tag class="picker-tag" :disable-transitions="true" v-else v-for="selectData in selectDataList" :key="selectData.id"  size="medium" @close="onClickTagClose(selectData)" closable>{{selectData.realname}}</el-tag>
        </div>
        <el-button type="primary" size="small" @click="onClickClear">清空</el-button>
        <el-button type="primary" size="small" @click="onClickOk">确定</el-button>
        <el-button size="small" @click="dialogFormVisible = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'userPicker',
  isAutoComponent: true,
  props: {
    multiple: {
      default: false
    },
    data: {
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      dialogFormVisible: false,
      nodeData: {},
      dataValue: '',
      selectDataList: [],
      selectDataIdList: [],
      form: {
        isFromLoading: false,
        isViewDialogVisible: false,
        isEditDialogVisible: false,
        dialogViewType: 'edit',
        detailData: {},
        formData: {
        },
        pageData: { currentPage: 1, pageSize: 10, sortName: '', sortOrder: '' },
        formResult: {}
      }
    }
  },
  watch: {
    data(val, oldVal) {
      const that = this
      if (!oldVal || oldVal.length <= 0) {
        const tempSelectDataIdList = []
        const tempDataList = []
        if (that.data) {
          that.data.forEach(item => {
            tempSelectDataIdList.push(item.id)
            tempDataList.push(item)
          })
        }
        that.selectDataIdList = tempSelectDataIdList
        that.selectDataList = tempDataList
      }
    }
  },
  mounted() {
    this.requestDataList()
  },
  methods: {
    onClickSearch() {
      this.requestDataList()
    },
    onClickRowSelect(rowData) {
      const id = rowData.id
      if (this.multiple && this.selectDataIdList.indexOf(id) === -1) {
        this.selectDataIdList.push(id)
        this.selectDataList.push(rowData)
      } else if (!this.multiple) {
        this.selectDataIdList = [id]
        this.selectDataList = [rowData]
      }
    },
    onClickRowUnSelect(rowData) {
      const id = rowData.id
      if (this.multiple && this.selectDataIdList.indexOf(id) !== -1) {
        const index = this.selectDataIdList.indexOf(id)
        this.selectDataIdList.splice(index, 1)
        this.selectDataList.splice(index, 1)
      } else if (!this.multiple) {
        this.selectDataIdList = []
        this.selectDataList = []
      }
    },
    onClickTagClose(tagData) {
      const id = tagData.id
      if (this.selectDataIdList.indexOf(id) !== -1) {
        const index = this.selectDataIdList.indexOf(id)
        this.selectDataIdList.splice(index, 1)
        this.selectDataList.splice(index, 1)
      }
    },
    onClickClear() {
      this.selectDataIdList = []
      this.selectDataList = []
      this.$emit('on-user-picker-change', this.selectDataList)
      this.$emit('input', this.selectDataList)
      this.dialogFormVisible = false
    },
    onClickOk() {
      if (!this.selectDataList || this.selectDataList.length <= 0) {
        this.$message({ type: 'warning', message: '请选择至少一条数据' })
        return
      }
      this.$emit('on-user-picker-change', this.selectDataList)
      this.$emit('input', this.selectDataList)
      this.dialogFormVisible = false
    },
    requestDataList() {
      const that = this
      that.form.isFromLoading = true
      that.$wtRequest({
        url: '/modules/cdi/cdiUserList',
        method: 'post',
        data: {
          formData: that.form.formData,
          pageData: that.form.pageData
        }
      }).then((resp) => {
        that.form.isFromLoading = false
        that.form.formResult = resp.data
      }).catch(() => {
        that.form.isFromLoading = false
      })
    }
  }
}
</script>

<style scoped>
  .chronic-picker{display: flex;}
  .chronic-picker .node-picker-inputs{display: flex;flex-wrap: wrap;}
  .chronic-picker .node-picker-inputs .node-picker-input-item{width: calc(50% - 16px);}
  .chronic-picker .node-picker-buttons{display: flex;justify-content: flex-end;}
  .chronic-picker .node-picker-content{max-height: 500px;display: flex;}
  .chronic-picker .node-picker-content .node-picker-school-tree{width: 300px;overflow: auto;flex-shrink: 0;}
  .chronic-picker .node-picker-content .node-picker-list{margin: 0 16px;display: flex;flex-direction: column;width: calc(100% - 32px)}
  .chronic-picker .node-picker-content .node-picker-list .node-picker-table{height: calc(100% - 140px);overflow: hidden;}
  .chronic-picker .node-picker-input{min-width: 100px;width: 50%;}
  .chronic-picker .node-picker-btn{margin-left: 0;}
</style>
<style>
  .picker-tags{display: flex;margin-right: 16px;display: inline-block;}
  .picker-tags .picker-tag{margin-left: 4px;}
</style>
