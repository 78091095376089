<template>
  <div class="one_box">
    <div class="content">
      <div class="time">截止今日{{ time }}</div>
      <div class="content_div">
        <div class="one">{{ patientNum }}</div>
        <div class="two">注册患者人数</div>
      </div>
      <div class="content_div">
        <div class="one">{{ doctorNum }}</div>
        <div class="two">服务医生人数</div>
      </div>
      <div class="content_div">
        <div class="one">{{ relationNum }}</div>
        <div class="two">医患交流次数</div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { index } from '@/api/course.js'
export default {
  data() {
    return {
      time: moment(new Date()).format('YYYY-MM-DD'),
      doctorNum: '',
      patientNum: '',
      relationNum: ''
    }
  },
  methods: {
    huoqushuju() {
      index()
        .then(res => {
          console.log(res)
          this.doctorNum = res.data.doctorNum
          this.patientNum = res.data.patientNum
          this.relationNum = res.data.relationNum
        })
        .catch(res => {
          console.log(res)
        })
    }
  },
  created() {
    this.huoqushuju()
  }
}
</script>

<style lang="scss" scoped>
.one_box {
  padding: 20px;
  .content {
    width: 100%;
    height: 617px;
    background: #fff;
    padding: 50px;
    font-size: 14px;
    .time {
      margin-bottom: 50px;
    }
    .content_div {
      width: 25%;
      height: 240px;
      border: 1px solid #d8d8d8;
      text-align: center;
      padding-top: 80px;
      display: inline-block;
      margin-right: 51px;
      border-radius: 6px;
      .one {
        font-size: 24px;
        margin-bottom: 20px;
      }
      .two {
        font-size: 12px;
      }
    }
  }
}
</style>
