<template>
  <div class="wt-form-tablelist-container">

    <div class="wt-form-tablelist-title">
      <div class="title">查询结果</div>
      <div class="buttons">

        <el-form size="small" class="wt-form-btns" :inline="true">
          <template v-if="!$slots.default">
            <template v-for="(item, index) in form.formButtonsData" >
              <template v-if="!item.permission">
                <el-button size="small" v-bind:key="index" :type="item.className ? item.className : 'default'" @click="toClickAction({}, item)">{{item.name}}</el-button>
              </template>
              <wt-auth v-else class="search-form-btn" :hasAnyPremission="form.formCode + ':' + item.permission" v-bind:key="index">
                <el-button size="small" :type="item.className ? item.className : 'default'" @click="toClickAction({}, item)">{{item.name}}</el-button>
              </wt-auth>
            </template>
          </template>
          <slot name="default"></slot>
          <slot name="custom"></slot>
        </el-form>
      </div>
    </div>
    <div class="wt-form-tablelist-content">

      <el-table class="wt-table" header-row-class-name="wt-table-header-row" v-loading.body="form.isFormLoading" element-loading-text="加载中.."
                :data="form.formResult.list" :default-sort="{prop: '', order: 'descending'}"
                @selection-change="onMultipleSelectionChange" @sort-change="onSortChange" size="mini"
                @current-change="onCurrentChange"
                fit highlight-current-row>

        <template v-if="form.formTableProp && form.formTableProp.selection">
          <el-table-column v-if="form.formTableProp.selection === 'single'" width="45">
            <template slot-scope="scope">
              <input type="radio" :name="formUuid" :checked="formSelectedId === scope.row.id">
            </template>
          </el-table-column>
          <el-table-column v-else :type="form.formTableProp.selection" width="45"></el-table-column>
        </template>
        <el-table-column v-else type="selection" width="45"></el-table-column>

        <el-table-column v-for="(item, index) in form.formTableData" :key="index" :label='item.name' :prop="item.key"
                         :sortable="item.sortable" :min-width="item.minWidth" :width="item.width" v-bind="item.attr">

          <template slot-scope="scope">
            <i v-if="item.type === 'datetime'" class="el-icon-time"></i>
            <template v-if="item.type === 'enum'" >
              <span :class="getDataValueClass(scope.row, item)">{{getDataValue(scope.row, item)}}</span>
            </template>
            <template v-else-if="item.type === 'custom'">
              <slot :name="item.key" :item="item" :row="scope.row" />
            </template>
            <template v-else>
              <span>{{getDataValue(scope.row, item)}}</span>
            </template>
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center" v-if="form.formTableProp ? !form.formTableProp.buttonHidden : true"
                         :width="form.formTableProp ? form.formTableProp.buttonWidth : ''"
                         :min-width="form.formTableProp ? form.formTableProp.buttonMinWidth : ''">
          <template slot-scope="scope">

            <template v-for="(item, index) in form.formTableButtonsData">
              <wt-auth class="search-form-btn" v-bind:key="index" :hasAnyPremission="!item.permission ? '' : form.formCode + ':' + item.permission">
                <el-button size="mini"
                           :type="item.className ? item.className : 'default'" @click="toClickAction(scope.row, item)"
                >{{ item.condition ? item.value[scope.row[item.condition]] : item.name}}</el-button>
              </wt-auth>
            </template>

            <slot name="wtFormTableListButtonsAfter" :row="scope.row" />

          </template>
        </el-table-column>

      </el-table>

    </div>

    <wt-detail-page :visible.sync="isAddDialogVisible" :component-name="form.formComponent.add.componentName" component-type="add" @on-button-action="onButtonAction" />
    <wt-detail-page :visible.sync="isViewDialogVisible" :component-name="form.formComponent.view.componentName" @request-list="requestList" @on-button-action="onButtonAction" />
    <wt-detail-page :visible.sync="isEditDialogVisible" :component-name="form.formComponent.edit.componentName" component-type="edit" @request-list="requestList" @on-button-action="onButtonAction" />

  </div>
</template>

<script>
import request from '@/utils/request'
import { mapState } from 'vuex'
export default {
  name: 'wt-form-table-list',
  props: ['form'],
  data() {
    return {
      formUuid: '',
      formSelectedId: '',
      isAddDialogVisible: false,
      isViewDialogVisible: false,
      isEditDialogVisible: false
    }
  },
  created() {
    this.formUuid = this.$wtUtil.uuid()
    if (!this.form.formResult) {
      this.form.formResult = {}
    }
    if (!this.form.formResult.list) {
      this.form.formResult.list = []
    }
  },
  methods: {
    onButtonAction(row) {
      this.$emit('on-button-action', row)
    },
    /*  ------------------------- 表格和分页  -------------------------  */
    onMultipleSelectionChange(data) {
      this.form.formSelectedList = data
    },
    onCurrentChange(row) {
      if (this.form.formTableProp && this.form.formTableProp.selection && this.form.formTableProp.selection === 'single') {
        this.form.formSelectedList = []
        this.form.formSelectedList.push(row)
        this.formSelectedId = row.id
      }
    },
    onSortChange(column) {
      //  点击排序
      this.form.pageData.sortName = column.prop
      this.form.pageData.sortOrder = column.order
      // this.$emit('request-form-list')
      this.requestList()
    },
    toClickAction(row, item) {
      // 增加兼容对象逻辑
      if (item.action === 'DELETE_LIST') {
        this.toDeleteMutil()
      } else if (item.action === 'ADD') {
        this.isAddDialogVisible = true
        if (this.form.formComponent && this.form.formComponent.add) {
          this.$store.dispatch(this.form.formComponent.add.value, this.form.formComponent.add.params)
        }
      } else if (item.action === 'VIEW') {
        this.toView(item, row)
      } else if (item.action === 'EDIT') {
        this.toEdit(item, row)
      } else if (item.action === 'DELETE') {
        this.toDelete(item, row)
      } else if (item.action === 'EXCEL') {
        this.exportExcel(item.url)
      }
      this.$emit('on-button-action', { item, row })
    },
    // 新加的导出！！！！
    exportExcel(url) {
      this.$wtUtil.downloadFile({
        url: url,
        data: { formData: this.inputData.formData },
        fileName: '工单.xls'
      }).then((res) => {
        console.log('导出成功')
      }).catch(() => {
      })
    },
    getDataValue(row, item) {
      if (row && item && item.key && item.filter && this.$options.filters[item.filter]) {
        return this.$options.filters[item.filter](row[item.key])
      } else if (row && item && item.key) {
        return row[item.key]
      }
      return ''
    },
    getDataValueClass(row, item) {
      if (item.filter && !item.filterClass) {
        item.filterClass = item.filter + '_Class'
      }
      if (row && item && item.key && item.filterClass && this.$options.filters[item.filterClass]) {
        return this.$options.filters[item.filterClass](row[item.key])
      }
      return ''
    },
    toView(item, row) {
      //  点击单条查看
      this.isViewDialogVisible = true
      if (this.form.formComponent && this.form.formComponent.view) {
        this.$store.dispatch(this.form.formComponent.view.value, row.id)
      }
    },
    toEdit(item, row) {
      //  点击单条编辑
      this.isEditDialogVisible = true
      if (this.form.formComponent && this.form.formComponent.edit) {
        this.$store.dispatch(this.form.formComponent.edit.value, row).then(() => {}).catch(() => {})
      }
    },
    toDelete(item, row) {
      //  点击单条删除
      const that = this
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.requestDelete(item, row)
      }).catch(() => {
      })
    },
    requestDelete(item, row) {
      const url = (this.form.formComponent && this.form.formComponent.delete ? this.form.formComponent.delete.value : '')
      const that = this
      request({
        url: url,
        method: 'post',
        data: { list: [row.id] }
      }).then(response => {
        // that.$emit('request-form-list')
        this.$emit('on-button-action', { item, row })
        that.requestList()
      }).catch(error => {
        console.log('error', error)
      })
    },
    toDeleteMutil(row, item) {
      if (!this.form.formSelectedList || this.form.formSelectedList.length <= 0) {
        this.$alert('请选中至少一列数据', '提示', {
          type: 'error',
          confirmButtonText: '确定'
        }).then(() => {
        }).catch(() => {})
        return
      }
      const that = this
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const dataIdList = []
        that.form.formSelectedList.forEach((item) => {
          dataIdList.push(item.id)
        })
        that.requestDeleteMutil(dataIdList)
      }).catch(() => {
      })
    },
    requestDeleteMutil(dataIdList) {
      const url = (this.form.formComponent && this.form.formComponent.delete ? this.form.formComponent.delete.value : '')
      const that = this
      request({
        url: url,
        method: 'post',
        data: { list: dataIdList }
      }).then(response => {
        that.requestList()
        that.$emit('on-button-action', 'update')
      }).catch(error => {
        console.log('error', error)
      })
    },
    requestList() {
      if (this._events && this._events['request-form-list']) {
        this.$emit('request-form-list')
      } else if (this.$parent && this.$parent.requestFormList) {
        this.$parent.requestFormList()
      }
    },
    selectedListId() {
      const idList = []
      if (this.form.formSelectedList) {
        this.form.formSelectedList.forEach((item) => {
          idList.push(item.id)
        })
      }
      return idList
    }
  },
  computed: {
    isFormTableButtonsDataShow() {
      let value = false
      if (this.form.formTableButtonsData) {
        this.form.formTableButtonsData.forEach((item) => {
          if (item.hidden !== undefined && !item.hidden) {
            value = true
          }
        })
      }
      return value
    },
    ...mapState({
      inputData: state => state.excelExport.data
    })
  }
}
</script>

<style scoped>
</style>
