<template>
  <div class="page-detail-container">
    <!--顶部标题和页面全局按钮-->
    <wt-detail-header title="详情">
      <wt-button v-if="viewType == 'add'" v-has-any-premission="['ROLE:ADD']" type="primary" :loading="isButtonLoading" @click="onClickAdd">保存</wt-button>
      <wt-button v-if="viewType == 'edit'" v-has-any-premission="['ROLE:EDIT']" type="primary" :loading="isButtonLoading" @click="onClickSave">保存</wt-button>
      <wt-button @click="$emit('on-close')">返回</wt-button>
    </wt-detail-header>
    <!--内容区域-->
    <div class="wt-detail-container" v-loading="isFromLoading">
      <wt-detail-section title="基本信息">
        <el-form :model="detailForm" ref="form" size="small" class="wt-detail-section-form-center" label-width="30%">
          <el-form-item label="组名称" prop="name" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <el-input v-model="detailForm.name" placeholder="请输入" disabled></el-input>
          </el-form-item>
          <el-form-item label="父节点" prop="parentId" :rules="[{ required: true, message: '选择内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' }]">
            <el-cascader v-model="detailForm.parentIds" :options="groupList" :props="{checkStrictly: true}" @change="onParentIdChanged" disabled></el-cascader>
          </el-form-item>
          <el-form-item label="发布状态：" prop="isPublish" :rules="[{ required: true, message: '请选择发布状态', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' }]">
            <wt-form-simple-select-item v-model="detailForm.isPublish" disabled/>
          </el-form-item>
        </el-form>
      </wt-detail-section>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import staticRoutes from '@/router/staticRoutes.js'
export default {
  name: 'groupView',
  isAutoComponent: true,
  props: ['viewType', 'detailData', 'parentData'],
  data() {
    return {
      roleList: [],
      staticRoutes: staticRoutes,
      defaultCheckedKeys: [],
      isButtonLoading: false,
      isFromLoading: false,
      detailForm: {
        parentId: '',
        parentIds: [],
        isPublish: 1
      }
    }
  },
  mounted() {
    if (this.viewType !== 'add') {
      this.requestDetail()
    } else if (this.parentData && this.parentData.path) {
      const that = this
      const tempParentIds = []
      this.parentData.path.split('/').forEach(item => {
        if (that.$wtUtil.isNotBlank(item)) {
          tempParentIds.push(parseInt(item))
        }
      })
      this.detailForm.parentIds = tempParentIds
    }
  },
  created() {
    this.$store.dispatch('requestGroupList')
  },
  methods: {
    onClickAdd() {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestAdd()
      }).catch(() => {
      })
    },
    onClickSave() {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestUpdate()
      }).catch(() => {
      })
    },
    onParentIdChanged(val) {
      if (val && val.length > 0) {
        this.detailForm.parentId = val[val.length - 1]
      } else {
        this.detailForm.parentId = ''
      }
      this.detailForm.parentIds = val
    },
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: '/modules/auth/groupDetail',
        method: 'post',
        data: { id: that.detailData.id }
      }).then((resp) => {
        that.detailForm = resp.data
        const tempParentIds = []
        that.detailForm.path.split('/').forEach(item => {
          if (that.$wtUtil.isNotBlank(item)) {
            tempParentIds.push(parseInt(item))
          }
        })
        that.detailForm.parentIds = tempParentIds.length > 0 ? tempParentIds.slice(0, -1) : tempParentIds
        that.isFromLoading = false
      }).catch(() => {
        that.isFromLoading = false
      })
    },
    requestUpdate() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that.$wtRequest({
        url: '/modules/auth/groupUpdate',
        method: 'post',
        data: that.detailForm
      }).then((resp) => {
        that.isButtonLoading = false
        that.isFromLoading = false
        that.$message({ type: 'success', message: '保存成功' })
        that.$emit('on-refresh')
        that.$emit('on-close')
      }).catch(() => {
        that.isButtonLoading = false
        that.isFromLoading = false
      })
    },
    requestAdd() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that.$wtRequest({
        url: '/modules/auth/groupAdd',
        method: 'post',
        data: that.detailForm
      }).then((resp) => {
        that.isButtonLoading = false
        that.isFromLoading = false
        that.$message({ type: 'success', message: '保存成功' })
        that.$emit('on-refresh')
        that.$emit('on-close')
      }).catch(() => {
        that.isButtonLoading = false
        that.isFromLoading = false
      })
    }
  },
  computed: {
    ...mapState({
      groupList: state => state.ops.groupList
    })
  }
}
</script>
