<template>
  <div class="dialog-container wt-dialog-container">
    <div v-loading="isDutyDetailLoading">
      <wt-detail-form-buttons :detail-form-buttons.sync="detailFormButtons" @on-detail-button-action="onDetailButtonAction"></wt-detail-form-buttons>
      <wt-detail-form-section ref="formSection" :detail-form-data.sync="detailFormData" :detail-form.sync="detailForm" />
      <wt-detail-form-section ref="formSection2" :detail-form-data.sync="detailFormData" :detail-form.sync="detailForm2" />
      <wt-detail-form-section ref="formSection3" :detail-form-data.sync="detailFormData" :detail-form.sync="detailForm3" />
      <wt-detail-blank-section title="自定义区域1">
        <div style="height: 300px;background-color: gray;"></div>
      </wt-detail-blank-section>
      <wt-detail-blank-section title="自定义区域2">
        <div style="height: 300px;background-color: seagreen;"></div>
      </wt-detail-blank-section>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'test2-view',
  props: ['component-type'],
  isAutoComponent: true,
  data() {
    return {
      formCode: 'MODULES_DUTY',
      isSubmitLoading: false,
      formUpdateUrl: '/modules/duty/dutyUpdate',
      formAddUrl: '/modules/duty/dutyAdd',
      detailFormButtons: {
        title: '详情',
        elements: [{ name: '自定义按钮1', key: 'CUSTOM1', action: 'CUSTOM1' },
          { name: '自定义按钮2', key: 'CUSTOM2', action: 'CUSTOM2', className: 'default' },
          { name: '自定义按钮3', key: 'CUSTOM3', action: 'CUSTOM3', className: 'primary ' },
          { name: '自定义按钮4', key: 'CUSTOM4', action: 'CUSTOM4', className: 'danger' },
          { name: '返回', key: 'CLOSE', action: 'CLOSE' }]
      },
      detailForm: {
        title: '第一块信息',
        elements: [
          { name: '岗位名称', key: 'dutyName', type: 'text', disabled: true },
          { name: '部门id', key: 'organizationId', type: 'text', disabled: true }]
      },
      detailForm2: {
        title: '第二块信息',
        elements: [
          { name: '岗位名称2', key: 'dutyName', type: 'text', disabled: true }]
      },
      detailForm3: {
        title: '第三块信息',
        elements: [
          { name: '岗位名称3', key: 'dutyName', type: 'text', disabled: true }]
      }
    }
  },
  created() {
  },
  methods: {
    /*  ------------------------- 按钮  -------------------------  */
    onDetailButtonAction(item) {
      if (item.action === 'CLOSE') {
        this.$emit('on-dialog-action', 'close')
      }
    },
    /*  ------------------------- 网络请求  -------------------------  */
    onWtDialogClose() {
      this.$emit('on-dialog-action', 'close')
    }
  },
  computed: {
    ...mapState({
      isDutyDetailLoading: state => state.duty.isDutyDetailLoading,
      detailFormData: state => state.duty.dutyDetailData
    })
  }
}
</script>

<style scoped>

</style>
