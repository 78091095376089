<template>
  <div class="page-container">
    <wt-data-form
      ref="dataForm"
      :form.sync="form"
      request-list-url="/modules/fixedCycle/list"
      request-delete-url="/modules/fixedCycle/delete"
      request-detail-url="/modules/fixedCycle/detail"
    >
      <template slot="search">
        <wt-search-form-item
          title="主键"
          v-model="form.formData.id"
          type="select"
        />
        <wt-search-form-item
          title="提醒ID"
          v-model="form.formData.remindId"
          type="select"
        />
        <wt-search-form-item
          title="周几 1 星期一 2星期二 3星期三 4星期四 5星期五 6星期六 7星期日"
          v-model="form.formData.weekDay"
          type="select"
        />
        <wt-search-form-item
          title="创建时间"
          v-model="form.formData.createTime"
          type="daterange"
        />
        <wt-search-form-item
          title="是否删除"
          v-model="form.formData.isDelete"
          type="select"
        />
      </template>
      <template slot="toolbar">
        <wt-button
          type="primary"
          v-has-any-premission="['FIXEDCYCLE:ADD']"
          @click="$refs.dataForm.toAdd()"
          >新增</wt-button
        >
        <wt-button
          type="danger"
          v-has-any-premission="['FIXEDCYCLE:DELETE']"
          @click="$refs.dataForm.onClickMultipleDelete()"
          >删除</wt-button
        >
      </template>
      <template slot="table">
        <el-table-column type="selection" label="选择"></el-table-column>
        <el-table-column prop="id" label="主键" sortable></el-table-column>
        <el-table-column
          prop="remindId"
          label="提醒ID"
          sortable
        ></el-table-column>
        <el-table-column
          prop="weekDay"
          label="周几 1 星期一 2星期二 3星期三 4星期四 5星期五 6星期六 7星期日"
          sortable
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          sortable
        ></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <wt-text-button
              v-has-any-premission="['FIXEDCYCLE:VIEW']"
              @click="$refs.dataForm.toView(scope.row)"
              >查看详情</wt-text-button
            >
            <wt-text-button
              v-has-any-premission="['FIXEDCYCLE:EDIT']"
              @click="$refs.dataForm.toEdit(scope.row)"
              >编辑</wt-text-button
            >
          </template>
        </el-table-column>
      </template>
    </wt-data-form>
    <!--弹层 开始-->
    <wt-detail-page :visible.sync="form.isViewDialogVisible"
      ><FixedCycleView
        :detailData="form.detailData"
        view-type="view"
        @on-close="form.isViewDialogVisible = false"
    /></wt-detail-page>
    <wt-detail-page :visible.sync="form.isEditDialogVisible"
      ><FixedCycleEdit
        :detailData="form.detailData"
        :view-type="form.dialogViewType"
        @on-refresh="$refs.dataForm.requestDataList()"
        @on-close="form.isEditDialogVisible = false"
    /></wt-detail-page>
    <!--弹层 结束-->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'fixedCycleList',
  data() {
    return {
      form: {
        isFromLoading: false,
        isViewDialogVisible: false,
        isEditDialogVisible: false,
        dialogViewType: 'edit',
        detailData: {},
        extFormData: {},
        formData: {
          name: '',
          updateTimeRange: []
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        table: {},
        formResult: {}
      }
    }
  },
  mounted() {
    this.$refs.dataForm.onClickSearch()
  },
  created() {},
  methods: {},
  computed: {
    ...mapState({})
  }
}
</script>
