<template>
  <el-table class="wt-table" cell-class-name="wt-cell" row-class-name="wt-row" header-cell-class-name="wt-header-cell" header-row-class-name="wt-header-row"
            :data="dataList" @selection-change="handleSelectionChange"
            v-loading="form.table ? form.table.loading : false"
            :height="height" :size="size ? size : 'small'" v-bind="form.table ? form.table : {}" >
    <slot></slot>
  </el-table>
</template>

<script>
export default {
  name: 'WtFormTable',
  isAutoComponent: true,
  props: ['form', 'height', 'isTableLoading', 'size'],
  data() {
    return {
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.$emit('selection-change', val)
    }
  },
  computed: {
    dataList() {
      if (this.form == null || this.form.formResult == null) {
        return []
      }
      return this.form.formResult.list
    }
  }
}
</script>

<style>
</style>
