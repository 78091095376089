<template>
  <iframe v-if="isLoad" class="wt-frame" :src="url" frameborder="0" ></iframe>
</template>

<script>
export default {
  name: 'WtFrame',
  data() {
    return {
      isLoad: false,
      url: ''
    }
  },
  mounted() {
    this.url = this.$route.meta.href
    this.isLoad = true
  }
}
</script>

<style scoped>
  .wt-frame{width: 100%;height: 100%;}
</style>
