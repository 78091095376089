<template>
  <div>
    <wt-button type="primary" @click="onClickDialogOpen">{{text}}</wt-button>
    <el-dialog v-if="isDialogFormVisible" :visible.sync="isDialogFormVisible" width="950px" :destroy-on-close="true" :append-to-body="true" custom-class="wt-import-container">
      <div class="body">
        <el-steps :active="stepValue" simple>
          <el-step title="上传" icon="el-icon-upload"></el-step>
          <el-step title="检查并提交" icon="el-icon-document-checked"></el-step>
        </el-steps>
        <div class="content">
          <div v-if="stepValue == 0" style="display: flex;margin: 16px;">
            <div style="display: flex;align-items: center;"><span style="margin-right: 4px;">点击下载：</span>
              <el-link type="primary" @click="onClickDownloadTemplate">模板文件.xlxs</el-link>
            </div>
          </div>
          <div class="content-upload" v-if="stepValue == 0">
            <el-upload class="upload-demo" action="" :http-request="onFileUpload" drag :multiple="false" accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">只能上传excel文件，单文件记录数不建议超过5万条</div>
            </el-upload>
          </div>
          <div class="content-check" v-if="stepValue == 1">
            <el-divider content-position="left">校验通过数据</el-divider>
            <div>
              共 {{successCount}} 条数据
            </div>
            <el-divider content-position="left">校验失败数据</el-divider>
            <div>
              无
            </div>
            <el-divider content-position="left">错误数据</el-divider>
            <div>
              无
            </div>
          </div>
          <div class="content-upload" v-if="stepValue == 2">
          </div>
        </div>
      </div>
      <div slot="footer">
        <template v-if="stepValue == 0 ">
          <el-button type="primary" size="small" @click="onClickNextStep" :loading="isFormLoading">下一步</el-button>
        </template>
        <template v-else-if="stepValue == 1">
          <el-button type="primary" size="small" @click="onClickNextPrev" :loading="isFormLoading">上一步</el-button>
          <el-button type="primary" size="small" @click="onClickCommit" :loading="isFormLoading">提交</el-button>
        </template>
        <el-button size="small" @click="isDialogFormVisible = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'importButton',
  isAutoComponent: true,
  props: {
    templateUrl: {
      type: String,
      default: './'
    },
    text: {
      type: String,
      default: '导入'
    }
  },
  data() {
    return {
      wtTemplateServerUrl: window.wtConst.TEMPLATE_SERVER_URL,
      successCount: '',
      isFormLoading: false,
      stepValue: 0,
      uploadData: {},
      isDialogFormVisible: false
    }
  },
  methods: {
    onClickDialogOpen() {
      this.successCount = Math.floor(Math.random() * 100)
      this.stepValue = 0
      this.uploadData = {}
      this.isFormLoading = false
      this.isDialogFormVisible = true
    },
    onClickDownloadTemplate() {
      window.open(this.wtTemplateServerUrl + 'template/模板.xls', '_blank')
    },
    onClickNextPrev() {
      this.stepValue -= 1
    },
    onClickNextStep() {
      this.stepValue += 1
    },
    onClickCommit() {
      const that = this
      that.isFormLoading = true
      setTimeout(() => {
        that.$message({ type: 'success', message: '导入成功' })
        that.isFormLoading = false
        that.isDialogFormVisible = false
      }, 1000)
    },
    onFileUpload(data) {
      console.log('onFileUpload', data)
      this.uploadData.file = data.file
    }
  }
}
</script>

<style scoped>
  .wt-import-container{}
  .wt-import-container .body{height: 350px;}
  .wt-import-container .content{width: 100%;height: 350px;}
  .wt-import-container .content .content-upload{width: 100%; height: 300px; display: flex;justify-content: center;align-items: center;}
  .wt-import-container .content  .content-check{}
</style>
