
const constants = {}

//  审核状态 0/审核不通过,1/草稿,2/待审核,3/审核通过
constants.roleApprovalState = {
  Reject: 0,
  Draft: 1,
  Review: 2,
  Pass: 3
}

export default constants
