var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-detail-container"},[_c('wt-detail-header',{attrs:{"title":"详情"}},[_c('wt-button',{on:{"click":function($event){return _vm.$emit('on-close')}}},[_vm._v("返回")])],1),_c('div',{staticClass:"wt-detail-container"},[_c('wt-detail-section',{attrs:{"title":"基本信息"}},[_c('el-form',{ref:"form",staticClass:"wt-detail-section-form-center",attrs:{"model":_vm.detailForm,"size":"small","label-width":"30%"}},[_c('el-form-item',{attrs:{"label":"订单号：","prop":"orderNo","rules":[
            {
              required: true,
              message: '订单号不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_vm._v(" "+_vm._s(_vm.detailForm.orderNo)+" ")]),_c('el-form-item',{attrs:{"label":"角色 1 医生 2 患者：","prop":"type","rules":[
            {
              required: true,
              message: '角色 1 医生 2 患者不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择","readonly":"","disabled":""},model:{value:(_vm.detailForm.type),callback:function ($$v) {_vm.$set(_vm.detailForm, "type", $$v)},expression:"detailForm.type"}})],1),_c('el-form-item',{attrs:{"label":"用户：","prop":"userId","rules":[
            {
              required: true,
              message: '用户不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择","readonly":"","disabled":""},model:{value:(_vm.detailForm.userId),callback:function ($$v) {_vm.$set(_vm.detailForm, "userId", $$v)},expression:"detailForm.userId"}})],1),_c('el-form-item',{attrs:{"label":"商品ID：","prop":"giftId","rules":[
            {
              required: true,
              message: '商品ID不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择","readonly":"","disabled":""},model:{value:(_vm.detailForm.giftId),callback:function ($$v) {_vm.$set(_vm.detailForm, "giftId", $$v)},expression:"detailForm.giftId"}})],1),_c('el-form-item',{attrs:{"label":"兑换数量：","prop":"exchangeNum","rules":[
            {
              required: true,
              message: '兑换数量不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择","readonly":"","disabled":""},model:{value:(_vm.detailForm.exchangeNum),callback:function ($$v) {_vm.$set(_vm.detailForm, "exchangeNum", $$v)},expression:"detailForm.exchangeNum"}})],1),_c('el-form-item',{attrs:{"label":"消耗积分：","prop":"point","rules":[
            {
              required: true,
              message: '消耗积分不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择","readonly":"","disabled":""},model:{value:(_vm.detailForm.point),callback:function ($$v) {_vm.$set(_vm.detailForm, "point", $$v)},expression:"detailForm.point"}})],1),_c('el-form-item',{attrs:{"label":"状态 1 待审核 2审核通过，待发货 3审核通过，已发货 4 拒绝：","prop":"status","rules":[
            {
              required: true,
              message:
                '状态 1 待审核 2审核通过，待发货 3审核通过，已发货 4 拒绝不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择","readonly":"","disabled":""},model:{value:(_vm.detailForm.status),callback:function ($$v) {_vm.$set(_vm.detailForm, "status", $$v)},expression:"detailForm.status"}})],1),_c('el-form-item',{attrs:{"label":"拒绝原因：","prop":"reason","rules":[
            {
              required: true,
              message: '拒绝原因不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_vm._v(" "+_vm._s(_vm.detailForm.reason)+" ")]),_c('el-form-item',{attrs:{"label":"物流公司：","prop":"logisticsCompany","rules":[
            {
              required: true,
              message: '物流公司不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_vm._v(" "+_vm._s(_vm.detailForm.logisticsCompany)+" ")]),_c('el-form-item',{attrs:{"label":"快递单号：","prop":"trackingNumber","rules":[
            {
              required: true,
              message: '快递单号不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_vm._v(" "+_vm._s(_vm.detailForm.trackingNumber)+" ")]),_c('el-form-item',{attrs:{"label":"是否发布：","prop":"isPublish","rules":[
            {
              required: true,
              message: '请选择发布状态',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('wt-form-simple-select-item',{attrs:{"readonly":"","disabled":""},model:{value:(_vm.detailForm.isPublish),callback:function ($$v) {_vm.$set(_vm.detailForm, "isPublish", $$v)},expression:"detailForm.isPublish"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }