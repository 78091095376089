<template>
  <div class="wt-detail-form-buttons-container">
    <div class="wt-detail-form-buttons-title">
      <div class="left">
        <template v-if="!$slots.left">
          <span>{{title}}</span>
        </template>
        <slot v-else name="left"></slot>
      </div>
      <div class="right" v-show="!isRightHidden">
        <div class="wt-section-title-icon" @click="isExpanded = !isExpanded">
          <span>{{isExpanded?'收起':'展开'}}</span>
          <i :class="{'el-icon-caret-right': !isExpanded, 'el-icon-caret-bottom': isExpanded}"></i>
        </div>
      </div>
    </div>
    <div v-show="isExpanded" class="wt-form-inputs-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'wt-detail-form-blank-section',
  props: ['title', 'isRightHidden'],
  data() {
    return {
      isExpanded: true
    }
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
