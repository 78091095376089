<template>
  <div class="page-detail-container">
    <!--顶部标题和页面全局按钮-->
    <wt-detail-header title="详情">
      <wt-button
        v-if="viewType == 'add'"
        v-has-any-premission="['ROLELIST:ADD']"
        type="primary"
        :loading="isButtonLoading"
        @click="onClickAdd"
        >保存
      </wt-button>
      <wt-button
        v-if="viewType == 'edit'"
        v-has-any-premission="['ROLELIST:EDIT']"
        type="primary"
        :loading="isButtonLoading"
        @click="onClickSave"
        >保存
      </wt-button>
      <wt-button @click="$emit('on-close')">返回</wt-button>
    </wt-detail-header>
    <!--内容区域-->
    <div class="wt-detail-container">
      <wt-detail-section title="基本信息" v-loading="isFromLoading">
        <el-form
          :model="detailForm"
          ref="form"
          size="small"
          class="wt-detail-section-form-center"
          label-width="30%"
        >
          <el-form-item label="商品名称:" prop="giftName">
            <el-input
              v-model="detailForm.giftName"
              maxlength="20"
              placeholder="请输入"
              :disabled="viewType === 'view'"
            ></el-input>
          </el-form-item>
          <el-form-item label="商品图片:" prop="cover">
            <el-upload
              class="avatar-uploader"
              action="https://meining.100api.com.cn/server/api/file/upload"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            注：图片比例为：1:1，图片格式为jpg/png/jpeg，大小不超过10M
          </el-form-item>
          <el-form-item label="兑换所需积分:" prop="point">
            <el-input
              v-model="detailForm.point"
              maxlength="20"
              placeholder="请输入"
              :disabled="viewType === 'view'"
            ></el-input>
          </el-form-item>
          <el-form-item label="现有库存数:" prop="inventoryNum">
            <el-input
              v-model="detailForm.inventoryNum"
              maxlength="20"
              placeholder="请输入"
              :disabled="viewType === 'view'"
            ></el-input>
          </el-form-item>
          <el-form-item label="增减库存:" prop="inventoryNum">
            <!-- <el-input
              v-model="detailForm.inventoryNum"
              maxlength="20"
              placeholder="请输入"
              :disabled="viewType === 'view'"
            ></el-input> -->
            <el-input-number
              v-model="num"
              controls-position="right"
              :min="0"
            ></el-input-number>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">上架</el-button>
            <el-button @click="quxiao">取消</el-button>
          </el-form-item>
        </el-form>
      </wt-detail-section>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import staticRoutes from '@/router/staticRoutes.js'
import { articleGroupList, giftUpdate } from '@/api/course.js'
export default {
  name: 'patientList2',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      num: '',
      dangqianyijilanmu: [],
      dangqianyijilanmu2: [],
      isPermissionItemVisibility: true,
      staticRoutes: staticRoutes,
      defaultCheckedKeys: [],
      checkedModuleCodes: [],
      checkedPermissionCodes: [],
      modulePermissionMap: {},
      isButtonLoading: false,
      isFromLoading: false,
      detailForm: {
        orderNum: 0,
        isPublish: 1
      },
      imageUrl: ''
    }
  },
  mounted() {
    this.requestPermissionList()
    if (this.viewType !== 'add') {
    }
  },
  created() {
    console.log(this.detailData.cover)
    console.log(this.detailData)
    if (this.detailData.cover) {
      console.log(123)
      console.log(this.detailData)
      this.detailForm = this.detailData
      this.imageUrl =
        'https://meining.100api.com.cn/files/' +
        this.detailForm.cover
      // 一级栏目
      this.num = this.detailData.inventoryNum
    } else {
      console.log(321)
    }
  },
  methods: {
    quxiao() {
      this.detailForm = {}
      this.detailData = {}
      this.$emit('on-close')
    },

    onSubmit() {
      console.log(this.detailForm)
      this.detailForm.inventoryNum = this.num
      giftUpdate(this.detailForm)
        .then(res => {
          console.log(res)
          if (res.code === '0') {
            this.requestUpdate()
          }
        })
        .catch(res => {
          console.log(res)
        })
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
      console.log(this.imageUrl)
      this.detailForm.cover = res.data.url
    },
    huoqujiaodian() {
      console.log(this.detailForm.programaGroupId2)
    },
    onClickAdd() {
      const that = this
      this.$wtUtil
        .validateDetailFormsAndConfirm(this, '是否保存?')
        .then(() => {
          that.requestAdd()
        })
        .catch(() => {})
    },
    onClickSave() {
      const that = this
      this.$wtUtil
        .validateDetailFormsAndConfirm(this, '是否保存?')
        .then(() => {
          that.requestUpdate()
        })
        .catch(() => {})
    },
    onClickTag(code) {
      const tempCheckedPermissionCodes = this.checkedPermissionCodes || []
      const index = tempCheckedPermissionCodes.indexOf(code)
      if (index !== -1) {
        this.checkedPermissionCodes.splice(index, 1)
      } else {
        this.checkedPermissionCodes.push(code)
      }
    },
    onClickItemAction({ action, data }) {
      const moduleName = data.name.toUpperCase()
      if (action === 'currentCheckAll') {
        this.checkAllModulePermission(moduleName)
      } else if (action === 'currentUnCheckAll') {
        this.unCheckAllModulePermission(moduleName)
      } else if (action === 'checkAll') {
        const that = this
        this.staticRoutes.forEach(item => {
          const moduleName = item.name.toUpperCase()
          if (
            !item.hidden &&
            that.checkedModuleCodes.indexOf(moduleName) !== -1
          ) {
          }
        })
      } else if (action === 'unCheckAll') {
      }
    },
    checkAllModulePermission(moduleName) {
      const codes = ['VIEW', 'ADD', 'EDIT', 'DELETE']
      if (
        this.modulePermissionMap[moduleName] &&
        this.modulePermissionMap[moduleName].length > 0
      ) {
        for (let i = 0; i < this.modulePermissionMap[moduleName].length; i++) {
          codes.push(
            this.modulePermissionMap[moduleName][i].permissionCode.toUpperCase()
          )
        }
      }
      for (let i = 0; i < codes.length; i++) {
        const permissionCode = codes[i]
        if (
          this.checkedPermissionCodes.indexOf(
            moduleName + ':' + permissionCode
          ) === -1
        ) {
          this.checkedPermissionCodes.push(moduleName + ':' + permissionCode)
        }
      }
    },
    unCheckAllModulePermission(moduleName) {
      const codes = ['VIEW', 'ADD', 'EDIT', 'DELETE']
      if (
        this.modulePermissionMap[moduleName] &&
        this.modulePermissionMap[moduleName].length > 0
      ) {
        for (let i = 0; i < this.modulePermissionMap[moduleName].length; i++) {
          codes.push(
            this.modulePermissionMap[moduleName][i].permissionCode.toUpperCase()
          )
        }
      }
      for (let i = 0; i < codes.length; i++) {
        const permissionCode = codes[i]
        const permissionIndex = this.checkedPermissionCodes.indexOf(
          moduleName + ':' + permissionCode
        )
        if (permissionIndex !== -1) {
          this.checkedPermissionCodes.splice(permissionIndex, 1)
        }
      }
    },
    onModuleCurrentChanged(nodeData, node) {
      this.checkedModuleCodes = node.checkedKeys.concat(node.halfCheckedKeys)
    },
    requestPermissionList() {
      const that = this
      that
        .$wtRequest({
          url: '/modules/auth/permissionList',
          method: 'post',
          data: {}
        })
        .then(resp => {
          const tempMap = {}
          resp.data.forEach(item => {
            if (!tempMap[item.moduleName]) {
              tempMap[item.moduleName] = []
            }
            tempMap[item.moduleName].push(item)
          })
          that.modulePermissionMap = tempMap
        })
        .catch(() => {})
    },
    requestUpdate() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that
        .$wtRequest({
          url: 'modules/article/list',
          method: 'post',
          data: {
            formData: { name: '', updateTimeRange: [] },
            pageData: {
              currentPage: 1,
              pageSize: 20,
              sortName: '',
              sortOrder: ''
            }
          }
        })
        .then(resp => {
          that.isButtonLoading = false
          that.isFromLoading = false
          that.$message({ type: 'success', message: '保存成功' })
          that.$emit('on-refresh')
          that.$emit('on-close')
        })
        .catch(() => {
          that.isButtonLoading = false
          that.isFromLoading = false
        })
    },
    requestAdd() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that
        .$wtRequest({
          url: '/modules/auth/roleAdd',
          method: 'post',
          data: Object.assign(that.detailForm, {
            moduleList: this.checkedModuleCodes
          })
        })
        .then(resp => {
          that.isButtonLoading = false
          that.isFromLoading = false
          that.$message({ type: 'success', message: '保存成功' })
          that.$emit('on-refresh')
          that.$emit('on-close')
        })
        .catch(() => {
          that.isButtonLoading = false
          that.isFromLoading = false
        })
    }
  },
  computed: {
    moduleTreeData() {
      const that = this
      const treeData = []
      const makeChildrenFunction = staticRoute => {
        const children = []
        if (!staticRoute.hidden) {
          if (staticRoute.children && staticRoute.children.length > 0) {
            const parentRouteId = staticRoute.name.toUpperCase()
            if (that.defaultCheckedKeys.indexOf(parentRouteId) !== -1) {
              that.defaultCheckedKeys.splice(
                that.defaultCheckedKeys.indexOf(parentRouteId),
                1
              )
            }
            staticRoute.children.forEach(child => {
              const routeId = child.name.toUpperCase()
              children.push({
                id: routeId,
                label: child.meta ? child.meta.title : '',
                disabled: that.isReadMode,
                children: makeChildrenFunction(child)
              })
            })
          }
        }
        return children
      }
      this.staticRoutes.forEach(staticRoute => {
        if (!staticRoute.hidden) {
          const routeId = staticRoute.name.toUpperCase()
          treeData.push({
            id: routeId,
            label: staticRoute.meta ? staticRoute.meta.title : '',
            disabled: that.isReadMode,
            children: makeChildrenFunction(staticRoute)
          })
        }
      })
      return treeData
    },
    isReadMode() {
      return this.viewType === 'view'
    },
    ...mapState({})
  }
}
</script>
<style scoped>
.module-permission-content {
  display: flex;
  width: 100%;
}
.module-permission-content .left-content {
  width: 250px;
  padding: 0 24px;
}
.module-permission-content .right-content {
  flex: 1;
}
.module-menu-content {
  display: flex;
  width: 100%;
}
.module-menu-content .left-content {
  width: 300px;
  padding: 0 24px;
}
.module-menu-content .right-content {
  flex: 1;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
