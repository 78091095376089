<template>
  <div class="login-container">
    <div class="logo-container">
      <div class="logo-content">
        <img alt="" />
      </div>
    </div>
    <div class="content"></div>
    <div class="left-container"></div>
    <div class="right-container">
      <div class="login-form-container">
        <div class="login-form">
          <h2>欢迎使用 {{ appName }}</h2>
          <p class="subtitle">请使用系统分配的账号进行登录</p>
          <div class="form">
            <div class="form-input">
              <label class="title">用户名</label>
              <div :class="[isUsernameValidate ? '' : 'is-invalid']">
                <el-input
                  v-model="formData.username"
                  size="medium"
                  maxlength="50"
                  placeholder="example@example.com"
                  @blur="onValidateUsername"
                  @keyup.enter.native="onClickLogin"
                ></el-input>
                <span class="err-msg">用户名不能为空</span>
              </div>
            </div>
            <div class="form-input">
              <label class="title">密码</label>
              <div :class="[isPasswordValidate ? '' : 'is-invalid']">
                <el-input
                  v-model="formData.password"
                  type="password"
                  maxlength="50"
                  size="medium"
                  placeholder="********"
                  show-password
                  @blur="onValidatePassword"
                  @keyup.enter.native="onClickLogin"
                ></el-input>
                <span class="err-msg">密码不能为空</span>
              </div>
            </div>
            <div class="form-input">
              <el-checkbox v-model="formData.isRemember">记住我</el-checkbox>
            </div>
            <div class="form-input">
              <el-button
                class="login-button"
                type="primary"
                size="medium"
                :loading="isLoading"
                @click="onClickLogin"
                >登 录</el-button
              >
            </div>
          </div>
          <div class="other"></div>
          <div>
            <el-divider
              ><span class="other-login-text">其他登录方式</span></el-divider
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setStorge } from '@/utils/auth'
export default {
  name: 'Login',
  components: {},
  data() {
    return {
      appName: process.env.VUE_APP_NAME,
      isAnnouncementVisiable: false,
      announcementContent: '',
      isLoading: false,
      isUsernameValidate: true,
      isPasswordValidate: true,
      formData: {
        isRemember: false,
        username: '',
        password: ''
      }
    }
  },
  mounted() {},
  methods: {
    onClickLogin() {
      const that = this
      this.onValidateUsername()
      this.onValidatePassword()
      if (!this.isUsernameValidate || !this.isPasswordValidate) {
        return
      }
      that.isLoading = true
      that.$store
        .dispatch('requestLogin', this.formData)
        .then(() => {
          setStorge('x-is-remember', that.formData.isRemember)
          that.isLoading = false
          that.$router.push({ path: '/' })
        })
        .catch(() => {
          that.isLoading = false
        })
    },
    onValidateUsername() {
      if (this.$wtUtil.isBlank(this.formData.username)) {
        this.isUsernameValidate = false
      } else {
        this.isUsernameValidate = true
      }
    },
    onValidatePassword() {
      if (this.$wtUtil.isBlank(this.formData.password)) {
        this.isPasswordValidate = false
      } else {
        this.isPasswordValidate = true
      }
    }
  },
  computed: {}
}
</script>

<style lang="scss" scoped>
.login-container {
  width: 100%;
  height: 100%;
  min-width: 1280px;
  min-height: 800px;
  position: relative;
  display: flex;
  background-color: #f8f8f8;
}
.login-container .logo-container .logo-content {
  width: 8rem;
  height: 4rem;
  position: absolute;
  left: 2rem;
  top: 2rem;
}
.login-container .logo-container .logo-content img {
  width: 100%;
  height: auto;
}
.login-container .left-container {
  width: 100%;
  height: 100%;
  flex: 0 0 66.67%;
  padding: 4rem;
  display: flex;
}
.login-container .left-container .launch-bg {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 4rem;
  padding-right: 4rem;
}
.login-container .left-container .launch-bg > img {
  max-width: 100%;
  height: auto;
}
.login-container .right-container {
  flex: 0 0 33.33%;
  background-color: #ffffff;
}
.login-container .right-container .form {
  margin-top: 1.5rem;
}
.login-container .right-container .login-form-container {
  height: 100%;
  display: flex;
  align-items: center;
}
.login-container .right-container .login-form {
  padding: 4rem 5.5rem;
  width: 100%;
}
.login-container .right-container .login-form h2 {
  color: #5e5873;
  margin-top: 0;
  margin-bottom: 1rem;
}
.login-container .right-container .login-form .subtitle {
  font-size: 1rem;
  color: #6e6b7b;
  margin-top: 0;
  margin-bottom: 1rem;
}
.login-container .right-container .login-form .form-input {
  margin-bottom: 1rem;
}
.login-container .right-container .login-form .form-input .title {
  display: block;
  font-size: 0.857rem;
  color: #5e5873;
  margin-bottom: 0.2857rem;
}
.login-container .right-container .login-form .form-input .remember {
  display: block;
  font-size: 1rem;
  color: #5e5873;
  margin-bottom: 0;
}
.login-container .right-container .login-form .form-input .input-control {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}
.login-container .right-container .login-form .form-input .login-button {
  width: 100%;
}
.login-container .right-container .login-form .other {
  width: 100%;
  height: 1rem;
}
.login-container .right-container .other-login-text {
  color: #6e6b7b;
  font-size: 0.6rem;
  font-weight: 400;
}
</style>
<style>
.login-container
  .right-container
  .login-form
  .form-input
  .is-invalid
  .el-input__inner {
  border-color: #ea5455 !important;
}
.login-container .right-container .login-form .form-input .err-msg {
  color: #ea5455 !important;
  font-size: 0.857rem;
  display: none;
  font-weight: 400;
  margin-top: 0.2857rem;
}
.login-container .right-container .login-form .form-input .is-invalid .err-msg {
  display: block;
}
</style>
<style lang="scss" scoped>
.content {
  width: 500px;
  height: 270px;
  background: red;
}
</style>
