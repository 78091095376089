<template>
  <el-dialog :title="title" :destroy-on-close="true" append-to-body :visible.sync="visible" width="70%">
    <slot v-if="visible" />
  </el-dialog>
</template>

<script>
export default {
  name: 'wt-dialog',
  props: {
    title: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
