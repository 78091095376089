var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-detail-container"},[_c('wt-detail-header',{attrs:{"title":"详情"}},[_c('wt-button',{on:{"click":function($event){return _vm.$emit('on-close')}}},[_vm._v("返回")])],1),_c('div',{staticClass:"wt-detail-container"},[_c('wt-detail-section',{attrs:{"title":"基本信息"}},[_c('el-form',{ref:"form",staticClass:"wt-detail-section-form-center",attrs:{"model":_vm.detailForm,"size":"small","label-width":"30%"}},[_c('el-form-item',{attrs:{"label":"类型 1 患者档案检查图片 2 病历资料：","prop":"type","rules":[
            {
              required: true,
              message: '类型 1 患者档案检查图片 2 病历资料不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择","readonly":"","disabled":""},model:{value:(_vm.detailForm.type),callback:function ($$v) {_vm.$set(_vm.detailForm, "type", $$v)},expression:"detailForm.type"}})],1),_c('el-form-item',{attrs:{"label":"来源ID：","prop":"sourceId","rules":[
            {
              required: true,
              message: '来源ID不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择","readonly":"","disabled":""},model:{value:(_vm.detailForm.sourceId),callback:function ($$v) {_vm.$set(_vm.detailForm, "sourceId", $$v)},expression:"detailForm.sourceId"}})],1),_c('el-form-item',{attrs:{"label":"图片地址：","prop":"path","rules":[
            {
              required: true,
              message: '图片地址不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_vm._v(" "+_vm._s(_vm.detailForm.path)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }