<template>
  <div class="app-container">
    <wt-form-table :form.sync="form" @on-button-action="onButtonAction" >

      <wt-form-table-list slot="wtFormTableList" :form.sync="form" @on-button-action="onButtonAction">

        <!--自定义列-->
        <template slot="customCol" slot-scope="data">
          <span>{{data}}</span>
        </template>

        <!--自定义按钮-->
        <template slot="wtFormTableListButtonsAfter" slot-scope="scope">
          <wt-auth class="search-form-btn">
            <el-button size="mini" @click="onButtonAction({ row: scope.row, item: { action: 'APPROVAL' } })">
              自定义按钮
            </el-button>
          </wt-auth>
        </template>

      </wt-form-table-list>

    </wt-form-table>

    <!--自定义的详情，默认不需要写-->
    <wt-detail-page :visible.sync="isViewDialogVisible" component-name="test2-view" component-type="view" />
    <wt-detail-page :visible.sync="isCustomViewDialogVisible" component-name="test2-custom-view" component-type="view" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isViewDialogVisible: false,
      isCustomViewDialogVisible: false,
      form: { //  表单数据
        isFormLoading: false,
        formCode: 'MODULES_ACCOUNT',
        formComponent: {
          view: { type: 'component', componentName: 'test2-view', value: 'queryDutyDetailData' },
          add: { type: 'component', componentName: 'test2-edit', value: 'resetDutyDetailData' },
          edit: { type: 'component', componentName: 'test2-edit', value: 'queryDutyDetailData' },
          list: { type: 'default', value: '/modules/auth/accountList' },
          delete: { type: 'default', value: '/modules/auth/accountDelete' }
        },
        formInputsData: [
          { name: '名称', key: 'dutyName', value: '', type: 'text' },
          { name: '时间', key: 'updateTimeRange', value: [], type: 'datetime' }],
        formButtonsData: [{ name: '打开默认详情', key: 'CUSTOM', action: 'OPEN_DETAIL_DEFAULT', className: 'default' },
          { name: '打开有目录的详情', key: 'CUSTOM1', action: 'OPEN_DETAIL_CUSTOM', className: 'default' }],
        formTableData: [
          { name: '主键ID', key: 'id', type: 'text' },
          { name: '名称', key: 'dutyName', type: 'text' },
          { name: '部门', key: 'organizationId', type: 'text' },
          { name: '时间字段', key: 'updateTime', sortable: 'custom', type: 'datetime' },
          { name: '状态值字段', key: 'isPublish', type: 'enum', filter: 'FILTER_IsPublish' },
          { name: '自定义列', key: 'customCol', type: 'custom' }],
        formTableButtonsData: [],
        formResult: {},
        pageData: { currentPage: 0, pageSize: 20, sortName: '', sortOrder: '' }
      }
    }
  },
  methods: {
    /*  ------------------------- 按钮相关  -------------------------  */
    onButtonAction(data) {
      if (data.item.action === 'OPEN_DETAIL_DEFAULT') {
        console.log('打开默认详情')
        this.isViewDialogVisible = true
      } else if (data.item.action === 'OPEN_DETAIL_CUSTOM') {
        console.log('打开有目录的详情')
        this.isCustomViewDialogVisible = true
      } else {
        console.log('data', data)
      }
    }
  }
}
</script>
