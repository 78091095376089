var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-detail-container"},[_c('wt-detail-header',[(_vm.viewType == 'add')?_c('wt-button',{directives:[{name:"has-any-premission",rawName:"v-has-any-premission",value:(['ACCOUNTLIST:ADD']),expression:"['ACCOUNTLIST:ADD']"}],attrs:{"type":"primary","loading":_vm.isButtonLoading},on:{"click":_vm.onClickAdd}},[_vm._v("保存1")]):_vm._e(),(_vm.viewType == 'edit')?_c('wt-button',{directives:[{name:"has-any-premission",rawName:"v-has-any-premission",value:(['ACCOUNTLIST:EDIT']),expression:"['ACCOUNTLIST:EDIT']"}],attrs:{"type":"primary","loading":_vm.isButtonLoading},on:{"click":_vm.onClickSave}},[_vm._v("保存2")]):_vm._e(),_c('wt-button',{on:{"click":function($event){return _vm.$emit('on-close')}}},[_vm._v("返回")])],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isFromLoading),expression:"isFromLoading"}],staticClass:"wt-detail-container"},[_c('wt-detail-section',{attrs:{"title":"基本信息"}},[_c('el-form',{ref:"form",staticClass:"wt-detail-section-form-center",attrs:{"model":_vm.detailForm,"size":"small","label-width":"30%"}},[(_vm.aaaa)?_c('el-form-item',{attrs:{"label":"医生头像:"}},[_c('el-upload',{staticClass:"avatar-uploader",attrs:{"action":"https://meining.100api.com.cn/server/api/file/upload","show-file-list":false,"on-success":_vm.handleAvatarSuccess}},[(_vm.detailForm.head)?_c('img',{staticClass:"avatar",attrs:{"src":'https://meining.100api.com.cn/files/' +
                  _vm.detailForm.head}}):_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"})])],1):_vm._e(),_c('el-form-item',{attrs:{"label":"姓名:","prop":"realname","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-input',{attrs:{"placeholder":"请输入","disabled":_vm.viewType == 'view'},model:{value:(_vm.detailForm.realname),callback:function ($$v) {_vm.$set(_vm.detailForm, "realname", $$v)},expression:"detailForm.realname"}})],1),_c('el-form-item',{attrs:{"label":"性别","prop":"gender"}},[_c('el-radio-group',{model:{value:(_vm.detailForm.gender),callback:function ($$v) {_vm.$set(_vm.detailForm, "gender", $$v)},expression:"detailForm.gender"}},[_c('el-radio',{attrs:{"label":1}},[_vm._v("男")]),_c('el-radio',{attrs:{"label":2}},[_vm._v("女")])],1)],1),_c('el-form-item',{attrs:{"label":"手机号码","prop":"phone","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-input',{attrs:{"placeholder":"请输入","disabled":_vm.viewType == 'view'},model:{value:(_vm.detailForm.phone),callback:function ($$v) {_vm.$set(_vm.detailForm, "phone", $$v)},expression:"detailForm.phone"}})],1),_c('el-form-item',{attrs:{"label":"医院","prop":"hospital","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-input',{attrs:{"placeholder":"请输入","disabled":_vm.viewType == 'view'},model:{value:(_vm.detailForm.hospital),callback:function ($$v) {_vm.$set(_vm.detailForm, "hospital", $$v)},expression:"detailForm.hospital"}})],1),_c('el-form-item',{attrs:{"label":"科室","prop":"department","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-input',{attrs:{"placeholder":"请输入","disabled":_vm.viewType == 'view'},model:{value:(_vm.detailForm.department),callback:function ($$v) {_vm.$set(_vm.detailForm, "department", $$v)},expression:"detailForm.department"}})],1),_c('el-form-item',{attrs:{"label":"职称","prop":"systemData","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-input',{attrs:{"placeholder":"请输入","disabled":_vm.viewType == 'view'},model:{value:(_vm.detailForm.systemData),callback:function ($$v) {_vm.$set(_vm.detailForm, "systemData", $$v)},expression:"detailForm.systemData"}})],1),_c('el-form-item',{attrs:{"label":"从业时间","prop":"jobTime"}},[_c('el-input',{attrs:{"placeholder":"请输入","disabled":_vm.viewType == 'view'},model:{value:(_vm.detailForm.jobTime),callback:function ($$v) {_vm.$set(_vm.detailForm, "jobTime", $$v)},expression:"detailForm.jobTime"}})],1),_c('el-form-item',{attrs:{"label":"擅长疾病","prop":"goodDisease"}},[_c('el-input',{attrs:{"type":"textarea","placeholder":"请输入","disabled":_vm.viewType == 'view'},model:{value:(_vm.detailForm.goodDisease),callback:function ($$v) {_vm.$set(_vm.detailForm, "goodDisease", $$v)},expression:"detailForm.goodDisease"}})],1),_c('el-form-item',{attrs:{"label":"医生助手1手机号码：","prop":"affiliatedPhoneOne"}},[_c('el-input',{attrs:{"placeholder":"请输入","disabled":_vm.viewType == 'view'},model:{value:(_vm.detailForm.affiliatedPhoneOne),callback:function ($$v) {_vm.$set(_vm.detailForm, "affiliatedPhoneOne", $$v)},expression:"detailForm.affiliatedPhoneOne"}})],1),_c('el-form-item',{attrs:{"label":"医生助手2手机号码：","prop":"affiliatedPhoneTwo"}},[_c('el-input',{attrs:{"placeholder":"请输入","disabled":_vm.viewType == 'view'},model:{value:(_vm.detailForm.affiliatedPhoneTwo),callback:function ($$v) {_vm.$set(_vm.detailForm, "affiliatedPhoneTwo", $$v)},expression:"detailForm.affiliatedPhoneTwo"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitForm(_vm.detailForm)}}},[_vm._v("保存")]),_c('el-button',{on:{"click":function($event){return _vm.$emit('on-close')}}},[_vm._v("取消")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }