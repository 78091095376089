<template>
  <div class="page-detail-container">
    <!--顶部标题和页面全局按钮-->
    <wt-detail-header title="详情">
      <wt-button @click="$emit('on-close')">返回</wt-button>
    </wt-detail-header>
    <!--内容区域-->
    <div class="wt-detail-container">
      <wt-detail-section title="基本信息">
        <el-form
          :model="detailForm"
          ref="form"
          size="small"
          class="wt-detail-section-form-center"
          label-width="30%"
        >
          <el-form-item
            label="患者ID："
            prop="patientId"
            :rules="[
              {
                required: true,
                message: '患者ID不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'change'
              }
            ]"
          >
            <el-select
              v-model="detailForm.patientId"
              placeholder="请选择"
              readonly
              disabled
            ></el-select>
          </el-form-item>
          <el-form-item
            label="医生ID："
            prop="doctorId"
            :rules="[
              {
                required: true,
                message: '医生ID不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'change'
              }
            ]"
          >
            <el-select
              v-model="detailForm.doctorId"
              placeholder="请选择"
              readonly
              disabled
            ></el-select>
          </el-form-item>
          <el-form-item
            label="医院："
            prop="hospital"
            :rules="[
              {
                required: true,
                message: '医院不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            {{ detailForm.hospital }}
          </el-form-item>
          <el-form-item
            label="主诉："
            prop="actionChief"
            :rules="[
              {
                required: true,
                message: '主诉不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            {{ detailForm.actionChief }}
          </el-form-item>
          <el-form-item
            label="病史："
            prop="medicalHistory"
            :rules="[
              {
                required: true,
                message: '病史不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            {{ detailForm.medicalHistory }}
          </el-form-item>
          <el-form-item
            label="诊断："
            prop="diagnose"
            :rules="[
              {
                required: true,
                message: '诊断不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            {{ detailForm.diagnose }}
          </el-form-item>
          <el-form-item
            label="处理："
            prop="dispose"
            :rules="[
              {
                required: true,
                message: '处理不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            {{ detailForm.dispose }}
          </el-form-item>
          <el-form-item
            label="是否发布："
            prop="isPublish"
            :rules="[
              {
                required: true,
                message: '请选择发布状态',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'change'
              }
            ]"
          >
            <wt-form-simple-select-item
              v-model="detailForm.isPublish"
              readonly
              disabled
            />
          </el-form-item>
        </el-form>
      </wt-detail-section>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PatientRecordView',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isFromLoading: false,
      detailForm: {}
    }
  },
  mounted() {
    this.requestDetail()
  },
  created() {},
  methods: {
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that
        .$wtRequest({
          url: '/modules/patientRecord/detail',
          method: 'post',
          data: { id: that.detailData.id }
        })
        .then(resp => {
          that.detailForm = resp.data || {}
          that.isFromLoading = false
        })
        .catch(() => {
          that.isFromLoading = false
        })
    }
  },
  computed: {
    ...mapState({})
  }
}
</script>
