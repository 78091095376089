var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-detail-container"},[_c('wt-detail-header',{attrs:{"title":"详情"}},[_c('wt-button',{on:{"click":function($event){return _vm.$emit('on-close')}}},[_vm._v("返回")])],1),_c('div',{staticClass:"wt-detail-container"},[_c('wt-detail-section',{attrs:{"title":"基本信息"}},[_c('el-form',{ref:"form",staticClass:"wt-detail-section-form-center",attrs:{"model":_vm.detailForm,"size":"small","label-width":"30%"}},[_c('el-form-item',{attrs:{"label":"发送人ID：","prop":"senderId","rules":[
            {
              required: true,
              message: '发送人ID不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择","readonly":"","disabled":""},model:{value:(_vm.detailForm.senderId),callback:function ($$v) {_vm.$set(_vm.detailForm, "senderId", $$v)},expression:"detailForm.senderId"}})],1),_c('el-form-item',{attrs:{"label":"接收人ID：","prop":"recipientId","rules":[
            {
              required: true,
              message: '接收人ID不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择","readonly":"","disabled":""},model:{value:(_vm.detailForm.recipientId),callback:function ($$v) {_vm.$set(_vm.detailForm, "recipientId", $$v)},expression:"detailForm.recipientId"}})],1),_c('el-form-item',{attrs:{"label":"内容：","prop":"content","rules":[
            {
              required: true,
              message: '内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_vm._v(" "+_vm._s(_vm.detailForm.content)+" ")]),_c('el-form-item',{attrs:{"label":"是否已读：","prop":"isRead","rules":[
            {
              required: true,
              message: '是否已读不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择","readonly":"","disabled":""},model:{value:(_vm.detailForm.isRead),callback:function ($$v) {_vm.$set(_vm.detailForm, "isRead", $$v)},expression:"detailForm.isRead"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }