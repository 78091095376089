<template>
  <div class="page-container">
    <wt-data-form ref="dataForm" :form.sync="form" request-list-url="/modules/article/list" request-delete-url="/modules/article/delete"
                  request-detail-url="/modules/article/detail">
      <template slot="search">
          <wt-search-form-item title="主键" v-model="form.formData.id" type="select" />
          <wt-search-form-item title="标题" v-model="form.formData.title" type="input" />
          <wt-search-form-item title="栏目ID" v-model="form.formData.programaGroupId" type="select" />
          <wt-search-form-item title="封面" v-model="form.formData.cover" type="input" />
          <wt-search-form-item title="内容" v-model="form.formData.content" type="input" />
          <wt-search-form-item title="是否发布 0否1是" v-model="form.formData.isPublish" type="select" />
          <wt-search-form-item title="是否删除" v-model="form.formData.isDelete" type="select" />
          <wt-search-form-item title="创建时间" v-model="form.formData.createTime" type="daterange" />
          <wt-search-form-item title="更新时间" v-model="form.formData.updateTime" type="daterange" />
          <wt-search-form-item title="类型  1 心身科普 2 阳光康复" v-model="form.formData.type" type="select" />
      </template>
      <template slot="toolbar">
        <wt-button type="primary" v-has-any-premission="['ARTICLE:ADD']" @click="$refs.dataForm.toAdd()">新增</wt-button>
        <wt-button type="danger" v-has-any-premission="['ARTICLE:DELETE']" @click="$refs.dataForm.onClickMultipleDelete()">删除</wt-button>
      </template>
      <template slot="table">
        <el-table-column type="selection" label="选择"></el-table-column>
        <el-table-column prop="id" label="主键" sortable></el-table-column>
        <el-table-column prop="title" label="标题"></el-table-column>
        <el-table-column prop="programaGroupId" label="栏目ID" sortable></el-table-column>
        <el-table-column prop="cover" label="封面">
          <template slot-scope="scope">
            <wt-table-column-image :url="scope.row.cover" />
          </template></el-table-column>
        <el-table-column prop="content" label="内容"></el-table-column>
        <el-table-column prop="isPublish" label="发布状态"><template slot-scope="scope"><span :class="scope.row.isPublish|FILTER_IsPublishClass">{{scope.row.isPublish|FILTER_IsPublish}}</span></template></el-table-column>
        <el-table-column prop="createTime" label="创建时间" sortable></el-table-column>
        <el-table-column prop="updateTime" label="更新时间" sortable></el-table-column>
        <el-table-column prop="type" label="类型  1 心身科普 2 阳光康复" sortable></el-table-column>
        <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <wt-text-button v-has-any-premission="['ARTICLE:VIEW']" @click="$refs.dataForm.toView(scope.row)">查看详情</wt-text-button>
              <wt-text-button v-has-any-premission="['ARTICLE:EDIT']" @click="$refs.dataForm.toEdit(scope.row)">编辑</wt-text-button>
            </template>
        </el-table-column>
      </template>
    </wt-data-form>
    <!--弹层 开始-->
    <wt-detail-page :visible.sync="form.isViewDialogVisible"><ArticleView :detailData="form.detailData" view-type="view" @on-close="form.isViewDialogVisible = false"/></wt-detail-page>
    <wt-detail-page :visible.sync="form.isEditDialogVisible"><ArticleEdit :detailData="form.detailData" :view-type="form.dialogViewType" @on-refresh="$refs.dataForm.requestDataList()" @on-close="form.isEditDialogVisible = false"/></wt-detail-page>
    <!--弹层 结束-->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'articleList',
  data() {
    return {
      form: {
        isFromLoading: false,
        isViewDialogVisible: false,
        isEditDialogVisible: false,
        dialogViewType: 'edit',
        detailData: {},
        extFormData: {},
        formData: {
          name: '',
          updateTimeRange: []
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        table: {},
        formResult: {}
      }
    }
  },
  mounted() {
    this.$refs.dataForm.onClickSearch()
  },
  created() {
  },
  methods: {
  },
  computed: {
    ...mapState({
    })
  }
}
</script>
