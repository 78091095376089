var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-detail-container"},[_c('wt-detail-header',{attrs:{"title":"详情"}},[_c('wt-button',{on:{"click":function($event){return _vm.$emit('on-close')}}},[_vm._v("返回")])],1),_c('div',{staticClass:"wt-detail-container"},[_c('wt-detail-section',{attrs:{"title":"基本信息"}},[_c('el-form',{ref:"form",staticClass:"wt-detail-section-form-center",attrs:{"model":_vm.detailForm,"size":"small","label-width":"30%"}},[_c('el-form-item',{attrs:{"label":"患者分组ID：","prop":"patientGroupId","rules":[
            {
              required: true,
              message: '患者分组ID不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择","readonly":"","disabled":""},model:{value:(_vm.detailForm.patientGroupId),callback:function ($$v) {_vm.$set(_vm.detailForm, "patientGroupId", $$v)},expression:"detailForm.patientGroupId"}})],1),_c('el-form-item',{attrs:{"label":"患者ID：","prop":"patientId","rules":[
            {
              required: true,
              message: '患者ID不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_vm._v(" "+_vm._s(_vm.detailForm.patientId)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }