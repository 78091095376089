<template>
  <div class="page-container">
    <wt-data-form
      ref="dataForm"
      :form.sync="form"
      request-list-url="/modules/patient/list"
      request-delete-url="/modules/patient/delete"
      request-detail-url="/modules/patient/detail"
    >
      >
      <template slot="search">
        <wt-search-form-item
          title="患者姓名"
          v-model="form.formData.realname"
          type="input"
        />
        <wt-search-form-item
          title="患者手机号码"
          v-model="form.formData.phone"
          type="input"
          placeholder="请输入完整的手机号码"
        />
      </template>
      <template slot="toolbar">
        <wt-button type="primary" @click="$refs.dataForm.exportData('患者信息')"
          >导出患者信息</wt-button
        >
        <wt-button type="primary" @click="qunfa()">群发消息</wt-button>
      </template>
      <template slot="table">
        <el-table-column type="selection" label="选择"></el-table-column>
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column
          prop="realname"
          label="患者姓名"
          width="70"
        ></el-table-column>
        <el-table-column
          prop="phone"
          label="患者手机号码"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="relationName"
          label="家属姓名"
          width="70"
        ></el-table-column>
        <el-table-column
          prop="relationPhone"
          label="家属联系电话"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="point"
          label="患者积分"
          width="100"
        ></el-table-column>
        <el-table-column prop="createTime" label="注册时间"></el-table-column>
        <!-- <el-table-column prop="isPublish" label="发布状态">
          <template slot-scope="scope"
            ><span :class="scope.row.isPublish | FILTER_IsPublishClass">{{
              scope.row.isPublish | FILTER_IsPublish
            }}</span>
          </template>
        </el-table-column> -->
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <span @click="bianji(scope.row.id)" class="chouzuospan">编辑</span>
            <span @click="dangan(scope.row.id)" class="chouzuospan"
              >患者档案</span
            >
            <span @click="jilu(scope.row.id)" class="chouzuospan"
              >用药记录</span
            >
            <span @click="zixun(scope.row.id)" class="chouzuospan"
              >咨询记录</span
            >
            <span @click="duijiang(scope.row.id)" class="chouzuospan"
              >兑奖情况</span
            >
            <span
              style="color:#FF5B5B"
              @click="$refs.dataForm.onClickDelete(scope.row.id)"
              class="chouzuospan"
              >删除</span
            >
          </template>
        </el-table-column>
      </template>
    </wt-data-form>
    <!--弹层 开始-->
    <wt-detail-page :visible.sync="form.isViewDialogVisible">
      <accountEdit
        :detailData="form.detailData"
        view-type="view"
        @on-close="form.isViewDialogVisible = false"
      />
    </wt-detail-page>
    <wt-detail-page :visible.sync="form.isEditDialogVisible">
      <accountEdit
        :detailData="form.detailData"
        :view-type="form.dialogViewType"
        @on-refresh="$refs.dataForm.requestDataList()"
        @on-close="form.isEditDialogVisible = false"
      />
    </wt-detail-page>
    <wt-detail-page :visible.sync="form.isEditDialogVisible2">
      <patienArchives
        :detailData="form.detailData"
        :view-type="form.dialogViewType"
        @on-refresh="$refs.dataForm.requestDataList()"
        @on-close="form.isEditDialogVisible2 = false"
      />
    </wt-detail-page>
    <wt-detail-page :visible.sync="form.isEditDialogVisible3">
      <patienArchives2
        :detailData="form.detailData"
        :view-type="form.dialogViewType"
        @on-refresh="$refs.dataForm.requestDataList()"
        @on-close="form.isEditDialogVisible3 = false"
      />
    </wt-detail-page>
    <wt-detail-page :visible.sync="form.isEditDialogVisible4">
      <patienArchives3
        :detailData="form.detailData"
        :view-type="form.dialogViewType"
        @on-refresh="$refs.dataForm.requestDataList()"
        @on-close="form.isEditDialogVisible4 = false"
      />
    </wt-detail-page>
    <wt-detail-page :visible.sync="form.isEditDialogVisible5">
      <patienArchives4
        :detailData="form.detailData"
        :view-type="form.dialogViewType"
        @on-refresh="$refs.dataForm.requestDataList()"
        @on-close="form.isEditDialogVisible5 = false"
      />
    </wt-detail-page>
    <!--弹层 结束-->
    <el-dialog
      title="群发消息"
      :visible.sync="dialogFormVisible"
      :modal-append-to-body="false"
    >
      <el-form>
        <el-form-item>
          <el-input
            type="textarea"
            :maxlength="300"
            v-model="shurukuang"
            placeholder="请输入您要发送的消息内容"
          ></el-input>
        </el-form-item>
        <div style="color:#bababa">字数限制在300字以内</div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="qunfaqueding">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { patienDelete, messageAdd } from '@/api/course.js'
export default {
  name: 'patientList',
  data() {
    return {
      areaList: [],
      dialogFormVisible: false,
      shurukuang: '',
      form: {
        isFromLoading: false,
        isViewDialogVisible: false,
        isEditDialogVisible: false,
        isEditDialogVisible2: false,
        isEditDialogVisible3: false,
        isEditDialogVisible4: false,
        isEditDialogVisible5: false,
        dialogViewType: 'edit',
        detailData: {},
        formData: {
          name: '',
          updateTimeRange: []
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        formResult: {}
      },
      xuanzhongId: []
    }
  },
  mounted() {
    this.$refs.dataForm.requestDataList()
  },
  created() {},
  methods: {
    qunfaqueding() {
      // this.dialogFormVisible = false
      if (this.shurukuang === '') {
        this.$message({
          message: '请输入内容',
          type: 'warning'
        })
      } else {
        messageAdd({
          content: this.shurukuang,
          recipientId: this.xuanzhongId
        })
          .then(res => {
            console.log(res)
            this.$message({
              message: '发送成功',
              type: 'success'
            })
            this.dialogFormVisible = false
          })
          .catch(res => {
            console.log(res)
          })
      }
      console.log(this.shurukuang)
    },
    qunfa() {
      this.xuanzhongId = []
      console.log(this.form.formSelectedList)
      if (this.form.formSelectedList === undefined) {
        this.$message({
          message: '请选择患者',
          type: 'warning'
        })
        return
      }
      this.form.formSelectedList.map(val => {
        this.xuanzhongId.push(val.id)
      })
      this.dialogFormVisible = true
      console.log(this.xuanzhongId)
    },
    bianji(data) {
      console.log(data)
      this.form.isEditDialogVisible = true
      this.form.detailData.id = data
    },
    dangan(data) {
      this.form.detailData.id = data
      this.form.isEditDialogVisible2 = true
      console.log(data)
    },
    jilu(data) {
      this.form.isEditDialogVisible3 = true
      console.log(data)
      this.form.detailData.id = data
    },
    zixun(data) {
      this.form.isEditDialogVisible4 = true
      console.log(data)
      this.form.detailData.id = data
    },
    duijiang(data) {
      this.form.isEditDialogVisible5 = true
      this.form.detailData.id = data

      console.log(data)
    },
    del(data) {
      var dellist = {
        list: [data]
      }
      console.log(dellist)
      patienDelete(dellist)
        .then(res => {
          console.log(res)
          if (res.code === '0') {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.$refs.dataForm.requestDataList()
          }
        })
        .catch(res => {
          console.log(res)
        })
      console.log(data)
    }
  },
  computed: {
    ...mapState({})
  }
}
</script>

<style lang="scss" scoped>
.chouzuospan {
  color: #0180cc;
  font-size: 14px;
  margin-right: 14px;
  cursor: pointer;
}
</style>
