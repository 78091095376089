<template>
  <div style="display: flex;">
    <el-input v-model="areaName" placeholder="请选择" disabled></el-input>
    <el-popover v-model="isPopoverVisible" trigger="manual" placement="bottom" style="margin-left: 8px;" v-show="!disabled">
      <el-cascader-panel ref="areaCascader" v-model="data" :props="props" @change="onAreaChange"></el-cascader-panel>
      <div style="text-align: right; margin-top: 8px;">
        <el-button size="mini" type="text" @click="isPopoverVisible = false">取消</el-button>
        <el-button type="primary" size="mini" @click="onClickOk">确定</el-button>
      </div>
      <wt-button slot="reference" class="node-picker-btn" type="primary" size="small" @click="isPopoverVisible = true">选择地区</wt-button>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: 'areaPicker',
  isAutoComponent: true,
  props: {
    name: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isPopoverVisible: false,
      isFromLoading: false,
      areaName: '',
      data: [],
      props: {
        lazy: true,
        lazyLoad: this.lazyLoad
      }
    }
  },
  watch: {
    name(val, oldVal) {
      if (val !== this.areaName) {
        this.areaName = val
      }
    }
  },
  methods: {
    onAreaChange(val) {
    },
    onClickOk() {
      if (!this.$refs.areaCascader.getCheckedNodes() || this.$refs.areaCascader.getCheckedNodes().length <= 0) {
        this.$message({ type: 'warning', message: '请选择区县' })
        return
      }
      const node = this.$refs.areaCascader.getCheckedNodes()[0]
      const districteName = node.data.label
      const cityName = node.parent.data.label
      const provinceName = node.parent.parent.data.label
      this.areaName = provinceName + ',' + cityName + ',' + districteName
      this.$emit('on-area-picker-change', {
        value: this.data,
        name: this.areaName
      })
      this.$emit('input', this.data)
      this.isPopoverVisible = false
    },
    lazyLoad(node, resolve) {
      const that = this
      if (node.level === 0) {
        that.requestProvinceList().then((data) => {
          resolve(data.map(item => {
            return {
              value: item.code,
              label: item.name,
              leaf: false
            }
          }))
        })
      } else if (node.level === 1) {
        that.requestCityList(node.value).then((data) => {
          resolve(data.map(item => {
            return {
              value: item.code,
              label: item.name,
              leaf: false
            }
          }))
        })
      } else if (node.level === 2) {
        that.requestDistrictList(node.value).then((data) => {
          resolve(data.map(item => {
            return {
              value: item.code,
              label: item.name,
              leaf: true
            }
          }))
        })
      }
    },
    requestProvinceList() {
      return new Promise((resolve, reject) => {
        const that = this
        that.isFromLoading = true
        that.$wtRequest({
          url: '/modules/area/provinceList',
          method: 'post',
          data: {}
        }).then((resp) => {
          that.isFromLoading = false
          resolve(resp.data)
        }).catch(() => {
          that.isFromLoading = false
          reject(new Error(''))
        })
      })
    },
    requestCityList(key) {
      return new Promise((resolve, reject) => {
        const that = this
        that.isFromLoading = true
        that.$wtRequest({
          url: '/modules/area/cityList',
          method: 'post',
          data: { key: key }
        }).then((resp) => {
          that.isFromLoading = false
          resolve(resp.data)
        }).catch(() => {
          that.isFromLoading = false
          reject(new Error(''))
        })
      })
    },
    requestDistrictList(key) {
      return new Promise((resolve, reject) => {
        const that = this
        that.isFromLoading = true
        that.$wtRequest({
          url: '/modules/area/districtList',
          method: 'post',
          data: { key: key }
        }).then((resp) => {
          that.isFromLoading = false
          resolve(resp.data)
        }).catch(() => {
          that.isFromLoading = false
          reject(new Error(''))
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
