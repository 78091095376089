<template>
  <div class="wt-search-form-item" :class="[className, itemWidth]">
    <div class="title" :style="titleStyle" v-if="title">{{title}}：</div>
    <div class="content">
      <!--  select  -->
      <template v-if="type && type === 'input'">
        <el-input v-model="dataValue" :placeholder="placeholder ? placeholder : '请输入' + title" size="small" v-bind="$attrs" :value="value"></el-input>
      </template>
      <!--  daterange  -->
      <template v-if="type && type === 'daterange'">
        <el-date-picker type="daterange" v-model="dataValue" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                        :placeholder="placeholder ? placeholder : '请选择' + title" size="small" v-bind="$attrs"></el-date-picker>
      </template>
      <!--  date  -->
      <template v-if="type && type === 'date'">
        <el-date-picker type="date" v-model="dataValue" :placeholder="placeholder ? placeholder : '请选择' + title" value-format="yyyy-MM-dd" size="small" v-bind="$attrs"></el-date-picker>
      </template>
      <!--  datetimerange  -->
      <template v-if="type && type === 'datetimerange'">
        <el-date-picker type="datetimerange" v-model="dataValue" start-placeholder="开始时间" end-placeholder="开始时间" value-format="yyyy-MM-dd HH:mm:ss"
                        :placeholder="placeholder ? placeholder : '请选择' + title" size="small" v-bind="$attrs"></el-date-picker>
      </template>
      <!--  datetime  -->
      <template v-if="type && type === 'datetime'">
        <el-date-picker type="datetime" v-model="dataValue" :placeholder="placeholder ? placeholder : '请选择' + title" value-format="yyyy-MM-dd HH:mm:ss" size="small" v-bind="$attrs"></el-date-picker>
      </template>
      <!--  select  -->
      <template v-else-if="type && type === 'select'">
        <el-select v-model="dataValue" :placeholder="placeholder ? placeholder : '请选择' + title" size="small" v-bind="$attrs">
          <el-option v-for="item in dataList" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </template>

      <!--  cb-select  -->
      <template v-else-if="type && type === 'cb-select'">
        <el-select @change="selectChangeCb" v-model="dataValue" :placeholder="placeholder ? placeholder : '请选择' + title" size="small" v-bind="$attrs">
          <el-option v-for="item in dataList" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </template>

      <!--  multi-select  -->
      <template v-else-if="type && type === 'multi-select'">
        <el-select v-model="dataValue" :placeholder="placeholder ? placeholder : '请选择' + title" size="small" v-bind="$attrs">
          <el-option v-for="item in dataList" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </template>
      <!--  custom  -->
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WtSearchFormItem',
  isAutoComponent: true,
  props: ['title', 'type', 'dataList', 'value', 'className', 'placeholder', 'titleStyle', 'selectChangeCb'],
  data() {
    return {
      dataValue: ''
    }
  },
  created() {
    if ((this.type === 'select' || this.type === 'multi-select') && this.value) {
      this.dataValue = this.value
    }
  },
  watch: {
    value(val, oldValue) {
      if (val !== this.dataValue) {
        this.dataValue = val
      }
    },
    dataValue(val, oldValue) {
      if (val !== this.value) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
  },
  computed: {
    itemWidth() {
      if (this.$wtUtil.isBlank(this.$attrs.width)) {
        return 'col-4'
      }
      return this.$attrs.width
    }
  }
}
</script>

<style scoped>
  .wt-search-form-item .content{width: 200px;text-align: right;}
  .alignFormItem .title {
    width: 7em;
    text-align: right;
  }
</style>
