<template>
  <div class="page-detail-container">
    <!--顶部标题和页面全局按钮-->
    <wt-detail-header>
      <wt-button
        v-if="viewType == 'add'"
        v-has-any-premission="['ACCOUNTLIST:ADD']"
        type="primary"
        :loading="isButtonLoading"
        @click="onClickAdd"
        >保存1</wt-button
      >
      <wt-button
        v-if="viewType == 'edit'"
        v-has-any-premission="['ACCOUNTLIST:EDIT']"
        type="primary"
        :loading="isButtonLoading"
        @click="onClickSave"
        >保存2</wt-button
      >
      <wt-button @click="$emit('on-close')">返回</wt-button>
    </wt-detail-header>
    <!--内容区域-->
    <div class="wt-detail-container" v-loading="isFromLoading">
      <wt-detail-section title="基本信息">
        <el-form
          :model="detailForm"
          ref="form"
          size="small"
          class="wt-detail-section-form-center"
          label-width="30%"
        >
          <el-form-item v-if="aaaa" label="医生头像:">
            <el-upload
              class="avatar-uploader"
              action="https://meining.100api.com.cn/server/api/file/upload"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
            >
              <img
                v-if="detailForm.head"
                :src="
                  'https://meining.100api.com.cn/files/' +
                    detailForm.head
                "
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item
            label="姓名:"
            prop="realname"
            :rules="[
              {
                required: true,
                message: '输入内容不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            <el-input
              v-model="detailForm.realname"
              placeholder="请输入"
              :disabled="viewType == 'view'"
            ></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="gender">
            <el-radio-group v-model="detailForm.gender">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="手机号码"
            prop="phone"
            :rules="[
              {
                required: true,
                message: '输入内容不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            <el-input
              v-model="detailForm.phone"
              placeholder="请输入"
              :disabled="viewType == 'view'"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="医院"
            prop="hospital"
            :rules="[
              {
                required: true,
                message: '输入内容不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            <el-input
              v-model="detailForm.hospital"
              placeholder="请输入"
              :disabled="viewType == 'view'"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="科室"
            prop="department"
            :rules="[
              {
                required: true,
                message: '输入内容不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            <el-input
              v-model="detailForm.department"
              placeholder="请输入"
              :disabled="viewType == 'view'"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="职称"
            prop="systemData"
            :rules="[
              {
                required: true,
                message: '输入内容不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            <el-input
              v-model="detailForm.systemData"
              placeholder="请输入"
              :disabled="viewType == 'view'"
            ></el-input>
          </el-form-item>
          <el-form-item label="从业时间" prop="jobTime">
            <el-input
              v-model="detailForm.jobTime"
              placeholder="请输入"
              :disabled="viewType == 'view'"
            ></el-input>
          </el-form-item>
          <el-form-item label="擅长疾病" prop="goodDisease">
            <el-input
            type="textarea"
              v-model="detailForm.goodDisease"
              placeholder="请输入"
              :disabled="viewType == 'view'"
            ></el-input>
          </el-form-item>
          <el-form-item label="医生助手1手机号码：" prop="affiliatedPhoneOne">
            <el-input
              v-model="detailForm.affiliatedPhoneOne"
              placeholder="请输入"
              :disabled="viewType == 'view'"
            ></el-input>
          </el-form-item>
          <el-form-item label="医生助手2手机号码：" prop="affiliatedPhoneTwo">
            <el-input
              v-model="detailForm.affiliatedPhoneTwo"
              placeholder="请输入"
              :disabled="viewType == 'view'"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm(detailForm)"
              >保存</el-button
            >
            <el-button @click="$emit('on-close')">取消</el-button>
          </el-form-item>
        </el-form>
      </wt-detail-section>
      <!-- <wt-detail-section title="密码信息" v-if="viewType != 'view'">
        <el-form :model="detailForm" ref="form" size="small" class="wt-detail-section-form-center" label-width="30%">
          <el-form-item label="新密码" prop="newPassword">
            <el-input v-model="detailForm.newPassword" type="password" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="confirmPassword">
            <el-input v-model="detailForm.confirmPassword" type="password" placeholder="请输入"></el-input>
          </el-form-item>
        </el-form>
      </wt-detail-section> -->
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapState } from 'vuex'
import staticRoutes from '@/router/staticRoutes.js'
import { doctorAdd } from '@/api/course.js'
export default {
  name: 'accountEdit3',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      imageUrl: '',
      roleList: [],
      staticRoutes: staticRoutes,
      defaultCheckedKeys: [],
      isButtonLoading: false,
      isFromLoading: false,
      detailForm: {
        isPublish: 1,
        roleList: []
      },
      aaaa: true
    }
  },
  mounted() {
    this.requestManagerRoleList()
    if (this.viewType !== 'add') {
      this.requestDetail()
    }
  },
  created() {},
  methods: {
    // 上传图片
    handleAvatarSuccess(res, file) {
      this.aaaa = false
      console.log(this.aaaa)
      this.detailForm.head = res.data.url
      this.aaaa = true
      console.log(this.aaaa)
    },
    // beforeAvatarUpload(file) {
    //   const isJPG = file.type === 'image/jpeg'
    //   const isLt2M = file.size / 1024 / 1024 < 2

    //   if (!isLt2M) {
    //     this.$message.error('上传头像图片大小不能超过 2MB!')
    //   }
    //   return isJPG && isLt2M
    // },
    // 上传图片
    submitForm(data) {
      console.log(data.systemData)
      if (data.realname === undefined) {
        console.log(data.realname)
        this.$message('请输入姓名')
        return
      }
      if (data.hospital === undefined) {
        console.log(data.hospital)
        this.$message('请输入医院')
        return
      }
      // if (data.gender === undefined) {
      //   console.log(data.gender)
      //   this.$message('请选中性别')
      //   return
      // }
      if (data.phone === undefined) {
        console.log(data.phone)
        this.$message('请输入手机号')
        return
      }
      if (data.department === undefined) {
        console.log(data.department)
        this.$message('请输入科室')
        return
      }
      if (data.systemData === undefined) {
        console.log(data.systemData)
        this.$message('请输入职称')
        return
      }
      // return
      doctorAdd(data)
        .then(res => {
          console.log(res)
          if (res.msg === '请求成功') {
            this.$message({
              message: '保存成功',
              type: 'success'
            })
            // this.$emit('on-close')
            this.$router.go(0)
          }
        })
        .catch(res => {
          console.log(res)
        })
      console.log('aaa')
    },
    onClickAdd() {
      const that = this
      this.$wtUtil
        .validateDetailFormsAndConfirm(this, '是否保存?')
        .then(() => {
          that.requestAdd()
        })
        .catch(() => {})
    },
    onClickSave() {
      const that = this
      this.$wtUtil
        .validateDetailFormsAndConfirm(this, '是否保存?')
        .then(() => {
          that.requestUpdate()
        })
        .catch(() => {})
    },
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that
        .$wtRequest({
          url: '/modules/doctor/detail',
          method: 'post',
          data: { id: that.detailData.id }
        })
        .then(resp => {
          that.detailForm = resp.data || {}
          that.isFromLoading = false
        })
        .catch(() => {
          that.isFromLoading = false
        })
    },
    requestManagerRoleList() {
      const that = this
      that.isFromLoading = true
      that
        .$wtRequest({
          url: '/modules/auth/accountRoleList',
          method: 'post',
          data: {}
        })
        .then(resp => {
          const tempRoleList = [].concat(resp.data)
          that.roleList = tempRoleList
          console.log(that.roleList)
          that.isFromLoading = false
        })
        .catch(() => {
          that.isFromLoading = false
        })
    },
    requestUpdate() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that
        .$wtRequest({
          url: '/modules/auth/accountUpdate',
          method: 'post',
          data: that.detailForm
        })
        .then(resp => {
          that.isButtonLoading = false
          that.isFromLoading = false
          that.$message({ type: 'success', message: '保存成功' })
          that.$emit('on-refresh')
          that.$emit('on-close')
        })
        .catch(() => {
          that.isButtonLoading = false
          that.isFromLoading = false
        })
    },
    requestAdd() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that
        .$wtRequest({
          url: '/modules/auth/accountAdd',
          method: 'post',
          data: that.detailForm
        })
        .then(resp => {
          that.isButtonLoading = false
          that.isFromLoading = false
          that.$message({ type: 'success', message: '保存成功' })
          that.$emit('on-refresh')
          that.$emit('on-close')
        })
        .catch(() => {
          that.isButtonLoading = false
          that.isFromLoading = false
        })
    }
  },
  computed: {
    moduleTreeData() {
      const that = this
      const treeData = []
      const makeChildrenFunction = staticRoute => {
        const children = []
        if (!staticRoute.hidden) {
          if (staticRoute.children && staticRoute.children.length > 0) {
            const parentRouteId = staticRoute.name.toUpperCase()
            if (that.defaultCheckedKeys.indexOf(parentRouteId) !== -1) {
              that.defaultCheckedKeys.splice(
                that.defaultCheckedKeys.indexOf(parentRouteId),
                1
              )
            }
            staticRoute.children.forEach(child => {
              const routeId = child.name.toUpperCase()
              children.push({
                id: routeId,
                label: child.meta ? child.meta.title : '',
                children: makeChildrenFunction(child)
              })
            })
          }
        }
        return children
      }
      this.staticRoutes.forEach(staticRoute => {
        if (!staticRoute.hidden) {
          const routeId = staticRoute.name.toUpperCase()
          treeData.push({
            id: routeId,
            label: staticRoute.meta ? staticRoute.meta.title : '',
            children: makeChildrenFunction(staticRoute)
          })
        }
      })
      return treeData
    }
    // ...mapState({
    //   groupList: state => state.ops.groupList
    // })
  }
}
</script>

<style>
/* 上传图片css */
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
/* 上传图片css */
</style>
