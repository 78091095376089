<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  watch: {
    $route(val) {
      // this.$store.commit('SET_CURRENT_ROUTE', val)
    }
  },
  provide() {
    // 父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true // 控制视图是否显示的变量
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false // 先关闭，
      this.$nextTick(function() {
        this.isRouterAlive = true // 再打开
      })
    }
  }
}
</script>
<style>
#app {
  background: #f5f5f5;
}
body .el-table th.gutter {
  display: table-cell !important;
}
/* 调整填写数字时的表单样式 */
.fill-info-container .el-input input {
  width: 220px;
}
.el-dialog .page-detail-container {
  overflow-y: auto;
}
</style>
