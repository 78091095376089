var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-detail-container"},[_c('wt-detail-header',{attrs:{"title":"详情"}},[_c('wt-button',{on:{"click":function($event){return _vm.$emit('on-close')}}},[_vm._v("返回")])],1),_c('div',{staticClass:"wt-detail-container"},[_c('wt-detail-section',{attrs:{"title":"基本信息"}},[_c('el-form',{ref:"form",staticClass:"wt-detail-section-form-center",attrs:{"model":_vm.detailForm,"size":"small","label-width":"30%"}},[_c('el-form-item',{attrs:{"label":"首字母：","prop":"initial","rules":[
            {
              required: true,
              message: '首字母不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_vm._v(" "+_vm._s(_vm.detailForm.initial)+" ")]),_c('el-form-item',{attrs:{"label":"手机号：","prop":"phone","rules":[
            {
              required: true,
              message: '手机号不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_vm._v(" "+_vm._s(_vm.detailForm.phone)+" ")]),_c('el-form-item',{attrs:{"label":"密码：","prop":"password","rules":[
            {
              required: true,
              message: '密码不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_vm._v(" "+_vm._s(_vm.detailForm.password)+" ")]),_c('el-form-item',{attrs:{"label":"姓名：","prop":"realname","rules":[
            {
              required: true,
              message: '姓名不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_vm._v(" "+_vm._s(_vm.detailForm.realname)+" ")]),_c('el-form-item',{attrs:{"label":"性别：","prop":"gender","rules":[
            {
              required: true,
              message: '性别不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择","readonly":"","disabled":""},model:{value:(_vm.detailForm.gender),callback:function ($$v) {_vm.$set(_vm.detailForm, "gender", $$v)},expression:"detailForm.gender"}})],1),_c('el-form-item',{attrs:{"label":"积分：","prop":"point","rules":[
            {
              required: true,
              message: '积分不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择","readonly":"","disabled":""},model:{value:(_vm.detailForm.point),callback:function ($$v) {_vm.$set(_vm.detailForm, "point", $$v)},expression:"detailForm.point"}})],1),_c('el-form-item',{attrs:{"label":"医院：","prop":"age","rules":[
            {
              required: true,
              message: '医院不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择","readonly":"","disabled":""},model:{value:(_vm.detailForm.age),callback:function ($$v) {_vm.$set(_vm.detailForm, "age", $$v)},expression:"detailForm.age"}})],1),_c('el-form-item',{attrs:{"label":"头像：","prop":"head","rules":[
            {
              required: true,
              message: '头像不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_vm._v(" "+_vm._s(_vm.detailForm.head)+" ")]),_c('el-form-item',{attrs:{"label":"所在城市：","prop":"city","rules":[
            {
              required: true,
              message: '所在城市不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_vm._v(" "+_vm._s(_vm.detailForm.city)+" ")]),_c('el-form-item',{attrs:{"label":"家属姓名：","prop":"relationName","rules":[
            {
              required: true,
              message: '家属姓名不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_vm._v(" "+_vm._s(_vm.detailForm.relationName)+" ")]),_c('el-form-item',{attrs:{"label":"家属联系人电话：","prop":"relationPhone","rules":[
            {
              required: true,
              message: '家属联系人电话不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_vm._v(" "+_vm._s(_vm.detailForm.relationPhone)+" ")]),_c('el-form-item',{attrs:{"label":"是否发布：","prop":"isPublish","rules":[
            {
              required: true,
              message: '请选择发布状态',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('wt-form-simple-select-item',{attrs:{"readonly":"","disabled":""},model:{value:(_vm.detailForm.isPublish),callback:function ($$v) {_vm.$set(_vm.detailForm, "isPublish", $$v)},expression:"detailForm.isPublish"}})],1),_c('el-form-item',{attrs:{"label":"二维码地址：","prop":"code","rules":[
            {
              required: true,
              message: '二维码地址不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_vm._v(" "+_vm._s(_vm.detailForm.code)+" ")]),_c('el-form-item',{attrs:{"label":"详细地址：","prop":"address","rules":[
            {
              required: true,
              message: '详细地址不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_vm._v(" "+_vm._s(_vm.detailForm.address)+" ")]),_c('el-form-item',{attrs:{"label":"邮政编码：","prop":"postalCode","rules":[
            {
              required: true,
              message: '邮政编码不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_vm._v(" "+_vm._s(_vm.detailForm.postalCode)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }