<template>
  <v-chart :options="options" v-bind="$attrs" class="chart-view" />
</template>

<script>
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/title'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/legendScroll'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/toolbox'
export default {
  name: 'statBarChart',
  isAutoComponent: true,
  props: ['data', 'title'],
  components: {
    'v-chart': ECharts
  },
  data() {
    return {
    }
  },
  computed: {
    options() {
      const defaultOptions = {
        color: ['#1890ff'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            shadowStyle: {
              opacity: 0
            },
            type: 'shadow'
          }
        },
        grid: {
          left: 32,
          top: 32,
          right: 60,
          bottom: 32
        },
        xAxis: [{
          type: 'category',
          data: [],
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        }],
        yAxis: {
          type: 'value',
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        series: []
      }
      if (this.data) {
        const keyList = []
        const valueList = []
        this.data.forEach(item => {
          keyList.push(item.name)
          valueList.push(item.value)
        })
        defaultOptions.xAxis[0].data = keyList
        defaultOptions.series = [{
          barMaxWidth: 30,
          type: 'bar',
          data: valueList
        }]
      }
      return defaultOptions
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/styles/variables.scss";
  .chart-view{width: 100%;height: 100%;}
</style>
