<template>
  <div v-if="isItemShow">
    <el-menu-item v-if="!item.children || item.children.length <= 0" :index="itemIndex" @click="onClickItem(item)">
      <span>{{item.meta ? item.meta.title : ''}}</span>
    </el-menu-item>
    <el-submenu v-else :index="itemIndex">
      <template slot="title">
        <i :class="[item.meta && item.meta.icon ? item.meta.icon : 'el-icon-discover']"></i>
        <span slot="title">{{item.meta ? item.meta.title : ''}}</span>
      </template>
      <wt-side-menu-item v-for="(subItem, subIndex) in item.children" :item="subItem" :key="subIndex" :itemIndex="itemIndex + '_' + subIndex"></wt-side-menu-item>
    </el-submenu>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'WtSideMenuItem',
  props: ['item', 'itemIndex'],
  data() {
    return {
    }
  },
  methods: {
    onClickItem(item) {
      sessionStorage.setItem('MENU_ITEM_INDEX', this.itemIndex)
      this.$router.push({ name: item.name })
    }
  },
  computed: {
    isItemShow() {
      return !this.item.hidden && (true || this.moduleNames.indexOf((this.item.name ? this.item.name : '').toUpperCase()) !== -1)
    },
    ...mapState({
      moduleNames: state => state.auth.moduleNames
    })
  }
}
</script>
<style>
</style>
