<template>
  <div class="content">
    <div class="content2" v-if="diyiyemian">
      <div
        class="btn"
        v-if="lanmu"
        @click=";(diyiyemian = false), (addone = true)"
      >
        添加一级栏目
      </div>
      <div
        class="btn"
        v-else
        @click=";(addone4 = true), (diyiyemian = false), (form2 = {})"
      >
        添加二级栏目
      </div>

      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="一级栏目" name="first">
          <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="title" label="一级栏目" width="180">
            </el-table-column>
            <el-table-column
              prop="isAddChild"
              label="是否有二级栏目"
              width="180"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.isAddChild | statusText }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="sortNum" label="排序"> </el-table-column>
            <el-table-column fixed="right" label="操作" width="100">
              <template slot-scope="scope">
                <el-button
                  @click="handleClick2(scope.row)"
                  type="text"
                  size="small"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  style="color: red"
                  size="small"
                  @click="del(scope.row.id, scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="二级栏目" name="second">
          <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="title" label="二级栏目" width="180">
            </el-table-column>
            <el-table-column
              prop="parientTitle"
              label="关联一级栏目"
              width="180"
            >
            </el-table-column>
            <el-table-column prop="sortNum" label="排序"> </el-table-column>
            <el-table-column fixed="right" label="操作" width="100">
              <template slot-scope="scope">
                <el-button
                  @click="handleClick3(scope.row)"
                  type="text"
                  size="small"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  style="color: red"
                  size="small"
                  @click="del3(scope.row.id)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table></el-tab-pane
        >
      </el-tabs>
    </div>
    <!-- 弹窗 -->
    <!-- 一级栏目 -->
    <div
      style="
        color: rgb(102, 102, 102);
        font-size: 16px;
        font-weight: bold;
        border-bottom: 1px solid rgb(244, 244, 244);
        height: 50px;
        width: 100%;
        background: #fff;
        padding: 16px;
      "
      v-if="addone"
    >
      新增一级栏目
    </div>
    <div class="content2" v-if="addone">
      <el-form ref="form" :model="form" label-width="130px">
        <el-form-item label="一级栏目名 : ">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="栏目排序 : ">
          <el-input v-model="form.sortNum"></el-input>
        </el-form-item>
        <el-form-item label="是否添加二级栏目">
          <el-radio-group v-model="form.isAddChild">
            <el-radio label="1">是</el-radio>
            <el-radio label="2">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">立即创建</el-button>
          <el-button
            @click=";(addone = false), (diyiyemian = true), (form = {})"
            >取消</el-button
          >
        </el-form-item>
      </el-form>
      <div
        class="fanhui"
        @click=";(addone = false), (diyiyemian = true), (form = {})"
      >
        返回
      </div>
    </div>
    <div
      style="
        color: rgb(102, 102, 102);
        font-size: 16px;
        font-weight: bold;
        border-bottom: 1px solid rgb(244, 244, 244);
        height: 50px;
        width: 100%;
        background: #fff;
        padding: 16px;
      "
      v-if="addone2"
    >
      查看一级栏目
    </div>
    <div class="content2" v-if="addone2">
      <!-- <div>查看一级栏目</div> -->
      <el-form ref="form" :model="form" label-width="130px">
        <el-form-item label="一级栏目名 : ">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="栏目排序 : ">
          <el-input v-model="form.sortNum"></el-input>
        </el-form-item>
        <el-form-item label="是否添加二级栏目">
          <el-radio-group v-model="form.isAddChild">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="2">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit2">保存</el-button>
          <el-button
            @click=";(addone2 = false), (diyiyemian = true), (form = {})"
            >取消</el-button
          >
        </el-form-item>
      </el-form>
      <div
        class="fanhui"
        @click=";(addone2 = false), (diyiyemian = true), (form = {})"
      >
        返回
      </div>
    </div>
    <!-- 二级栏目 -->
    <!-- 新增二级 -->
    <div
      style="
        color: rgb(102, 102, 102);
        font-size: 16px;
        font-weight: bold;
        border-bottom: 1px solid rgb(244, 244, 244);
        height: 50px;
        width: 100%;
        background: #fff;
        padding: 16px;
      "
      v-if="addone4"
    >
      新增二级栏目
    </div>
    <div class="content2" v-if="addone4">
      <!-- <div>新增二级栏目</div> -->
      <el-form ref="form" :model="form2" label-width="120px">
        <el-form-item label="二级栏目名称">
          <el-input v-model="form2.title"></el-input>
        </el-form-item>
        <div class="guanlinyijilanmu" style="width: 58.5%">
          <el-form-item label="关联一级栏目" label-width="120px">
            <el-select v-model="form2.parientId" placeholder="请选择一级栏目">
              <el-option
                v-for="(item, index) in tableData2"
                :key="index"
                :label="item.title"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item label="栏目排序">
          <el-input v-model="form2.sortNum"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit4">立即创建</el-button>
          <el-button @click="quxiaoerji">取消</el-button>
        </el-form-item>
      </el-form>
      <div class="fanhui" @click="quxiaoerji">返回</div>
    </div>

    <!-- 修改二级 -->
    <div
      style="
        color: rgb(102, 102, 102);
        font-size: 16px;
        font-weight: bold;
        border-bottom: 1px solid rgb(244, 244, 244);
        height: 50px;
        width: 100%;
        background: #fff;
        padding: 16px;
      "
      v-if="addone3"
    >
      查看二级栏目
    </div>
    <div class="content2" v-if="addone3">
      <el-form ref="form" :model="form2" label-width="120px">
        <el-form-item label="二级栏目名称">
          <el-input v-model="form2.title"></el-input>
        </el-form-item>
        <div style="width: 58.5%">
          <el-form-item label="关联一级栏目">
            <el-select v-model="form2.parientId" placeholder="请选择一级栏目">
              <el-option
                v-for="(item, index) in tableData2"
                :key="index"
                :label="item.title"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item label="栏目排序">
          <el-input v-model="form2.sortNum"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit3">保存</el-button>
          <el-button @click="quxiaoerji">取消</el-button>
        </el-form-item>
      </el-form>
      <div class="fanhui" @click="quxiaoerji">返回</div>
    </div>
    <!-- 弹窗 -->
  </div>
</template>

<script>
import {
  articleGroupList,
  articleGroupAdd,
  articleGroupUpdate,
  articleGroupDelete
} from '@/api/course.js'
export default {
  data() {
    return {
      form: {
        title: '',
        sortNum: '',
        isAddChild: ''
      },
      form2: {
        title: '',
        parientId: '',
        sortNum: ''
      },
      diyiyemian: true,
      activeName: 'first',
      tableData: [],
      tableData2: [],
      lanmu: true,
      addone: false,
      addone2: false,
      addone3: false,
      addone4: false
    }
  },
  methods: {
    quxiaoerji() {
      this.addone3 = false
      this.diyiyemian = true
      this.addone4 = false
      this.huoqu(0)
    },
    onSubmit() {
      console.log(this.form.title)
      if (this.form.title.length > 5) {
        this.$message({
          message: '栏目名称最多五个字',
          type: 'warning'
        })
        return
      }
      // return
      if (this.form.title === '') {
        this.$message({
          message: '请输入一级栏目名称',
          type: 'warning'
        })
      }
      if (this.form.sortNum === '') {
        this.$message({
          message: '请输入一级栏目序号',
          type: 'warning'
        })
      }
      if (this.form.isAddChild === '') {
        this.$message({
          message: '是否添加二级栏目',
          type: 'warning'
        })
      }
      articleGroupAdd(this.form)
        .then(res => {
          console.log(res)
          if (res.code === '0') {
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.huoqu(1)
            this.addone = false
            this.diyiyemian = true
          }
        })
        .catch(res => {
          console.log(res)
        })
      console.log(this.form)
    },
    onSubmit2() {
      console.log(this.form.title.length)
      if (this.form.title.length > 5) {
        this.$message({
          message: '栏目名称最多五个字',
          type: 'warning'
        })
        return
      }
      // return
      if (this.form.title === '') {
        this.$message({
          message: '请输入一级栏目名称',
          type: 'warning'
        })
      }
      if (this.form.sortNum === '') {
        this.$message({
          message: '请输入一级栏目序号',
          type: 'warning'
        })
      }
      if (this.form.isAddChild === '') {
        this.$message({
          message: '是否添加二级栏目',
          type: 'warning'
        })
      }
      articleGroupUpdate(this.form)
        .then(res => {
          console.log(res)
          if (res.code === '0') {
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.huoqu(1)
            this.addone2 = false
            this.diyiyemian = true
          }
        })
        .catch(res => {
          console.log(res)
        })
      console.log(this.form)
    },
    onSubmit3() {
      console.log(this.form2.title)
      if (this.form2.title.length > 5) {
        this.$message({
          message: '栏目名称最多五个字',
          type: 'warning'
        })
        return
      }
      // return
      if (this.form2.title === '') {
        this.$message({
          message: '请输入二级栏目名称',
          type: 'warning'
        })
      }
      if (this.form2.sortNum === '') {
        this.$message({
          message: '请输入二级栏目序号',
          type: 'warning'
        })
      }
      if (this.form2.parientId === '') {
        this.$message({
          message: '选择需要关联的一级栏目',
          type: 'warning'
        })
      }
      articleGroupUpdate(this.form2)
        .then(res => {
          console.log(res)
          if (res.code === '0') {
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.huoqu(0)
            this.addone3 = false
            this.diyiyemian = true
          }
        })
        .catch(res => {
          console.log(res)
        })
      console.log(this.form)
    },
    onSubmit4() {
      console.log(this.form2.title)
      if (this.form2.title.length > 5) {
        this.$message({
          message: '栏目名称最多五个字',
          type: 'warning'
        })
        return
      }
      // return
      if (this.form2.title === '') {
        this.$message({
          message: '请输入二级栏目名称',
          type: 'warning'
        })
      }
      if (this.form2.sortNum === '') {
        this.$message({
          message: '请输入二级栏目序号',
          type: 'warning'
        })
      }
      if (this.form2.parientId === '') {
        this.$message({
          message: '选择需要关联的一级栏目',
          type: 'warning'
        })
      }
      articleGroupAdd(this.form2)
        .then(res => {
          console.log(res)
          if (res.code === '0') {
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.huoqu(0)
            this.addone3 = false
            this.diyiyemian = true
            this.addone4 = false
            this.addone3 = false
            this.diyiyemian = true
          }
        })
        .catch(res => {
          console.log(res)
        })
      console.log(this.form)
    },
    huoqu(data) {
      articleGroupList({
        formData: {
          isAddChild: data,
          title: ''
        },
        pageData: {
          currentPage: 1,
          pageSize: 20,
          sortName: ' ',
          sortOrder: ' '
        }
      })
        .then(res => {
          console.log(res)
          this.tableData = res.data.list
          if (data === 1) {
            console.log('*')
            this.tableData2 = res.data.list
          }
        })
        .catch(res => {
          console.log(res)
        })
    },
    handleClick(tab, event) {
      console.log(tab.$options.propsData.label)
      console.log(tab.$options.propsData.name)
      if (tab.$options.propsData.name === 'second') {
        this.huoqu(0)
        this.lanmu = false
      } else {
        this.huoqu(1)
        this.lanmu = true
      }
    },
    handleClick2(row) {
      console.log(row)
      this.form = row
      this.addone2 = true
      this.diyiyemian = false
    },
    del(row, data) {
      console.log(data.isOwnChild)
      if (data.isOwnChild === 0) {
        this.articleGroupDelete(row, 1)
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      } else {
        this.$confirm('是否删除该栏目?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.articleGroupDelete(row, 1)

            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })
          })
      }
    },
    articleGroupDelete(data, data2) {
      articleGroupDelete({
        list: [data]
      })
        .then(res => {
          console.log(res)
          if (res.code === '0') {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.huoqu(data2)
          }
        })
        .catch(res => {
          console.log(res)
        })
    },

    handleClick3(row) {
      this.form2 = row
      console.log(row)
      this.form = row
      this.addone3 = true
      this.diyiyemian = false
      this.huoqu(1)
    },
    del3(row) {
      console.log(row)
      this.articleGroupDelete(row, 0)
    }
  },
  created() {
    this.huoqu(1)
  },
  filters: {
    statusText(status) {
      if (status === 1) {
        return '是'
      } else {
        return '否'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-input__inner {
  width: 50% !important;
}
.el-input {
  width: 50%;
}
.el-select > .el-input {
  display: block;
  width: 50%;
}
.el-select {
  display: inline-block;
  position: relative;
  width: 50%;
}
.el-select:hover .el-input__inner {
  width: 50%;
}
.content {
  padding: 20px;
  .content2 {
    background: #fff;
    padding: 20px;
  }
}
.btn {
  background: #0180cc;
  width: 124px;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  padding: 10px 0;
  font-size: 14px;
  float: right;
  cursor: pointer;
  position: absolute;
  right: 39px;
  margin-top: -10px;
  z-index: 123;
}
.el-select {
  display: inline-block;
  position: relative;
  width: 100%;
}

.fanhui {
  position: absolute;
  top: 28px;
  right: 41px;
  border: 1px solid #dcdfe6;
  padding: 7px;
  border-radius: 6px;
  color: #606266;
  font-size: 14px;
  cursor: pointer;
}
</style>
