<template>
  <div class="page-container">
    <div class="wt-permission-container">
      <permission-item v-if="!item.hidden" :key="'permission_' + index" v-for="(item, index) in staticRoutes" :item="item" :level="0"></permission-item>
    </div>
  </div>
</template>

<script>
import staticRoutes from '@/router/staticRoutes.js'

export default {
  name: 'PermissionView',
  data() {
    return {
      staticRoutes: staticRoutes
    }
  },
  methods: {
  },
  computed: {
  }
}
</script>

<style scoped>
.wt-permission-container{width: calc(100% - 40px);margin: 20px;background-color: white;border-bottom: 1px solid #F4F4F4;border-radius: 4px;}
</style>
