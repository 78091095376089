<template>
  <div class="content">
    <div class="content2">
      <img class="login_img" src="@/assets/img/login_img.png" alt="" />
      <div class="const" v-if="denglu">
        <div class="one">美宁健康管理平台</div>
        <div class="form">
          <div class="form-input">
            <div :class="[isUsernameValidate ? '' : 'is-invalid']">
              <el-input
                v-model="formData.username"
                size="medium"
                maxlength="50"
                placeholder="账号"
                prefix-icon="el-icon-user-solid "
                @blur="onValidateUsername"
                @keyup.enter.native="onClickLogin"
              ></el-input>
              <!-- <span class="err-msg">用户名不能为空</span> -->
            </div>
          </div>
          <div class="form-input">
            <div :class="[isPasswordValidate ? '' : 'is-invalid']">
              <el-input
                v-model="formData.password"
                type="password"
                maxlength="50"
                size="medium"
                placeholder="密码"
                prefix-icon="el-icon-lock"
                show-password
                @blur="onValidatePassword"
                @keyup.enter.native="onClickLogin"
              ></el-input>
              <!-- <span class="err-msg">密码不能为空</span> -->
            </div>
          </div>
          <!-- <div class="form-input">
            <el-checkbox v-model="formData.isRemember">记住我</el-checkbox>
          </div> -->
          <div class="form-input">
            <el-button
              class="login-button"
              type="primary"
              size="medium"
              :loading="isLoading"
              @click="onClickLogin"
              >登 录</el-button
            >
          </div>
          <span class="wangji" @click=";(denglu = false), (wangji = true)"
            >忘记密码</span
          >
        </div>
      </div>
      <div class="const_else" v-if="wangji">
        <div class="one">重置密码</div>
        <div class="form">
          <div class="form-input">
            <div :class="[isUsernameValidate ? '' : 'is-invalid']">
              <el-input
                v-model="formData.username"
                size="medium"
                maxlength="50"
                placeholder="账号"
                prefix-icon="el-icon-user-solid "
                @blur="onValidateUsername"
                @keyup.enter.native="onClickLogin"
              ></el-input>
              <!-- <span class="err-msg">用户名不能为空</span> -->
            </div>
          </div>
          <div class="form-input">
            <div :class="[isPasswordValidate ? '' : 'is-invalid']">
              <el-input
                v-model="formData.mobile"
                maxlength="50"
                size="medium"
                placeholder="手机号"
                prefix-icon="el-icon-phone"
                @blur="onValidatePassword"
                @keyup.enter.native="onClickLogin"
              ></el-input>
              <!-- <span class="err-msg">密码不能为空</span> -->
            </div>
          </div>
          <div class="form-input form-input2">
            <div :class="[isPasswordValidate ? '' : 'is-invalid']">
              <el-input
                v-model="formData.code"
                maxlength="50"
                size="medium"
                placeholder="请输入短信验证码"
                prefix-icon="el-icon-phone"
                @blur="onValidatePassword"
                @keyup.enter.native="onClickLogin"
                width="50"
              ></el-input>
              <el-button
                type="button"
                @click="sendcode"
                :disabled="disabled"
                v-if="disabled == false"
                >发送验证码
              </el-button>
              <el-button
                type="button"
                @click="sendcode"
                :disabled="disabled"
                v-if="disabled == true"
                >{{ btntxt }}
              </el-button>
              <!-- <span class="err-msg">密码不能为空</span> -->
            </div>
          </div>
          <!-- <div class="form-input">
            <el-checkbox v-model="formData.isRemember">记住我</el-checkbox>
          </div> -->
          <div class="form-input">
            <el-button
              class="login-button"
              type="primary"
              size="medium"
              :loading="isLoading"
              @click="onClickLogin2"
              >设置新密码</el-button
            >
          </div>
        </div>
      </div>
      <div class="const" v-if="newPass">
        <div class="one">设置新密码</div>
        <div class="form">
          <div class="form-input">
            <div :class="[isUsernameValidate ? '' : 'is-invalid']">
              <el-input
                v-model="formData.newPassword"
                size="medium"
                maxlength="50"
                type="password"
                show-password
                placeholder="新密码"
                prefix-icon="el-icon-lock"
                @blur="onValidateUsername"
                @keyup.enter.native="onClickLogin"
              ></el-input>
              <!-- <span class="err-msg">用户名不能为空</span> -->
            </div>
          </div>
          <div class="form-input">
            <div :class="[isPasswordValidate ? '' : 'is-invalid']">
              <el-input
                v-model="formData.sourcePassword"
                type="password"
                show-password
                maxlength="50"
                size="medium"
                placeholder="确认密码"
                prefix-icon="el-icon-lock"
                @blur="onValidatePassword"
                @keyup.enter.native="onClickLogin"
              ></el-input>
              <!-- <span class="err-msg">密码不能为空</span> -->
            </div>
          </div>
          <!-- <div class="form-input">
            <el-checkbox v-model="formData.isRemember">记住我</el-checkbox>
          </div> -->
          <div class="form-input">
            <el-button
              class="login-button"
              type="primary"
              size="medium"
              :loading="isLoading"
              @click="onClickLogin3"
              >确定找回</el-button
            >
          </div>
        </div>
      </div>
      <div class="const" v-if="chenggong">
        <div class="one">设置成功</div>
        <img class="two_img" src="@/assets/img/ok.png" alt="" />
        <div class="two">
          修改成功!
          <span
            style="cursor:pointer"
            @click=";(chenggong = false), (denglu = true)"
            >{{ daojishi }}</span
          >
          秒后自动返回登录页
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setStorge } from '@/utils/auth'
import {
  sendResetPasswordSmsCodeByMobile,
  authSetPassword
} from '@/api/course.js'
export default {
  name: 'Login',
  components: {},
  data() {
    return {
      denglu: true,
      daojishi: '10',
      Isyanzmafasong: false,
      newPass: false,
      disabled: false,
      chenggong: false,

      time: 0,
      btntxt: '重新发送',
      wangji: false,
      appName: process.env.VUE_APP_NAME,
      isAnnouncementVisiable: false,
      announcementContent: '',
      isLoading: false,
      isUsernameValidate: true,
      isPasswordValidate: true,
      formData: {
        isRemember: false,
        username: '',
        password: '',
        code: '',
        newPassword: '',
        sourcePassword: '',
        mobile: ''
      }
    }
  },
  mounted() {},
  methods: {
    // 手机验证发送验证码
    sendcode() {
      const reg = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/
      if (this.formData.mobile === '') {
        this.$message({
          message: '手机号不能为空',
          center: true
        })
        return
      }
      if (!reg.test(this.formData.mobile)) {
        this.$message({
          message: '请输入正确的手机号',
          center: true
        })
      } else {
        console.log(this.formData.mobile)
        // /modules/auth/sendResetPasswordSmsCodeByMobile
        sendResetPasswordSmsCodeByMobile({
          mobile: this.formData.mobile
        })
          .then(res => {
            console.log(res)
            this.$message({
              message: '发送成功',
              type: 'success',
              center: true
            })
            this.time = 60
            this.disabled = true
            this.timer()
            this.Isyanzmafasong = true
          })
          .catch(res => {
            console.log(res)
          })
      }
    },
    // 60S倒计时
    timer() {
      if (this.time > 0) {
        this.time--
        this.btntxt = this.time + 's后重新获取'
        setTimeout(this.timer, 1000)
      } else {
        this.time = 0
        this.btntxt = '获取验证码'
        this.disabled = false
      }
    },
    timer2() {
      if (this.daojishi > 0) {
        this.daojishi--
        setTimeout(this.timer2, 1000)
      } else {
        this.chenggong = false
        this.denglu = true
      }
    },
    submitForm() {
      console.log(1322310)
    },
    onClickLogin3() {
      // this.formData.mobile = this.formData.username
      var aaa = {}
      aaa.code = this.formData.code
      aaa.mobile = this.formData.mobile
      aaa.newPassword = this.formData.newPassword
      aaa.sourcePassword = this.formData.sourcePassword
      if (aaa.newPassword === aaa.sourcePassword) {
        authSetPassword(aaa)
          .then(res => {
            console.log(res)
            if (res.msg === '请求成功') {
              this.newPass = false
              this.chenggong = true

              this.timer2()
            } else {
              this.$message({
                message: res.msg,
                center: true
              })
            }
          })
          .catch(res => {
            console.log(res)
          })
      } else {
        this.$message({
          message: '两次密码不一致',
          center: true
        })
      }
    },
    onClickLogin2() {
      if (!this.Isyanzmafasong) {
        this.$message({
          message: '请发送验证码',
          center: true
        })
        return
      } else {
        if (this.formData.code === '') {
          this.$message({
            message: '请输入短信验证码',
            center: true
          })
          return
        }
      }
      this.wangji = false
      this.denglu = false
      this.newPass = true
    },
    onClickLogin() {
      console.log(2131)
      const that = this
      this.onValidateUsername()
      this.onValidatePassword()
      if (!this.isUsernameValidate || !this.isPasswordValidate) {
        return
      }
      that.isLoading = true
      that.$store
        .dispatch('requestLogin', this.formData)
        .then(() => {
          setStorge('x-is-remember', that.formData.isRemember)
          that.isLoading = false
          that.$router.push({ path: '/' })
        })
        .catch(() => {
          that.isLoading = false
        })
    },
    onValidateUsername() {
      if (this.$wtUtil.isBlank(this.formData.username)) {
        this.isUsernameValidate = false
      } else {
        this.isUsernameValidate = true
      }
    },
    onValidatePassword() {
      if (this.$wtUtil.isBlank(this.formData.password)) {
        this.isPasswordValidate = false
      } else {
        this.isPasswordValidate = true
      }
    }
  },
  computed: {}
}
</script>
<style lang="scss" scoped>
.form-input2 {
  .el-input {
    width: 60%;
    margin: 0 10px 30px 30px;
  }
  .el-input--medium .el-input__inner {
    width: 65% !important;
    height: 48px !important;
  }
}

#app {
  padding: 160px;
  background: red;
}
.wangji {
  float: right;
  margin-top: 10px;
  margin-right: 29px;
  font-size: 7px;
  cursor: pointer;
}
.el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 86%;
  margin: 0 31px 30px 30px;
}
.content {
  margin: 0 auto;
  border-radius: 6px;
  padding: 93px 0;
  font-size: 24px;

  .content2 {
    background: #ffffff;
    margin: 0 auto;
    border-radius: 6px;
    width: 1000px;
    height: 540px;
  }
  .login_img {
    width: 520px;
    height: 540px;
    float: left;
  }
  .const {
    float: right;
    width: 48%;
    .one {
      color: #0180cc;
      padding: 72px 0;
      text-align: center;
      font-size: 24px;
    }
    .two_img {
      width: 48px;
      height: 48px;
      display: block;
      margin: 0 auto;
    }
    .two {
      font-size: 14px;
      text-align: center;
      margin-top: 24px;
      > span {
        color: #0180cc;
      }
    }
  }
  .const_else {
    float: right;
    width: 48%;
    .one {
      color: #0180cc;
      padding: 72px 0;
      text-align: center;
      font-size: 24px;
    }
  }
}
.el-input__inner {
  padding: 19px 15px !important;
}
</style>

<style lang="scss" scoped>
.login-container {
  width: 100%;
  height: 100%;
  min-width: 1280px;
  min-height: 800px;
  position: relative;
  display: flex;
  background-color: #f8f8f8;
}
.login-container .logo-container .logo-content {
  width: 8rem;
  height: 4rem;
  position: absolute;
  left: 2rem;
  top: 2rem;
}
.login-container .logo-container .logo-content img {
  width: 100%;
  height: auto;
}
.login-container .left-container {
  width: 100%;
  height: 100%;
  flex: 0 0 66.67%;
  padding: 4rem;
  display: flex;
}
.login-container .left-container .launch-bg {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 4rem;
  padding-right: 4rem;
}
.login-container .left-container .launch-bg > img {
  max-width: 100%;
  height: auto;
}
.login-container .right-container {
  flex: 0 0 33.33%;
  background-color: #ffffff;
}
.login-container .right-container .form {
  margin-top: 1.5rem;
}
.login-container .right-container .login-form-container {
  height: 100%;
  display: flex;
  align-items: center;
}
.login-container .right-container .login-form {
  padding: 4rem 5.5rem;
  width: 100%;
}
.login-container .right-container .login-form h2 {
  color: #5e5873;
  margin-top: 0;
  margin-bottom: 1rem;
}
.login-container .right-container .login-form .subtitle {
  font-size: 1rem;
  color: #6e6b7b;
  margin-top: 0;
  margin-bottom: 1rem;
}
.login-container .right-container .login-form .form-input {
  margin-bottom: 1rem;
}
.login-container .right-container .login-form .form-input .title {
  display: block;
  font-size: 0.857rem;
  color: #5e5873;
  margin-bottom: 0.2857rem;
}
.login-container .right-container .login-form .form-input .remember {
  display: block;
  font-size: 1rem;
  color: #5e5873;
  margin-bottom: 0;
}
.login-container .right-container .login-form .form-input .input-control {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}
.login-container .right-container .login-form .form-input .login-button {
  width: 100%;
}
.login-container .right-container .login-form .other {
  width: 100%;
  height: 1rem;
}
.login-container .right-container .other-login-text {
  color: #6e6b7b;
  font-size: 0.6rem;
  font-weight: 400;
}
</style>
<style>
.login-container
  .right-container
  .login-form
  .form-input
  .is-invalid
  .el-input__inner {
  border-color: #ea5455 !important;
}
.login-container .right-container .login-form .form-input .err-msg {
  color: #ea5455 !important;
  font-size: 0.857rem;
  display: none;
  font-weight: 400;
  margin-top: 0.2857rem;
}
.login-container .right-container .login-form .form-input .is-invalid .err-msg {
  display: block;
}
</style>
<style scoped>
.el-button--medium {
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 4px;
  margin: 5px 30px;
  width: 86%;
  height: 48px;
}
</style>
