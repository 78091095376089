<template>
  <div class="page-container">
    <div class="module-container">
      <div style="padding: 32px;">
        <el-collapse v-model="activeNames">
          <el-collapse-item v-if="!item.hidden" v-for="(item, index) in staticRoutes" :key="index" :name="index">
            <template slot="title">
              <el-checkbox class="ck-all">{{item.meta ? item.meta.title : ''}}</el-checkbox>
            </template>
            <module-item v-for="(item, index) in item.children" :data="item" :key="index" @on-item-value-changed="onItemValueChanged"></module-item>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moduleItem from './moduleItem'
import staticRoutes from '@/router/staticRoutes.js'
export default {
  name: 'moduleList',
  components: {
    moduleItem
  },
  data() {
    return {
      staticRoutes: staticRoutes,
      activeNames: [],
      form: {
      }
    }
  },
  mounted() {
  },
  created() {
  },
  methods: {
    onItemValueChanged() {
      console.log('onItemValueChanged')
    }
  },
  computed: {
    ...mapState({
    })
  }
}
</script>
<style>
  .module-container .el-collapse-item__header{background-color: #F0F0F0;border-bottom: 1px solid #FCFCFC;}
</style>
<style scoped>
  .module-container{background-color: white;width: calc(100% - 40px);margin: 20px 20px 16px;border-radius: 4px;}
</style>
