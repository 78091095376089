var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-detail-container"},[_c('wt-detail-header',{attrs:{"title":"详情"}},[_c('wt-button',{on:{"click":function($event){return _vm.$emit('on-close')}}},[_vm._v("返回")])],1),_c('div',{staticClass:"wt-detail-container"},[_c('wt-detail-section',{attrs:{"title":"基本信息"}},[_c('el-form',{ref:"form",staticClass:"wt-detail-section-form-center",attrs:{"model":_vm.detailForm,"size":"small","label-width":"30%"}},[_c('el-form-item',{attrs:{"label":"患者ID：","prop":"patientId","rules":[
            {
              required: true,
              message: '患者ID不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择","readonly":"","disabled":""},model:{value:(_vm.detailForm.patientId),callback:function ($$v) {_vm.$set(_vm.detailForm, "patientId", $$v)},expression:"detailForm.patientId"}})],1),_c('el-form-item',{attrs:{"label":"医生ID：","prop":"doctorId","rules":[
            {
              required: true,
              message: '医生ID不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择","readonly":"","disabled":""},model:{value:(_vm.detailForm.doctorId),callback:function ($$v) {_vm.$set(_vm.detailForm, "doctorId", $$v)},expression:"detailForm.doctorId"}})],1),_c('el-form-item',{attrs:{"label":"是否发布：","prop":"isPublish","rules":[
            {
              required: true,
              message: '请选择发布状态',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('wt-form-simple-select-item',{attrs:{"readonly":"","disabled":""},model:{value:(_vm.detailForm.isPublish),callback:function ($$v) {_vm.$set(_vm.detailForm, "isPublish", $$v)},expression:"detailForm.isPublish"}})],1),_c('el-form-item',{attrs:{"label":"发起类型人  1 医生  2 患者：","prop":"sponsorType","rules":[
            {
              required: true,
              message: '发起类型人  1 医生  2 患者不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择","readonly":"","disabled":""},model:{value:(_vm.detailForm.sponsorType),callback:function ($$v) {_vm.$set(_vm.detailForm, "sponsorType", $$v)},expression:"detailForm.sponsorType"}})],1),_c('el-form-item',{attrs:{"label":"咨询次数：","prop":"advisoryNum","rules":[
            {
              required: true,
              message: '咨询次数不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_vm._v(" "+_vm._s(_vm.detailForm.advisoryNum)+" ")]),_c('el-form-item',{attrs:{"label":"最后一次咨询时间：","prop":"endTime","rules":[
            {
              required: true,
              message: '最后一次咨询时间不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_vm._v(" "+_vm._s(_vm.detailForm.endTime)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }