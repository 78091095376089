<template>
  <div class="page-detail-container">
    <!--顶部标题和页面全局按钮-->
    <wt-detail-header title="详情">
      <wt-button @click="$emit('on-close')">返回</wt-button>
    </wt-detail-header>
    <!--内容区域-->
    <div class="wt-detail-container">
      <wt-detail-section title="基本信息">
        <el-form :model="detailForm" ref="form" size="small" class="wt-detail-section-form-center" label-width="30%">
                    <el-form-item label="一级栏目：" prop="parientId" :rules="[{ required: true, message: '一级栏目不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' }]">
              <el-select v-model="detailForm.parientId" placeholder="请选择" readonly disabled></el-select>
            </el-form-item>
            <el-form-item label="栏目名称：" prop="title" :rules="[{ required: true, message: '栏目名称不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
              {{detailForm.title}}
            </el-form-item>
            <el-form-item label="排序：" prop="sortNum" :rules="[{ required: true, message: '排序不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' }]">
              <el-select v-model="detailForm.sortNum" placeholder="请选择" readonly disabled></el-select>
            </el-form-item>
            <el-form-item label="是否添加二级栏目：" prop="isAddChild" :rules="[{ required: true, message: '是否添加二级栏目不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' }]">
              <el-select v-model="detailForm.isAddChild" placeholder="请选择" readonly disabled></el-select>
            </el-form-item>
            <el-form-item label="是否发布：" prop="isPublish" :rules="[{ required: true, message: '请选择发布状态', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' }]">
              <wt-form-simple-select-item v-model="detailForm.isPublish" readonly disabled/>
            </el-form-item>
        </el-form>
      </wt-detail-section>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ArticleGroupView',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isFromLoading: false,
      detailForm: {}
    }
  },
  mounted() {
    this.requestDetail()
  },
  created() {
  },
  methods: {
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: '/modules/articleGroup/detail',
        method: 'post',
        data: { id: that.detailData.id }
      }).then((resp) => {
        that.detailForm = resp.data || {}
        that.isFromLoading = false
      }).catch(() => {
        that.isFromLoading = false
      })
    }
  },
  computed: {
    ...mapState({
    })
  }
}
</script>
