var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-detail-container"},[_c('wt-detail-header',{attrs:{"title":"详情"}},[(_vm.viewType == 'add')?_c('wt-button',{directives:[{name:"has-any-premission",rawName:"v-has-any-premission",value:(['MEDICINEREMIND:ADD']),expression:"['MEDICINEREMIND:ADD']"}],attrs:{"type":"primary","loading":_vm.isButtonLoading},on:{"click":_vm.onClickAdd}},[_vm._v("保存")]):_vm._e(),(_vm.viewType == 'edit')?_c('wt-button',{directives:[{name:"has-any-premission",rawName:"v-has-any-premission",value:(['MEDICINEREMIND:EDIT']),expression:"['MEDICINEREMIND:EDIT']"}],attrs:{"type":"primary","loading":_vm.isButtonLoading},on:{"click":_vm.onClickSave}},[_vm._v("保存")]):_vm._e(),_c('wt-button',{on:{"click":function($event){return _vm.$emit('on-close')}}},[_vm._v("返回")])],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isFromLoading),expression:"isFromLoading"}],staticClass:"wt-detail-container"},[_c('wt-detail-section',{attrs:{"title":"基本信息"}},[_c('el-form',{ref:"form",staticClass:"wt-detail-section-form-center",attrs:{"model":_vm.detailForm,"size":"small","label-width":"30%"}},[_c('el-form-item',{attrs:{"label":"类型 1 用药提醒 2 复诊提醒：","prop":"type","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.detailForm.type),callback:function ($$v) {_vm.$set(_vm.detailForm, "type", $$v)},expression:"detailForm.type"}})],1),_c('el-form-item',{attrs:{"label":"提醒名称：","prop":"title","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.detailForm.title),callback:function ($$v) {_vm.$set(_vm.detailForm, "title", $$v)},expression:"detailForm.title"}})],1),_c('el-form-item',{attrs:{"label":"服药剂量：","prop":"dosage","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.detailForm.dosage),callback:function ($$v) {_vm.$set(_vm.detailForm, "dosage", $$v)},expression:"detailForm.dosage"}})],1),_c('el-form-item',{attrs:{"label":"提醒次数：","prop":"remindNum","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.detailForm.remindNum),callback:function ($$v) {_vm.$set(_vm.detailForm, "remindNum", $$v)},expression:"detailForm.remindNum"}})],1),_c('el-form-item',{attrs:{"label":"提醒频率 1 每天 2每周 3每隔x天 4固定周期：","prop":"frequency","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.detailForm.frequency),callback:function ($$v) {_vm.$set(_vm.detailForm, "frequency", $$v)},expression:"detailForm.frequency"}})],1),_c('el-form-item',{attrs:{"label":"连续天数：","prop":"continuousDay","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.detailForm.continuousDay),callback:function ($$v) {_vm.$set(_vm.detailForm, "continuousDay", $$v)},expression:"detailForm.continuousDay"}})],1),_c('el-form-item',{attrs:{"label":"间隔天数：","prop":"intervalDay","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.detailForm.intervalDay),callback:function ($$v) {_vm.$set(_vm.detailForm, "intervalDay", $$v)},expression:"detailForm.intervalDay"}})],1),_c('el-form-item',{attrs:{"label":"周期：","prop":"period","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.detailForm.period),callback:function ($$v) {_vm.$set(_vm.detailForm, "period", $$v)},expression:"detailForm.period"}})],1),_c('el-form-item',{attrs:{"label":"是否开启：","prop":"isOpen","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.detailForm.isOpen),callback:function ($$v) {_vm.$set(_vm.detailForm, "isOpen", $$v)},expression:"detailForm.isOpen"}})],1),_c('el-form-item',{attrs:{"label":"结束时间：","prop":"endTime","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'blur'
            }
          ]}},[_c('el-date-picker',{attrs:{"type":"datetime","placeholder":"请选择"},model:{value:(_vm.detailForm.endTime),callback:function ($$v) {_vm.$set(_vm.detailForm, "endTime", $$v)},expression:"detailForm.endTime"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }