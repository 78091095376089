<template>
  <div class="page-container">
    <wt-data-form
      ref="dataForm"
      :form.sync="form"
      request-list-url="/modules/patient/list"
      request-delete-url="/modules/patient/delete"
      request-detail-url="/modules/patient/detail"
    >
      <template slot="search">
        <wt-search-form-item
          title="主键"
          v-model="form.formData.id"
          type="select"
        />
        <wt-search-form-item
          title="首字母"
          v-model="form.formData.initial"
          type="input"
        />
        <wt-search-form-item
          title="手机号"
          v-model="form.formData.phone"
          type="input"
        />
        <wt-search-form-item
          title="密码"
          v-model="form.formData.password"
          type="input"
        />
        <wt-search-form-item
          title="姓名"
          v-model="form.formData.realname"
          type="input"
        />
        <wt-search-form-item
          title="性别"
          v-model="form.formData.gender"
          type="select"
        />
        <wt-search-form-item
          title="积分"
          v-model="form.formData.point"
          type="select"
        />
        <wt-search-form-item
          title="医院"
          v-model="form.formData.age"
          type="select"
        />
        <wt-search-form-item
          title="头像"
          v-model="form.formData.head"
          type="input"
        />
        <wt-search-form-item
          title="所在城市"
          v-model="form.formData.city"
          type="input"
        />
        <wt-search-form-item
          title="家属姓名"
          v-model="form.formData.relationName"
          type="input"
        />
        <wt-search-form-item
          title="家属联系人电话"
          v-model="form.formData.relationPhone"
          type="input"
        />
        <wt-search-form-item
          title="是否发布 0否1是"
          v-model="form.formData.isPublish"
          type="select"
        />
        <wt-search-form-item
          title="是否删除"
          v-model="form.formData.isDelete"
          type="select"
        />
        <wt-search-form-item
          title="创建时间"
          v-model="form.formData.createTime"
          type="daterange"
        />
        <wt-search-form-item
          title="更新时间"
          v-model="form.formData.updateTime"
          type="daterange"
        />
        <wt-search-form-item
          title="二维码地址"
          v-model="form.formData.code"
          type="input"
        />
        <wt-search-form-item
          title="详细地址"
          v-model="form.formData.address"
          type="input"
        />
        <wt-search-form-item
          title="邮政编码"
          v-model="form.formData.postalCode"
          type="input"
        />
      </template>
      <template slot="toolbar">
        <wt-button
          type="primary"
          v-has-any-premission="['PATIENT:ADD']"
          @click="$refs.dataForm.toAdd()"
          >新增</wt-button
        >
        <wt-button
          type="danger"
          v-has-any-premission="['PATIENT:DELETE']"
          @click="$refs.dataForm.onClickMultipleDelete()"
          >删除</wt-button
        >
      </template>
      <template slot="table">
        <el-table-column type="selection" label="选择"></el-table-column>
        <el-table-column prop="id" label="主键" sortable></el-table-column>
        <el-table-column prop="initial" label="首字母"></el-table-column>
        <el-table-column prop="phone" label="手机号"></el-table-column>
        <el-table-column prop="password" label="密码"></el-table-column>
        <el-table-column prop="realname" label="姓名"></el-table-column>
        <el-table-column prop="gender" label="性别" sortable></el-table-column>
        <el-table-column prop="point" label="积分" sortable></el-table-column>
        <el-table-column prop="age" label="医院" sortable></el-table-column>
        <el-table-column prop="head" label="头像"></el-table-column>
        <el-table-column prop="city" label="所在城市"></el-table-column>
        <el-table-column prop="relationName" label="家属姓名"></el-table-column>
        <el-table-column
          prop="relationPhone"
          label="家属联系人电话"
        ></el-table-column>
        <el-table-column prop="isPublish" label="发布状态"
          ><template slot-scope="scope"
            ><span :class="scope.row.isPublish | FILTER_IsPublishClass">{{
              scope.row.isPublish | FILTER_IsPublish
            }}</span></template
          ></el-table-column
        >
        <el-table-column
          prop="createTime"
          label="创建时间"
          sortable
        ></el-table-column>
        <el-table-column
          prop="updateTime"
          label="更新时间"
          sortable
        ></el-table-column>
        <el-table-column prop="code" label="二维码地址"></el-table-column>
        <el-table-column prop="address" label="详细地址"></el-table-column>
        <el-table-column prop="postalCode" label="邮政编码"></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <wt-text-button
              v-has-any-premission="['PATIENT:VIEW']"
              @click="$refs.dataForm.toView(scope.row)"
              >查看详情</wt-text-button
            >
            <wt-text-button
              v-has-any-premission="['PATIENT:EDIT']"
              @click="$refs.dataForm.toEdit(scope.row)"
              >编辑</wt-text-button
            >
          </template>
        </el-table-column>
      </template>
    </wt-data-form>
    <!--弹层 开始-->
    <wt-detail-page :visible.sync="form.isViewDialogVisible"
      ><PatientView
        :detailData="form.detailData"
        view-type="view"
        @on-close="form.isViewDialogVisible = false"
    /></wt-detail-page>
    <wt-detail-page :visible.sync="form.isEditDialogVisible"
      ><PatientEdit
        :detailData="form.detailData"
        :view-type="form.dialogViewType"
        @on-refresh="$refs.dataForm.requestDataList()"
        @on-close="form.isEditDialogVisible = false"
    /></wt-detail-page>
    <!--弹层 结束-->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'patientList',
  data() {
    return {
      form: {
        isFromLoading: false,
        isViewDialogVisible: false,
        isEditDialogVisible: false,
        dialogViewType: 'edit',
        detailData: {},
        extFormData: {},
        formData: {
          name: '',
          updateTimeRange: []
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        table: {},
        formResult: {}
      }
    }
  },
  mounted() {
    this.$refs.dataForm.onClickSearch()
  },
  created() {},
  methods: {},
  computed: {
    ...mapState({})
  }
}
</script>
