<template>
  <div class="page-container">
    <wt-data-form
      ref="dataForm"
      :form.sync="form"
      request-list-url="/modules/giftOrder/list"
      request-delete-url="/modules/giftOrder/delete"
      request-detail-url="/modules/giftOrder/detail"
    >
      <template slot="search">
        <wt-search-form-item
          title="姓名"
          v-model="form.formData.username"
          type="input"
        />
        <wt-search-form-item
          title="角色"
          v-model="form.formData.type"
          :data-list="stateList"
          type="select"
        />
        <wt-search-form-item
          title="手机号"
          v-model="form.formData.receiptPhone"
          type="input"
        />
        <wt-search-form-item
          title="商品"
          v-model="form.formData.giftName"
          type="input"
        />
      </template>
      <template slot="toolbar">
        <!-- <wt-button
          type="primary"
          @click="$refs.dataForm.exportData2('医生信息')"
          >导出医生信息</wt-button
        > -->
        <!-- <wt-button type="primary" @click="toAdddoy">添加商品</wt-button> -->
      </template>
      <template slot="table">
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column
          prop="orderNo"
          label="订单号"
          width="70"
        ></el-table-column>
        <el-table-column
          prop="username"
          label="姓名"
          width="100"
        ></el-table-column>
        <el-table-column prop="type" label="角色" width="100">
          <template slot-scope="scope">
            {{ scope.row.type | statusText }}
          </template>
        </el-table-column>

        <el-table-column
          prop="receiptPhone"
          label="手机号"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="giftName"
          label="兑换商品"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="exchangeNum"
          label="兑换数量"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="point"
          label="消耗积分"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="兑换时间"
          width="100"
        ></el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            {{ scope.row.status | statusText2 }}
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <div v-if="scope.row.status === 1">
              <span
                style="color: #0180CC;cursor: pointer;"
                @click="tongguo(scope.row)"
                >通过</span
              >
              <span
                style="color: #FF5B5B;margin-left: 15px;cursor: pointer"
                @click="jujuebtn(scope.row)"
                >拒绝</span
              >
            </div>
            <div v-if="scope.row.status === 2">
              <span style="color: #999999;">已通过</span>
              <span
                style="color: #0180CC;margin-left: 15px;cursor: pointer"
                @click="fahuo(scope.row)"
                >填写发货</span
              >
            </div>
            <div v-if="scope.row.status === 3">
              <span style="color: #999999;">已通过</span>
              <span style="color: #0180CC;margin-left: 15px;">已发货</span>
            </div>
            <div v-if="scope.row.status === 4">
              <span style="color: #999999;">已拒绝</span>
              <span
                style="color: #FF5B5B;margin-left: 15px;cursor: pointer"
                @click="chakan(scope.row)"
                >查看原因</span
              >
            </div>
          </template>
        </el-table-column>
      </template>
    </wt-data-form>
    <!--弹层 开始-->
    <el-dialog
      title="填写发货信息"
      :visible.sync="dialogFormVisible"
      :modal-append-to-body="false"
    >
      <el-form :model="form2">
        <el-form-item label="收货人姓名:" :label-width="formLabelWidth">
          <el-input
            v-model="form2.consignee"
            disabled
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="兑换商品:" :label-width="formLabelWidth">
          <el-input
            v-model="form2.giftName"
            disabled
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号:" :label-width="formLabelWidth">
          <el-input
            v-model="form2.receiptPhone"
            disabled
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="收货地址:" :label-width="formLabelWidth">
          <el-input
            v-model="form2.address"
            disabled
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="物流公司:" :label-width="formLabelWidth">
          <el-input
            v-model="form2.logisticsCompany"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="快递单号:" :label-width="formLabelWidth">
          <el-input
            v-model="form2.trackingNumber"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="兑换商品" :label-width="formLabelWidth">
          <el-select v-model="form2.region" placeholder="请选择活动区域">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="duqding">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="拒绝原因"
      :visible.sync="dialogFormVisible2"
      :modal-append-to-body="false"
    >
      {{ jujueyuanying }}
    </el-dialog>
    <el-dialog
      title="填写拒绝原因"
      :visible.sync="dialogFormVisible3"
      :modal-append-to-body="false"
    >
      <span style="margin-bottom: 24px;display: block;">审核未通过原因:</span>
      <el-input
        type="textarea"
        :rows="2"
        placeholder="请输入内容"
        v-model="textarea"
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible3 = false">取 消</el-button>
        <el-button type="primary" @click="jujuequeding">确 定</el-button>
      </span>
    </el-dialog>

    <!--弹层 结束-->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { doctorDelete, giftOrderUpdate } from '@/api/course.js'
export default {
  name: 'accountList',
  data() {
    return {
      stateList: [
        { label: '医生', value: 1 },
        { label: '患者', value: 2 }
      ],
      areaList: [],
      form: {
        dialogViewType: 'edit',
        detailData: {},
        formData: {
          list: [],
          phone: '',
          realname: ''
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        formResult: {}
      },
      dialogFormVisible: false,
      dialogFormVisible2: false,
      dialogFormVisible3: false,
      form2: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      formLabelWidth: '120px',
      jujueyuanying: '',
      textarea: '',
      dangqianxuanzhog: {}
    }
  },
  mounted() {
    this.$refs.dataForm.requestDataList()
  },
  created() {},
  methods: {
    jujuequeding() {
      console.log(this.dangqianxuanzhog)
      if (this.textarea === '') {
        this.$message({
          message: '请填写拒绝原因',
          type: 'warning'
        })
      } else {
        giftOrderUpdate({
          reason: this.textarea,
          id: this.dangqianxuanzhog,
          status: 4
        })
          .then(res => {
            console.log(res)
            this.$message({
              message: '提交成功',
              type: 'success'
            })
            this.textarea = ''
            this.$refs.dataForm.requestDataList()
          })
          .catch(res => {
            console.log(res)
          })
        this.dialogFormVisible3 = false
        console.log(this.textarea)
      }
    },
    jujuebtn(data) {
      console.log(data.reason)
      this.dangqianxuanzhog = data.id
      this.dialogFormVisible3 = true
    },
    tongguo(data) {
      console.log(data)
      giftOrderUpdate({
        id: data.id,
        status: 2
      })
        .then(res => {
          console.log(res)
          this.$message({
            message: '通过成功',
            type: 'success'
          })
          this.$refs.dataForm.requestDataList()
        })
        .catch(res => {
          console.log(res)
        })
    },
    chakan(data) {
      console.log(data.reason)

      this.jujueyuanying = data.reason
      this.dialogFormVisible2 = true
    },
    duqding() {
      console.log(this.form2)
      this.form2.status = 3
      giftOrderUpdate(this.form2)
        .then(res => {
          console.log(res)
          this.$message({
            message: '添加成功',
            type: 'success'
          })
          this.dialogFormVisible = false
          this.$refs.dataForm.requestDataList()
        })
        .catch(res => {
          console.log(res)
        })
    },
    handleClick(row) {
      console.log(row)
    },
    del(data) {
      var dellist = {
        list: [data]
      }
      console.log(dellist)
      doctorDelete(dellist)
        .then(res => {
          console.log(res)
          if (res.code === '0') {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            // this.$router.push({ path: '/system/patientList' })
            this.$refs.dataForm.requestDataList()
          }
        })
        .catch(res => {
          console.log(res)
        })
      console.log(data)
    },
    fahuo(data) {
      this.dialogFormVisible = true
      this.form2 = data
      console.log(data)
    }
  },
  computed: {
    ...mapState({})
  },
  filters: {
    statusText(status) {
      if (status === 1) {
        return '医生'
      } else {
        return '患者'
      }
    },
    statusText2(status) {
      if (status === 1) {
        return '待审核'
      } else if (status === 2) {
        return '审核通过，待发货 '
      } else if (status === 3) {
        return '审核通过，已发货 '
      } else {
        return '拒绝'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chouzuospan {
  color: #0180cc;
  font-size: 14px;
  margin-right: 14px;
  cursor: pointer;
}
</style>
