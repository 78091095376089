<template>
  <div class="page-detail-container">
    <!--顶部标题和页面全局按钮-->
    <wt-detail-header title="详情">
      <wt-button @click="$emit('on-close')">返回</wt-button>
    </wt-detail-header>
    <!--内容区域-->
    <div class="wt-detail-container">
      <wt-detail-section title="基本信息">
        <el-form
          :model="detailForm"
          ref="form"
          size="small"
          class="wt-detail-section-form-center"
          label-width="30%"
        >
          <el-form-item
            label="类型 1 用药提醒 2 复诊提醒："
            prop="type"
            :rules="[
              {
                required: true,
                message: '类型 1 用药提醒 2 复诊提醒不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'change'
              }
            ]"
          >
            <el-select
              v-model="detailForm.type"
              placeholder="请选择"
              readonly
              disabled
            ></el-select>
          </el-form-item>
          <el-form-item
            label="提醒名称："
            prop="title"
            :rules="[
              {
                required: true,
                message: '提醒名称不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            {{ detailForm.title }}
          </el-form-item>
          <el-form-item
            label="服药剂量："
            prop="dosage"
            :rules="[
              {
                required: true,
                message: '服药剂量不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            {{ detailForm.dosage }}
          </el-form-item>
          <el-form-item
            label="提醒次数："
            prop="remindNum"
            :rules="[
              {
                required: true,
                message: '提醒次数不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'change'
              }
            ]"
          >
            <el-select
              v-model="detailForm.remindNum"
              placeholder="请选择"
              readonly
              disabled
            ></el-select>
          </el-form-item>
          <el-form-item
            label="提醒频率 1 每天 2每周 3每隔x天 4固定周期："
            prop="frequency"
            :rules="[
              {
                required: true,
                message: '提醒频率 1 每天 2每周 3每隔x天 4固定周期不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'change'
              }
            ]"
          >
            <el-select
              v-model="detailForm.frequency"
              placeholder="请选择"
              readonly
              disabled
            ></el-select>
          </el-form-item>
          <el-form-item
            label="连续天数："
            prop="continuousDay"
            :rules="[
              {
                required: true,
                message: '连续天数不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'change'
              }
            ]"
          >
            <el-select
              v-model="detailForm.continuousDay"
              placeholder="请选择"
              readonly
              disabled
            ></el-select>
          </el-form-item>
          <el-form-item
            label="间隔天数："
            prop="intervalDay"
            :rules="[
              {
                required: true,
                message: '间隔天数不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'change'
              }
            ]"
          >
            <el-select
              v-model="detailForm.intervalDay"
              placeholder="请选择"
              readonly
              disabled
            ></el-select>
          </el-form-item>
          <el-form-item
            label="周期："
            prop="period"
            :rules="[
              {
                required: true,
                message: '周期不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            {{ detailForm.period }}
          </el-form-item>
          <el-form-item
            label="是否开启："
            prop="isOpen"
            :rules="[
              {
                required: true,
                message: '是否开启不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'change'
              }
            ]"
          >
            <el-select
              v-model="detailForm.isOpen"
              placeholder="请选择"
              readonly
              disabled
            ></el-select>
          </el-form-item>
          <el-form-item
            label="结束时间："
            prop="endTime"
            :rules="[
              {
                required: true,
                message: '结束时间不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            {{ detailForm.endTime }}
          </el-form-item>
        </el-form>
      </wt-detail-section>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'MedicineRemindView',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isFromLoading: false,
      detailForm: {}
    }
  },
  mounted() {
    this.requestDetail()
  },
  created() {},
  methods: {
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that
        .$wtRequest({
          url: '/modules/medicineRemind/detail',
          method: 'post',
          data: { id: that.detailData.id }
        })
        .then(resp => {
          that.detailForm = resp.data || {}
          that.isFromLoading = false
        })
        .catch(() => {
          that.isFromLoading = false
        })
    }
  },
  computed: {
    ...mapState({})
  }
}
</script>
