<template>
  <div class="wt-oss-upload" :class="className">
    <el-image-viewer v-if="isPreviewVisiable" :on-close="() => { isPreviewVisiable = false }" :url-list="previewSrcList" />
    <div class="wt-oss-upload-btn" v-show="!readonly">
      <el-button v-show="type === 'singe' && dataList.length >= 1 ? false : true" type="primary" size="small" @click="$refs['wtOSSUploadInput_' + uploadUuid].click()">点击上传</el-button>
      <input :ref="'wtOSSUploadInput_' + uploadUuid" type="file" v-show="false" :accept="accept.value" :multiple="multiple" @change="onFilePickerChange">
      <div class="wt-oss-upload-preview-container" v-if="listType == 'picture-card'">
        <el-image :ref="'wtOSSUploadPreview_' + uploadUuid" v-if="previewUrlList && previewUrlList.length > 0" :src="previewUrl" :preview-src-list="previewUrlList"></el-image>
      </div>
      <input type="text" :value="value" style='display:none;'>
    </div>
    <!--提示文字区域-->
    <div class="wt-oss-upload-tip" v-show="!readonly">
      注:只能上传<span class="red">{{ accept.label }}</span>文件，<template v-if="uploadTip">尺寸建议<span class="red">{{ uploadTip }} </span></template>
    </div>
    <!--图片类型(picture-card)列表-->
    <div class="wt-oss-upload-imglist" v-if="listType == 'picture-card'">
      <div class="wt-oss-upload-item" v-for="(item, index) in dataList">
        <div class="content">
          <el-image v-if="item.state == 'SUCCESS' && item.url" class="content-image" :src="wtFileServerUrl + item.url" fit="cover" :preview-src-list="previewUrlList"></el-image>
        </div>
        <label class="state" :class="[item.state === 'SUCCESS' ? 'state-success' : '']"></label>
        <el-progress v-show="item.state == 'UPLOADING'" :percentage="item.percent" type="circle" :width="80"></el-progress>
        <div class="panel">
          <div class="panel-btns" v-if="item.state == 'SUCCESS'"><i class="panel-btn el-icon-view" @click="onFileListItemView(index)"></i><i class="panel-btn el-icon-delete" @click="onFileListItemDelete(index)"></i></div>
          <div class="panel-btns" v-if="item.state == 'UPLOADING'"></div>
        </div>
      </div>
    </div>
    <!--文件类型(card)列表-->
    <div class="wt-oss-upload-filelist" v-if="listType == 'card'">
      <div class="wt-oss-upload-item" v-for="(item, index) in dataList" :key="index">
        <div class="content">
          <div class="title link-title" @click="onClickPreview(item)"><i class="el-icon-document"></i><span>{{item.name}}</span></div>
          <div class="panel">
            <div class="panel-btn" v-if="item.state == 'SUCCESS'" v-show="!readonly" @click="onFileListItemDelete(index)"><i class="el-icon-close"></i></div>
            <span class="panel-text" v-if="item.state == 'UPLOADING'">{{item.percent}}%</span>
          </div>
        </div>
        <el-progress class="progress" v-show="item.state == 'UPLOADING'" :percentage="item.percent" :show-text="false" :stroke-width="4"></el-progress>
      </div>
    </div>
  </div>
</template>

<script>
import Emitter from 'element-ui/src/mixins/emitter'
import request from '@/utils/request'
import SparkMD5 from 'spark-md5'
import wtUploadRequest from '@/utils/wtUploadRequest'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

export default {
  name: 'wt-upload',
  mixins: [Emitter],
  components: { ElImageViewer },
  props: {
    value: {},
    directory: {
      type: String,
      default: 'temp'
    },
    uploadTip: {
      type: String,
      default: null
    },
    className: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'list'
    },
    // 是否支持拖拽上传
    drag: {
      type: Boolean,
      default: true
    },
    // 上传个数限制
    max: {
      type: Number,
      default: 9
    },
    // 文件列表
    fileList: {
      type: Array,
      default: function() {
        return []
      }
    },
    // 是否显示文件列表
    showFileList: {
      type: Boolean,
      default: true
    },
    // 上传按钮文字显示
    btnTxt: {
      type: String,
      default: '上传'
    },
    // 接受上传的文件类型(/分割) 支持：jpg','png','txt','zip', 'rar','pdf','doc','docx','xlsx', 'mp4'
    accept: {
      type: Object,
      default: function() {
        return { label: 'image/*', value: 'image/*' }
      }
    },
    multiple: {
      type: Boolean,
      default: true
    },
    // 列表展示类型  支持：card/picture-card
    listType: {
      type: String,
      default: 'card'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      isPreviewVisiable: false,
      previewSrcList: [],
      uploadUuid: this.$wtUtil.uuid(),
      wtFileServerUrl: window.wtConst.FILE_SERVER_URL,
      previewIndex: 0,
      ossClientMap: {},
      // dataList: [{ name: '图片容器.jpg', percentage: 0, state: 'SUCCESS', url: 'ossFiles/temp/561730736a8ec0f9c23031703165705c.jpg' }, { name: '1.jpg', percentage: 0, state: 'SUCCESS', url: 'ossFiles/temp/561730736a8ec0f9c23031703165705c.jpg' }],
      dataList: [],
      fileType: 0
    }
  },
  watch: {
    'value': function(val, oldVal) {
      // console.log('watch dataList', this.dataList)
      if (val != null && val.length <= 0) {
        this.dataList = []
        return
      }
      if (val === oldVal) {
        return
      }
      if ((typeof val) === 'string' && val === '') {
        this.dataList = []
      } else if ((typeof val) === 'string') {
        this.dataList = [{ url: val, state: 'SUCCESS', fileId: 'fileId_0' }]
      } else if (val && val.length > 0) {
        const tempList = []
        val.forEach((item, index) => {
          tempList.push({ name: item.fileName, url: item.fileUrl, state: 'SUCCESS', fileId: 'fileId_' + index })
        })
        this.dataList = tempList
      }
      this.dispatch('ElFormItem', 'el.form.change', val)
    }
  },
  mounted() {
  },
  created() {
    if (this.value && this.value.length !== this.dataList.length) {
      const tempList = []
      this.value.forEach((item, index) => {
        tempList.push({ name: item.fileName, url: item.fileUrl, state: 'SUCCESS', fileId: 'fileId_' + index })
      })
      this.dataList = tempList
    }
  },
  methods: {
    onClickPreview(item) {
      const fileName = item.url.toLowerCase()
      if (fileName.endsWith('.pdf') || fileName.endsWith('.xls') || fileName.endsWith('.xlsx') || fileName.endsWith('.doc') || fileName.endsWith('.docx')) {
        window.open(this.wtFileServerUrl + item.url, '_blank')
      } else {
        this.previewSrcList = [this.wtFileServerUrl + item.url]
        this.isPreviewVisiable = true
      }
    },
    onFileListItemView(index) {
      this.previewIndex = index
      this.$refs['wtOSSUploadPreview_' + this.uploadUuid].$el.children[0].click()
    },
    onFileListItemDelete(index) {
      // console.log('onFileListItemDelete', this.dataList)
      if (this.dataList.length > index) {
        this.removeFileItem(this.dataList[index].fileId)
      }
    },
    onFilePickerChange() {
      if (!event.target.files || event.target.files.length <= 0) {
        this.$refs['wtOSSUploadInput_' + this.uploadUuid].value = null
        return false
      }
      if (this.type === 'singe' && this.dataList.length >= 1) {
        this.$message.warning(`最多可以上传1个文件`)
        this.$refs['wtOSSUploadInput_' + this.uploadUuid].value = null
        return false
      }
      //  检查文件数量
      if (this.max && this.max < this.dataList.length + event.target.files.length) {
        this.$message.warning(`最多可以上传${this.max}个文件`)
        this.$refs['wtOSSUploadInput_' + this.uploadUuid].value = null
        return false
      }
      //  检查单文件大小
      for (let i = 0; i < event.target.files.length; i++) {
        const size = event.target.files[i].size
        if (size > this.fileSize) {
          this.$message.warning(`单文件最大大小为${this.$wtUtil.covertByteToString(this.fileSize)}`)
          this.$refs['wtOSSUploadInput_' + this.uploadUuid].value = null
          return false
        }
      }
      //  添加文件
      for (let i = 0; i < event.target.files.length; i++) {
        const fileItem = event.target.files[i]
        fileItem.fileId = this.$wtUtil.uuid()
        this.dataList.push({ file: fileItem, name: fileItem.name, url: null, state: 'UPLOADING', percent: 0, fileId: fileItem.fileId })
        this.onStartUploadFile(fileItem)
      }
      this.$refs['wtOSSUploadInput_' + this.uploadUuid].value = null
    },
    onStartUploadFile(file) {
      const that = this
      that.getFileMd5(file, (md5File, result) => {
        //  获取文件md5成功，检查文件在云端是否已上传
        that.updateFileItem(file.fileId, { state: 'UPLOADING', percent: 1 })
        if (result.resultCode === 'SUCCESS') {
          file.fileMd5 = result.fileMd5
          that.requestCheckFile(file)
        }
      })
    },
    getFileMd5(file, callback) {
      const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
      const chunkSize = 1024 * 1024 * 2
      const chunks = Math.ceil(file.size / chunkSize)
      let currentChunk = 0
      const spark = new SparkMD5.ArrayBuffer()
      const fileReader = new FileReader()

      fileReader.onload = function(e) {
        spark.append(e.target.result)
        currentChunk++
        if (currentChunk < chunks) {
          loadNext()
        } else {
          const fileMd5 = spark.end()
          callback(file, { resultCode: 'SUCCESS', fileMd5: fileMd5 })
        }
      }

      fileReader.onerror = function(err) {
        callback(file, { resultCode: 'FAIL', msg: err })
      }

      function loadNext() {
        const start = currentChunk * chunkSize
        const end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize
        fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
      }

      loadNext()
    },
    //  网络获取文件是否已上传，如果已经上传，直接使用
    requestCheckFile(md5File) {
      const that = this
      request({
        url: '/modules/resourceFile/checkFile',
        method: 'post',
        data: { id: md5File.fileMd5 }
      }).then(resp => {
        that.updateFileItem(md5File.fileId, { state: 'UPLOADING', percent: 2 })
        if (resp.code === '0') {
          that.requestUploadFile(md5File)
        } else {
          that.removeFileItem(md5File.fileId)
          that.$message.warning('检查文件失败,请稍候再试')
        }
      }).catch(() => {
        that.removeFileItem(md5File.fileId)
      })
    },
    // 上传
    requestUploadFile(md5File) {
      const that = this
      const formData = new FormData()
      formData.append('file', md5File, md5File.name)
      wtUploadRequest.post('/modules/resourceFile/uploadFile', formData).then((resp) => {
        that.isLoading = false
        if (resp.code === '0' && resp.data) {
          md5File.fileUrl = resp.data.url
          md5File.fileName = md5File.name
          that.updateFileItem(md5File.fileId, { state: 'SUCCESS', percent: 100, url: resp.data.url })
        }
      }).catch(() => {
        that.isLoading = false
        that.$message.warning('上传失败')
      })
    },
    // 监听上传结果
    handleChange(e) {
    },
    updateFileItem(fileId, { state, percent, url }) {
      if (this.dataList && this.dataList.length > 0) {
        this.dataList.forEach((item, index) => {
          if (item.fileId === fileId) {
            item.state = state
            item.percent = Math.floor(Math.min(percent, 100))
            item.url = url
          }
        })
      }
      if (state !== 'UPLOADING') {
        this.updateValue()
      }
    },
    removeFileItem(fileId) {
      // console.log('removeFileItem', this.dataList, fileId)
      if (this.dataList && this.dataList.length > 0) {
        this.dataList.forEach((item, index) => {
          if (item.fileId === fileId) {
            this.dataList.splice(index, 1)
          }
        })
      }
      this.updateValue()
    },
    updateValue() {
      const resultList = []
      let itemUrl = ''
      let isFinished = true
      this.dataList.forEach((item, index) => {
        if (item.state === 'UPLOADING') {
          isFinished = false
        }
      })
      if (isFinished) {
        this.dataList.forEach((item, index) => {
          if (item.state === 'SUCCESS') {
            resultList.push({ fileName: item.name, fileUrl: item.url, fileSize: item.size, fileId: 'fileId_' + index })
            itemUrl = item.url
          }
        })
        if (this.type === 'singe') {
          this.$emit('input', itemUrl)
        } else {
          this.$emit('input', resultList)
        }
      }
    }
  },
  computed: {
    previewUrl() {
      return (this.dataList && this.dataList.length > 0 && this.dataList.length > this.previewIndex) ? this.wtFileServerUrl + this.dataList[this.previewIndex].url : ''
    },
    previewUrlList() {
      const that = this
      const urlList = []
      this.dataList.forEach((item) => {
        if (item.url) {
          urlList.push(that.wtFileServerUrl + item.url)
        }
      })
      return urlList
    }
  }
}
</script>

<style scoped lang="scss">
  .wt-oss-upload{display: flex;flex-direction: column;}
  .wt-oss-upload .wt-oss-upload-btn{margin-bottom: 0px;}
  .wt-oss-upload .wt-oss-upload-preview-container{width: 0px;height: 0px;overflow: hidden;}

  .wt-oss-upload .wt-oss-upload-imglist{display: flex;flex-wrap: wrap;}
  .wt-oss-upload .wt-oss-upload-imglist .wt-oss-upload-item{width: 100px;height: 100px;margin-bottom: 10px;margin-right: 10px;border: 1px solid #c0ccda;border-radius: 6px;position: relative;display: flex;align-items: center;justify-content: center;overflow: hidden;}
  .wt-oss-upload .wt-oss-upload-imglist .wt-oss-upload-item .content{position: absolute;width: 100%;height: 100%;}
  .wt-oss-upload .wt-oss-upload-imglist .wt-oss-upload-item .content .content-image{width: 100%;height: 100%;}
  .wt-oss-upload .wt-oss-upload-imglist .wt-oss-upload-item .panel{position: absolute;width: 100%;height: 100%;background-color: rgba(0,0,0,.5);transition: opacity .3s;opacity: 0;display: flex;justify-content: center;align-items: center;}
  .wt-oss-upload .wt-oss-upload-imglist .wt-oss-upload-item .state{position: absolute;right: -15px;top: -6px;width: 40px;height: 24px;text-align: center;transform: rotate(45deg);box-shadow: 0 0 1pc 1px rgba(0,0,0,.2);display: none;}
  .wt-oss-upload .wt-oss-upload-imglist .wt-oss-upload-item .state-success{background: #13ce66;display: block;}
  .wt-oss-upload .wt-oss-upload-imglist .wt-oss-upload-item .panel-btns{width: 45px;display: flex;justify-content: space-between;}
  .wt-oss-upload .wt-oss-upload-imglist .wt-oss-upload-item .panel-btn{cursor: pointer;font-size: 18px;color: #FFFFFF;}
  .wt-oss-upload .wt-oss-upload-imglist .wt-oss-upload-item .panel:hover{opacity: 1;}

  .wt-oss-upload .wt-oss-upload-filelist{display: flex;flex-direction: column;margin-bottom: 8px;}
  .wt-oss-upload .wt-oss-upload-filelist .wt-oss-upload-item{width: 100%;max-width: 450px;display: flex;flex-direction: column; justify-content: center;margin-bottom: 4px;}
  .wt-oss-upload .wt-oss-upload-filelist .wt-oss-upload-item:hover{background-color: #F4F4F4;}
  .wt-oss-upload .wt-oss-upload-filelist .wt-oss-upload-item .content{width: 100%;display: flex;justify-content: space-between;}
  .wt-oss-upload .wt-oss-upload-filelist .wt-oss-upload-item .content .title{width: calc(100% - 50px); padding-left: 4px;overflow: hidden;text-overflow: ellipsis;transition: color .3s;white-space: nowrap;line-height: 25px;}
  .wt-oss-upload .wt-oss-upload-filelist .wt-oss-upload-item .content .title span{margin-left: 4px;}
  .wt-oss-upload .wt-oss-upload-filelist .wt-oss-upload-item .content .link-title{cursor: pointer;}
  .wt-oss-upload .wt-oss-upload-filelist .wt-oss-upload-item .content .panel{width: 50px;display: flex;align-items: center;justify-content: flex-end;}
  .wt-oss-upload .wt-oss-upload-filelist .wt-oss-upload-item .content .panel .panel-text{margin-right: 4px;}
  .wt-oss-upload .wt-oss-upload-filelist .wt-oss-upload-item .content .panel .panel-btn{width: 25px;height: 25px;cursor: pointer;display: flex;align-items: center;}
  .wt-oss-upload .wt-oss-upload-filelist .wt-oss-upload-item .content .panel .panel-btn i{font-size: 16px;}
  .wt-oss-upload .wt-oss-upload-filelist .wt-oss-upload-item .progress{width: 100%;}

  .wt-oss-upload .wt-oss-upload-tip{font-size: 14px;}
</style>
