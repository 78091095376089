var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-detail-container"},[_c('wt-detail-header',{attrs:{"title":"详情"}},[(_vm.viewType == 'add')?_c('wt-button',{directives:[{name:"has-any-premission",rawName:"v-has-any-premission",value:(['FIXEDCYCLE:ADD']),expression:"['FIXEDCYCLE:ADD']"}],attrs:{"type":"primary","loading":_vm.isButtonLoading},on:{"click":_vm.onClickAdd}},[_vm._v("保存")]):_vm._e(),(_vm.viewType == 'edit')?_c('wt-button',{directives:[{name:"has-any-premission",rawName:"v-has-any-premission",value:(['FIXEDCYCLE:EDIT']),expression:"['FIXEDCYCLE:EDIT']"}],attrs:{"type":"primary","loading":_vm.isButtonLoading},on:{"click":_vm.onClickSave}},[_vm._v("保存")]):_vm._e(),_c('wt-button',{on:{"click":function($event){return _vm.$emit('on-close')}}},[_vm._v("返回")])],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isFromLoading),expression:"isFromLoading"}],staticClass:"wt-detail-container"},[_c('wt-detail-section',{attrs:{"title":"基本信息"}},[_c('el-form',{ref:"form",staticClass:"wt-detail-section-form-center",attrs:{"model":_vm.detailForm,"size":"small","label-width":"30%"}},[_c('el-form-item',{attrs:{"label":"提醒ID：","prop":"remindId","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.detailForm.remindId),callback:function ($$v) {_vm.$set(_vm.detailForm, "remindId", $$v)},expression:"detailForm.remindId"}})],1),_c('el-form-item',{attrs:{"label":"周几 1 星期一 2星期二 3星期三 4星期四 5星期五 6星期六 7星期日：","prop":"weekDay","rules":[
            {
              required: true,
              message: '输入内容不能为空',
              validator: _vm.$wtValidator.ValidatorNotBlank,
              trigger: 'change'
            }
          ]}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.detailForm.weekDay),callback:function ($$v) {_vm.$set(_vm.detailForm, "weekDay", $$v)},expression:"detailForm.weekDay"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }