<template>
  <div class="wt-section wt-detail-form">
    <div class="wt-section-title" v-if="detailForm.title">
      <div class="title">{{detailForm.title}}</div>
      <div class="buttons">
        <div class="wt-section-title-icon" @click="isExpanded = !isExpanded">
          <span>{{isExpanded?'收起':'展开'}}</span>
          <i :class="{'el-icon-caret-right': !isExpanded, 'el-icon-caret-bottom': isExpanded}"></i>
        </div>
      </div>
    </div>
    <el-form v-show="isExpanded" ref="form" :model="detailFormData" label-width="150px" size="small" class="wt-section-content">
      <template v-for="(item, index) in detailForm.elements">
        <el-form-item v-if="item.type === 'text'" :key="index" :label="item.name + '：'" :prop="item.key" :rules="getRules(item)" :class="item.className ? item.className : 'wt-col-6'">
          <el-input v-model="detailFormData[item.key]" :placeholder="'请输入' + item.name" :disabled="readonly || item.disabled"></el-input>
        </el-form-item>
        <el-form-item v-if="item.type === 'textarea'" :key="index" type="textarea" :label="item.name + '：'" :prop="item.key" :rules="getRules(item)" :class="item.className ? item.className : 'wt-col-6'">
          <el-input v-model="detailFormData[item.key]" type="textarea" :placeholder="'请输入' + item.name" :disabled="readonly || item.disabled"></el-input>
        </el-form-item>
        <el-form-item v-if="item.type === 'select'" :key="index" :label="item.name + '：'" :prop="item.key" :rules="getRules(item)" :class="item.className ? item.className : 'wt-col-6'">
          <el-select v-model="detailFormData[item.key]" :placeholder="'请选择' + item.name" :disabled="readonly || item.disabled">
            <el-option label="请选择" value=""></el-option>
            <el-option v-for="(dataItem, dataIndex) in item.data" :key="dataIndex" :label="dataItem.label"
                       :value="dataItem.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="item.type === 'cascader'" :key="index" :label="item.name + '：'" :prop="item.key" :rules="getRules(item)" :class="item.className ? item.className : 'wt-col-6'">
          <el-cascader v-model="detailFormData[item.key]" :options="item.data" :placeholder="'请选择' + item.name" :disabled="readonly || item.disabled" :props="item.props"></el-cascader>
        </el-form-item>
        <el-form-item v-if="item.type === 'date'" :key="index" :label="item.name + '：'" :prop="item.key" :rules="getRules(item)" :class="item.className ? item.className : 'wt-col-6'">
          <el-date-picker v-model="detailFormData[item.key]" type="date" :placeholder="'请选择' + item.name" :disabled="readonly || item.disabled"
                          :picker-options="{disabledDate: (time) => {return datePickerOptionsDisabledDate(time, item)}}"
                          :value-format="(item.attr && item.attr['value-format'] ? item.attr['value-format'] : 'yyyy-MM-dd' )" v-bind="item.attr"></el-date-picker>
        </el-form-item>
        <el-form-item v-if="item.type === 'date'" :key="index" :label="item.name + '：'" :prop="item.key" :rules="getRules(item)" :class="item.className ? item.className : 'wt-col-6'">
          <el-date-picker v-model="detailFormData[item.key]" type="date" :placeholder="'请选择' + item.name" :disabled="readonly || item.disabled"
                          :picker-options="{disabledDate: (time) => {return datePickerOptionsDisabledDate(time, item)}}"
                          :value-format="(item.attr && item.attr['value-format'] ? item.attr['value-format'] : 'yyyy-MM-dd' )" v-bind="item.attr"></el-date-picker>
        </el-form-item>

        <el-form-item v-if="item.type === 'daterange'" :key="index" :label="item.name + '：'" :prop="item.key" :rules="getRules(item)" :class="item.className ? item.className : 'wt-col-6'">
          <el-date-picker v-model="detailFormData[item.key]" type="daterange" :placeholder="'请选择' + item.name" :disabled="readonly || item.disabled"
                          format="yyyy-MM-dd" value-format="yyyy-MM-dd" range-separator="至"
                          start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
        </el-form-item>

        <el-form-item v-if="item.type === 'datetime'" :key="index" :label="item.name + '：'" :prop="item.key" :rules="getRules(item)" :class="item.className ? item.className : 'wt-col-6'">
          <el-date-picker v-model="detailFormData[item.key]" type="datetime" :placeholder="'请选择' + item.name" :disabled="readonly || item.disabled"
                          :picker-options="{disabledDate: (time) => {return datePickerOptionsDisabledDate(time, item)}, selectableRange: item.attr && item.attr['selectableRange'] ? item.attr['selectableRange'] : null }"
                          :value-format="(item.attr && item.attr['value-format'] ? item.attr['value-format'] : 'yyyy-MM-dd HH:mm:ss' )" v-bind="item.attr"></el-date-picker>
        </el-form-item>
        <el-form-item v-if="item.type === 'time'" :key="index" :label="item.name + '：'" :prop="item.key" :rules="getRules(item)" :class="item.className ? item.className : 'wt-col-6'">
          <el-time-picker v-model="detailFormData[item.key]" :placeholder="'请选择' + item.name" :disabled="readonly || item.disabled" v-bind="item.attr"></el-time-picker>
        </el-form-item>
        <el-form-item v-if="item.type === 'timeselect'" :key="index" :label="item.name + '：'" :prop="item.key" :rules="getRules(item)" :class="item.className ? item.className : 'wt-col-6'">
          <el-time-select v-model="detailFormData[item.key]" :placeholder="'请选择' + item.name" :disabled="readonly || item.disabled" v-bind="item.attr"></el-time-select>
        </el-form-item>
        <el-form-item v-if="item.type === 'image'" :key="index" :label="item.name + '：'" :prop="item.key" :rules="getRules(item)" :class="item.className ? item.className : 'wt-col-6'">
          <wt-upload class="wt-image-upload" :value.sync="detailFormData[item.key]" :multiple="true" :limit="item.limit" :file-size="item.fileSize" :disabled="readonly || item.disabled"/>
        </el-form-item>
        <el-form-item v-if="item.type === 'file'" :key="index" :label="item.name + '：'" :prop="item.key" :rules="getRules(item)" :class="item.className ? item.className : 'wt-col-6'">
          <!--<wt-upload :value.sync="detailFormData[item.key]" file-type="file" :multiple="true" :limit="item.limit" :file-size="item.fileSize" :file-accept="item.fileAccept" :readonly="readonly || item.disabled"/>-->
          <wt-oss-upload
            :disabled="item.disabled"
            :listType="item.listType"
            :btnTxt="item.btnTxt"
            :showFileList="item.showFileList"
            :drag="item.drag"
            :accept="item.accept"
            :fileList.sync="detailFormData[item.key]"
            :limit="item.limit"></wt-oss-upload>
        </el-form-item>
        <template v-if="item.type === 'editor'">
          <el-form-item :label="item.name + '：'" :key="index" :prop="item.key" :rules="getRules(item)" :class="item.className ? item.className : 'wt-col-12'">
            <WtTinymce :ref="item.key + '_editor'" v-model="detailFormData[item.key]" :readonly="readonly || item.disabled"></WtTinymce>
          </el-form-item>
        </template>
        <template v-if="item.type === 'custom'">
          <el-form-item  :label="item.name + '：'" :key="index" :prop="item.key" :rules="getRules(item)" :class="item.className ? item.className : 'wt-col-6'">
            <slot :name="item.key" :item="item" :detailFormData="detailFormData" />
          </el-form-item>
        </template>
        <template v-if="item.type === 'customAll'">
          <slot :name="item.key" :item="item" :detailFormData="detailFormData" />
        </template>
      </template>
    </el-form>
  </div>
</template>

<script>
import WtTinymce from '@/components/WtTinymce'
export default {
  name: 'wt-detail-form-section',
  props: ['detail-form-data', 'detail-form', 'readonly'],
  components: {
    WtTinymce
  },
  data() {
    return {
      isExpanded: true
    }
  },
  created() {
  },
  methods: {
    getRules(item) {
      let rules = []
      if (item.rules) {
        rules = rules.concat(item.rules)
      }
      if (item.required) {
        if (item.type === 'select') {
          rules = rules.concat([{ required: true, message: '请选择' + item.name, trigger: 'change' }])
        } else if (item.type === 'editor') {
          rules = rules.concat([{ required: true, message: '请输入' + item.name, trigger: 'change' }, { validator: this.$wtValidator.ValidatorWtEditor, message: '输入内容不能为空', trigger: 'change' }])
        } else {
          rules = rules.concat([{ required: true, message: '请输入' + item.name, trigger: 'blur' }, { validator: this.$wtValidator.ValidatorNotBlank, message: '输入内容不能为空', trigger: 'blur' }])
        }
      }
      return rules
    },
    datePickerOptionsDisabledDate(time, item) {
      if (item.min && time < item.min) {
        return true
      }
      if (item.max && time > item.max) {
        return true
      }
      return false
    }
  }
}
</script>

<style scoped>

</style>
