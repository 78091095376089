<template>
  <div class="page-detail-container">
    <!--顶部标题和页面全局按钮-->
    <wt-detail-header title="详情">
      <wt-button @click="$emit('on-close')">返回</wt-button>
    </wt-detail-header>
    <!--内容区域-->
    <div class="wt-detail-container">
      <wt-detail-section title="基本信息">
        <el-form
          :model="detailForm"
          ref="form"
          size="small"
          class="wt-detail-section-form-center"
          label-width="30%"
        >
          <el-form-item
            label="订单号："
            prop="orderNo"
            :rules="[
              {
                required: true,
                message: '订单号不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            {{ detailForm.orderNo }}
          </el-form-item>
          <el-form-item
            label="角色 1 医生 2 患者："
            prop="type"
            :rules="[
              {
                required: true,
                message: '角色 1 医生 2 患者不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'change'
              }
            ]"
          >
            <el-select
              v-model="detailForm.type"
              placeholder="请选择"
              readonly
              disabled
            ></el-select>
          </el-form-item>
          <el-form-item
            label="用户："
            prop="userId"
            :rules="[
              {
                required: true,
                message: '用户不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'change'
              }
            ]"
          >
            <el-select
              v-model="detailForm.userId"
              placeholder="请选择"
              readonly
              disabled
            ></el-select>
          </el-form-item>
          <el-form-item
            label="商品ID："
            prop="giftId"
            :rules="[
              {
                required: true,
                message: '商品ID不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'change'
              }
            ]"
          >
            <el-select
              v-model="detailForm.giftId"
              placeholder="请选择"
              readonly
              disabled
            ></el-select>
          </el-form-item>
          <el-form-item
            label="兑换数量："
            prop="exchangeNum"
            :rules="[
              {
                required: true,
                message: '兑换数量不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'change'
              }
            ]"
          >
            <el-select
              v-model="detailForm.exchangeNum"
              placeholder="请选择"
              readonly
              disabled
            ></el-select>
          </el-form-item>
          <el-form-item
            label="消耗积分："
            prop="point"
            :rules="[
              {
                required: true,
                message: '消耗积分不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'change'
              }
            ]"
          >
            <el-select
              v-model="detailForm.point"
              placeholder="请选择"
              readonly
              disabled
            ></el-select>
          </el-form-item>
          <el-form-item
            label="状态 1 待审核 2审核通过，待发货 3审核通过，已发货 4 拒绝："
            prop="status"
            :rules="[
              {
                required: true,
                message:
                  '状态 1 待审核 2审核通过，待发货 3审核通过，已发货 4 拒绝不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'change'
              }
            ]"
          >
            <el-select
              v-model="detailForm.status"
              placeholder="请选择"
              readonly
              disabled
            ></el-select>
          </el-form-item>
          <el-form-item
            label="拒绝原因："
            prop="reason"
            :rules="[
              {
                required: true,
                message: '拒绝原因不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            {{ detailForm.reason }}
          </el-form-item>
          <el-form-item
            label="物流公司："
            prop="logisticsCompany"
            :rules="[
              {
                required: true,
                message: '物流公司不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            {{ detailForm.logisticsCompany }}
          </el-form-item>
          <el-form-item
            label="快递单号："
            prop="trackingNumber"
            :rules="[
              {
                required: true,
                message: '快递单号不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            {{ detailForm.trackingNumber }}
          </el-form-item>
          <el-form-item
            label="是否发布："
            prop="isPublish"
            :rules="[
              {
                required: true,
                message: '请选择发布状态',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'change'
              }
            ]"
          >
            <wt-form-simple-select-item
              v-model="detailForm.isPublish"
              readonly
              disabled
            />
          </el-form-item>
        </el-form>
      </wt-detail-section>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'GiftOrderView',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isFromLoading: false,
      detailForm: {}
    }
  },
  mounted() {
    this.requestDetail()
  },
  created() {},
  methods: {
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that
        .$wtRequest({
          url: '/modules/giftOrder/detail',
          method: 'post',
          data: { id: that.detailData.id }
        })
        .then(resp => {
          that.detailForm = resp.data || {}
          that.isFromLoading = false
        })
        .catch(() => {
          that.isFromLoading = false
        })
    }
  },
  computed: {
    ...mapState({})
  }
}
</script>
