<template>
  <div class="page-detail-container">
    <!--顶部标题和页面全局按钮-->
    <wt-detail-header>
      <wt-button
        v-if="viewType == 'add'"
        v-has-any-premission="['ACCOUNTLIST:ADD']"
        type="primary"
        :loading="isButtonLoading"
        @click="onClickAdd"
        >保存</wt-button
      >
      <wt-button
        v-if="viewType == 'edit'"
        v-has-any-premission="['ACCOUNTLIST:EDIT']"
        type="primary"
        :loading="isButtonLoading"
        @click="onClickSave"
        >保存</wt-button
      >
      <wt-button @click="$emit('on-close')">返回</wt-button>
    </wt-detail-header>
    <!--内容区域-->
    <div class="wt-detail-container" v-loading="isFromLoading">
      <wt-detail-section title="患者档案">
        <div class="one">
          <img class="bitian" src="@/assets/img/bitian.png" alt="" />
          <div style="display: inline-block">就诊时间:</div>
          <div style="float: right">{{ dangqianshuju.clinicTime }}</div>
        </div>
        <div class="one">
          <img class="bitian" src="@/assets/img/bitian.png" alt="" />
          <div style="display: inline-block">就诊医院:</div>
          <div style="float: right">{{ dangqianshuju.hospital }}</div>
        </div>
        <div class="one">
          <!-- <img class="bitian" src="@/assets/img/bitian.png" alt="" /> -->
          <div style="display: inline-block;    margin-left: 16px;">就诊医生:</div>
          <div style="float: right">{{ dangqianshuju.doctor }}</div>
        </div>
        <div class="one">
          <img class="bitian" src="@/assets/img/bitian.png" alt="" />
          <div style="display: inline-block">主诉:</div>
          <div style="padding-left: 14px; margin-top: 24px">
            {{ dangqianshuju.actionChief }}
          </div>
        </div>
        <div class="one">
          <!-- <img class="bitian" src="@/assets/img/bitian.png" alt="" /> -->
          <div style="display: inline-block;    margin-left: 16px;">病史:</div>
          <div style="padding-left: 14px; margin-top: 24px">
            {{ dangqianshuju.medicalHistory }}
          </div>
        </div>
        <div class="one">
          <div>
            <!-- <img class="bitian" src="@/assets/img/bitian.png" alt="" /> -->
            <div style="display: inline-block;    margin-left: 16px;">检查:</div>
          </div>
          <div
            style="padding-left: 14px; margin-top: 24px;display: inline-block;"
            v-for="(item, index) in dangqianshuju.paths"
            :key="index"
            class="aaa"
          >
            <div style="display: inline-block;" class="bbb">
              <!-- <img
                style="width: 150px; height: 150px"
                :src="
                  'https://meining.100api.com.cn/files/' + item
                "
                alt=""
              /> -->
              <el-image
                style="width: 100px; height: 100px"
                :src="
                  'https://meining.100api.com.cn/files/' + item
                "
                :preview-src-list="srcList"
              >
              </el-image>
            </div>
          </div>
        </div>
        <div class="one">
          <img class="bitian" src="@/assets/img/bitian.png" alt="" />
          <div style="display: inline-block">诊断:</div>
          <div style="padding-left: 14px; margin-top: 24px">
            {{ dangqianshuju.diagnose }}
          </div>
        </div>
        <div class="one">
          <img class="bitian" src="@/assets/img/bitian.png" alt="" />
          <div style="display: inline-block">处理:</div>
          <div style="padding-left: 14px; margin-top: 24px">
            {{ dangqianshuju.dispose }}
          </div>
        </div>
      </wt-detail-section>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapState } from 'vuex'
import staticRoutes from '@/router/staticRoutes.js'
import { patientRecordDetail } from '@/api/course.js'
import moment from 'moment'
export default {
  name: 'patienArchivesEdit',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      roleList: [],
      srcList: [],
      staticRoutes: staticRoutes,
      defaultCheckedKeys: [],
      isButtonLoading: false,
      isFromLoading: false,
      detailForm: {
        isPublish: 1,
        roleList: []
      },
      form: {
        isEditDialogVisible: false,
        detailData: {
          id: ''
        }
      },
      dangqianshuju: {}
    }
  },
  mounted() {
    this.requestManagerRoleList()
    if (this.viewType !== 'add') {
      this.requestDetail()
    }
  },
  created() {
    this.huoquxinxi()
  },
  methods: {
    huoquxinxi() {
      patientRecordDetail({ id: window.localStorage.getItem('param') })
        .then(res => {
          console.log(res)
          this.dangqianshuju = res.data
          // this.srcList = this.dangqianshuju.paths
          this.dangqianshuju.paths.map(val => {
            this.srcList.push(
              'https://meining.100api.com.cn/files/' + val
            )
            console.log(val)
          })
          this.dangqianshuju.clinicTime = moment(
            this.dangqianshuju.clinicTime
          ).format('YYYY-MM-DD')
          console.log(this.dangqianshuju)
          console.log(159)
          console.log(this.srcList)
        })
        .catch(res => {
          console.log(res)
        })
    },
    onClickAdd() {
      const that = this
      this.$wtUtil
        .validateDetailFormsAndConfirm(this, '是否保存?')
        .then(() => {
          that.requestAdd()
        })
        .catch(() => {})
    },
    onClickSave() {
      const that = this
      this.$wtUtil
        .validateDetailFormsAndConfirm(this, '是否保存?')
        .then(() => {
          that.requestUpdate()
        })
        .catch(() => {})
    },
    requestDetail() {
      const that = this
      that.isFromLoading = true
      console.log(that.detailData)
      that
        .$wtRequest({
          url: '/modules/patientRecord/list',
          method: 'post',
          data: {
            formData: {
              patientId: that.detailData.id
            },
            pageData: {
              currentPage: 1,
              pageSize: 20,
              sortName: ' ',
              sortOrder: ' '
            }
          }
        })
        .then(resp => {
          that.detailForm = resp.data || {}
          that.tableData = that.detailForm.list
          console.log(that.detailForm)
          that.isFromLoading = false
        })
        .catch(() => {
          that.isFromLoading = false
        })
    },
    requestManagerRoleList() {
      const that = this
      that.isFromLoading = true
      that
        .$wtRequest({
          url: '/modules/auth/accountRoleList',
          method: 'post',
          data: {}
        })
        .then(resp => {
          const tempRoleList = [].concat(resp.data)
          that.roleList = tempRoleList
          console.log(that.roleList)
          that.isFromLoading = false
        })
        .catch(() => {
          that.isFromLoading = false
        })
    },
    requestUpdate() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that
        .$wtRequest({
          url: '/modules/auth/accountUpdate',
          method: 'post',
          data: that.detailForm
        })
        .then(resp => {
          that.isButtonLoading = false
          that.isFromLoading = false
          that.$message({ type: 'success', message: '保存成功' })
          that.$emit('on-refresh')
          that.$emit('on-close')
        })
        .catch(() => {
          that.isButtonLoading = false
          that.isFromLoading = false
        })
    },
    requestAdd() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that
        .$wtRequest({
          url: '/modules/auth/accountAdd',
          method: 'post',
          data: that.detailForm
        })
        .then(resp => {
          that.isButtonLoading = false
          that.isFromLoading = false
          that.$message({ type: 'success', message: '保存成功' })
          that.$emit('on-refresh')
          that.$emit('on-close')
        })
        .catch(() => {
          that.isButtonLoading = false
          that.isFromLoading = false
        })
    }
  },
  computed: {
    moduleTreeData() {
      const that = this
      const treeData = []
      const makeChildrenFunction = staticRoute => {
        const children = []
        if (!staticRoute.hidden) {
          if (staticRoute.children && staticRoute.children.length > 0) {
            const parentRouteId = staticRoute.name.toUpperCase()
            if (that.defaultCheckedKeys.indexOf(parentRouteId) !== -1) {
              that.defaultCheckedKeys.splice(
                that.defaultCheckedKeys.indexOf(parentRouteId),
                1
              )
            }
            staticRoute.children.forEach(child => {
              const routeId = child.name.toUpperCase()
              children.push({
                id: routeId,
                label: child.meta ? child.meta.title : '',
                children: makeChildrenFunction(child)
              })
            })
          }
        }
        return children
      }
      this.staticRoutes.forEach(staticRoute => {
        if (!staticRoute.hidden) {
          const routeId = staticRoute.name.toUpperCase()
          treeData.push({
            id: routeId,
            label: staticRoute.meta ? staticRoute.meta.title : '',
            children: makeChildrenFunction(staticRoute)
          })
        }
      })
      return treeData
    }
    // ...mapState({
    //   groupList: state => state.ops.groupList
    // })
  }
}
</script>

<style lang="scss" scoped>
.one {
  width: 51%;
  padding: 14px;
  border-bottom: 1px solid #d8d8d8;
}
.bitian {
  width: 16px;
  height: 16px;
  vertical-align: top;
}
</style>
<style scoped></style>
