<template>
  <div class="page-detail-container">
    <!--顶部标题和页面全局按钮-->
    <wt-detail-header title="详情">
      <wt-button v-if="viewType == 'add' || viewType == 'sub-add'" v-has-any-premission="['DICT:ADD']" type="primary" :loading="isButtonLoading" @click="onClickAdd">保存</wt-button>
      <wt-button v-if="viewType == 'edit'" v-has-any-premission="['DICT:EDIT']" type="primary" :loading="isButtonLoading" @click="onClickSave">保存</wt-button>
      <wt-button @click="$emit('on-close')">返回</wt-button>
    </wt-detail-header>
    <!--内容区域-->
    <div class="wt-detail-container" v-loading="isFromLoading">
      <wt-detail-section title="基本信息">
        <el-form :model="detailForm" ref="form" size="small" class="wt-detail-section-form-center" label-width="30%">
          <el-form-item v-if="viewType == 'sub-add'" label="父字典项：" prop="parentId" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <el-input v-model="detailForm.parentName" placeholder="请输入" readonly></el-input>
          </el-form-item>
          <el-form-item label="名称：" prop="dictName" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <el-input v-model="detailForm.dictName" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="标识：" prop="dictKey" :rules="[{ required: true, message: '输入内容不能为空', validator: $wtValidator.ValidatorNotBlank, trigger: 'blur' }]">
            <el-input v-model="detailForm.dictKey" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="数值：" prop="dictValue">
            <el-input v-model="detailForm.dictValue" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="扩展信息：" prop="ext">
            <el-input v-model="detailForm.ext" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="排序：" prop="orderNum" :rules="[{ type: 'number', message: '排序字段必须为数字值'}]">
            <el-input v-model.number="detailForm.orderNum" placeholder="请输入" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="发布状态：" prop="isPublish" :rules="[{ required: true, message: '请选择发布状态', validator: $wtValidator.ValidatorNotBlank, trigger: 'change' }]">
            <wt-form-simple-select-item v-model="detailForm.isPublish" />
          </el-form-item>
        </el-form>
      </wt-detail-section>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'DictEdit',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      isButtonLoading: false,
      isFromLoading: false,
      detailForm: {
        parentId: '0',
        parentName: '',
        orderNum: 0,
        isPublish: 1
      }
    }
  },
  mounted() {
    if (this.viewType === 'edit') {
      this.requestDetail()
    } else if (this.viewType === 'sub-add') {
      this.detailForm.parentId = this.detailData.id
      this.detailForm.parentName = this.detailData.parentName
    }
  },
  created() {
  },
  methods: {
    onClickAdd() {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestAdd()
      }).catch(() => {
      })
    },
    onClickSave() {
      const that = this
      this.$wtUtil.validateDetailFormsAndConfirm(this, '是否保存?').then(() => {
        that.requestUpdate()
      }).catch(() => {
      })
    },
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that.$wtRequest({
        url: '/modules/dict/detail',
        method: 'post',
        data: { id: that.detailData.id }
      }).then((resp) => {
        that.detailForm = resp.data || {}
        that.isFromLoading = false
      }).catch(() => {
        that.isFromLoading = false
      })
    },
    requestUpdate() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that.$wtRequest({
        url: '/modules/dict/update',
        method: 'post',
        data: that.detailForm
      }).then((resp) => {
        that.isButtonLoading = false
        that.isFromLoading = false
        that.$message({ type: 'success', message: '保存成功' })
        that.$emit('on-refresh')
        that.$emit('on-close')
      }).catch(() => {
        that.isButtonLoading = false
        that.isFromLoading = false
      })
    },
    requestAdd() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that.$wtRequest({
        url: '/modules/dict/add',
        method: 'post',
        data: that.detailForm
      }).then((resp) => {
        that.isButtonLoading = false
        that.isFromLoading = false
        that.$message({ type: 'success', message: '保存成功' })
        that.$emit('on-refresh')
        that.$emit('on-close')
      }).catch(() => {
        that.isButtonLoading = false
        that.isFromLoading = false
      })
    }
  },
  computed: {
    ...mapState({
    })
  }
}
</script>
