<template>
  <div class="page-detail-container">
    <!--顶部标题和页面全局按钮-->
    <wt-detail-header>
      <wt-button
        v-if="viewType == 'add'"
        v-has-any-premission="['ACCOUNTLIST:ADD']"
        type="primary"
        :loading="isButtonLoading"
        @click="onClickAdd"
        >保存</wt-button
      >
      <wt-button
        v-if="viewType == 'edit'"
        v-has-any-premission="['ACCOUNTLIST:EDIT']"
        type="primary"
        :loading="isButtonLoading"
        @click="onClickSave"
        >保存</wt-button
      >
      <wt-button @click="$emit('on-close')">返回</wt-button>
    </wt-detail-header>
    <!--内容区域-->
    <div class="wt-detail-container" v-loading="isFromLoading">
      <wt-detail-section title="基本信息">
        <el-form
          :model="detailForm"
          ref="form"
          size="small"
          class="wt-detail-section-form-center"
          label-width="30%"
        >
          <el-form-item
            label="患者姓名:"
            prop="realname"
            :rules="[
              {
                required: true,
                message: '输入内容不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            <el-input
              v-model="detailForm.realname"
              placeholder="请输入"
              :disabled="viewType == 'view'"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="患者性别"
            prop="gender"
            :rules="[
              {
                required: true,
                message: '选择内容不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'change'
              }
            ]"
          >
            <el-radio-group v-model="detailForm.gender">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="患者年龄"
            prop="age"
            :rules="[
              {
                required: true,
                message: '输入内容不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            <el-input
              v-model="detailForm.age"
              placeholder="请输入"
              :disabled="viewType == 'view'"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="患者手机号码"
            prop="phone"
            :rules="[
              {
                required: true,
                message: '输入内容不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            <el-input
              v-model="detailForm.phone"
              placeholder="请输入"
              :disabled="viewType == 'view'"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="所在城市"
            prop="city"
            :rules="[
              {
                required: true,
                message: '输入内容不能为空',
                validator: $wtValidator.ValidatorNotBlank,
                trigger: 'blur'
              }
            ]"
          >
            <el-input
              v-model="detailForm.city"
              placeholder="请输入"
              :disabled="viewType == 'view'"
            ></el-input>
          </el-form-item>
          <el-form-item label="家属姓名" prop="relationName">
            <el-input
              v-model="detailForm.relationName"
              placeholder="请输入"
              :disabled="viewType == 'view'"
            ></el-input>
          </el-form-item>
          <el-form-item label="家属联系人电话" prop="relationPhone">
            <el-input
              v-model="detailForm.relationPhone"
              placeholder="请输入"
              :disabled="viewType == 'view'"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm(detailForm)"
              >保存</el-button
            >
            <el-button @click="$emit('on-close')">取消</el-button>
          </el-form-item>
        </el-form>
      </wt-detail-section>
      <!-- <wt-detail-section title="密码信息" v-if="viewType != 'view'">
        <el-form :model="detailForm" ref="form" size="small" class="wt-detail-section-form-center" label-width="30%">
          <el-form-item label="新密码" prop="newPassword">
            <el-input v-model="detailForm.newPassword" type="password" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="confirmPassword">
            <el-input v-model="detailForm.confirmPassword" type="password" placeholder="请输入"></el-input>
          </el-form-item>
        </el-form>
      </wt-detail-section> -->
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapState } from 'vuex'
import staticRoutes from '@/router/staticRoutes.js'
import { patientUpdate } from '@/api/course.js'
export default {
  name: 'accountEdit',
  isAutoComponent: true,
  props: ['viewType', 'detailData'],
  data() {
    return {
      roleList: [],
      staticRoutes: staticRoutes,
      defaultCheckedKeys: [],
      isButtonLoading: false,
      isFromLoading: false,
      detailForm: {
        isPublish: 1,
        roleList: []
      }
    }
  },
  mounted() {
    this.requestManagerRoleList()
    if (this.viewType !== 'add') {
      this.requestDetail()
    }
  },
  created() {},
  methods: {
    submitForm(data) {
      console.log(data)
      if (data.age === '') {
        console.log(data.age)
        this.$message('请输入年龄')
        return
      }
      if (data.city === '') {
        console.log(data.city)
        this.$message('请输入所有在城市')
        return
      }
      if (data.gender === '') {
        console.log(data.gender)
        this.$message('请选中性别')
        return
      }
      if (data.phone === '') {
        console.log(data.phone)
        this.$message('请输入手机号')
        return
      }
      if (data.realname === '') {
        console.log(data.realname)
        this.$message('请输入姓名')
        return
      }
      patientUpdate(data)
        .then(res => {
          console.log(res)
          if (res.msg === '请求成功') {
            this.$message({
              message: '保存成功',
              type: 'success'
            })
            this.$emit('on-close')
          }
        })
        .catch(res => {
          console.log(res)
        })
      console.log('aaa')
    },
    onClickAdd() {
      const that = this
      this.$wtUtil
        .validateDetailFormsAndConfirm(this, '是否保存?')
        .then(() => {
          that.requestAdd()
        })
        .catch(() => {})
    },
    onClickSave() {
      const that = this
      this.$wtUtil
        .validateDetailFormsAndConfirm(this, '是否保存?')
        .then(() => {
          that.requestUpdate()
        })
        .catch(() => {})
    },
    requestDetail() {
      const that = this
      that.isFromLoading = true
      that
        .$wtRequest({
          url: '/modules/patient/detail',
          method: 'post',
          data: { id: that.detailData.id }
        })
        .then(resp => {
          that.detailForm = resp.data || {}
          that.isFromLoading = false
        })
        .catch(() => {
          that.isFromLoading = false
        })
    },
    requestManagerRoleList() {
      const that = this
      that.isFromLoading = true
      that
        .$wtRequest({
          url: '/modules/auth/accountRoleList',
          method: 'post',
          data: {}
        })
        .then(resp => {
          const tempRoleList = [].concat(resp.data)
          that.roleList = tempRoleList
          console.log(that.roleList)
          that.isFromLoading = false
        })
        .catch(() => {
          that.isFromLoading = false
        })
    },
    requestUpdate() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that
        .$wtRequest({
          url: '/modules/auth/accountUpdate',
          method: 'post',
          data: that.detailForm
        })
        .then(resp => {
          that.isButtonLoading = false
          that.isFromLoading = false
          that.$message({ type: 'success', message: '保存成功' })
          that.$emit('on-refresh')
          that.$emit('on-close')
        })
        .catch(() => {
          that.isButtonLoading = false
          that.isFromLoading = false
        })
    },
    requestAdd() {
      const that = this
      that.isButtonLoading = true
      that.isFromLoading = true
      that
        .$wtRequest({
          url: '/modules/auth/accountAdd',
          method: 'post',
          data: that.detailForm
        })
        .then(resp => {
          that.isButtonLoading = false
          that.isFromLoading = false
          that.$message({ type: 'success', message: '保存成功' })
          that.$emit('on-refresh')
          that.$emit('on-close')
        })
        .catch(() => {
          that.isButtonLoading = false
          that.isFromLoading = false
        })
    }
  },
  computed: {
    moduleTreeData() {
      const that = this
      const treeData = []
      const makeChildrenFunction = staticRoute => {
        const children = []
        if (!staticRoute.hidden) {
          if (staticRoute.children && staticRoute.children.length > 0) {
            const parentRouteId = staticRoute.name.toUpperCase()
            if (that.defaultCheckedKeys.indexOf(parentRouteId) !== -1) {
              that.defaultCheckedKeys.splice(
                that.defaultCheckedKeys.indexOf(parentRouteId),
                1
              )
            }
            staticRoute.children.forEach(child => {
              const routeId = child.name.toUpperCase()
              children.push({
                id: routeId,
                label: child.meta ? child.meta.title : '',
                children: makeChildrenFunction(child)
              })
            })
          }
        }
        return children
      }
      this.staticRoutes.forEach(staticRoute => {
        if (!staticRoute.hidden) {
          const routeId = staticRoute.name.toUpperCase()
          treeData.push({
            id: routeId,
            label: staticRoute.meta ? staticRoute.meta.title : '',
            children: makeChildrenFunction(staticRoute)
          })
        }
      })
      return treeData
    }
    // ...mapState({
    //   groupList: state => state.ops.groupList
    // })
  }
}
</script>
