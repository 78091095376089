<template>
  <div class="page-container">
    <wt-data-form ref="dataForm" :form.sync="form" request-list-url="/modules/consultRecord/list" request-delete-url="/modules/consultRecord/delete"
                  request-detail-url="/modules/consultRecord/detail">
      <template slot="search">
          <wt-search-form-item title="主键" v-model="form.formData.id" type="select" />
          <wt-search-form-item title="患者ID" v-model="form.formData.patientId" type="select" />
          <wt-search-form-item title="医生ID" v-model="form.formData.doctorId" type="select" />
          <wt-search-form-item title="患者提问" v-model="form.formData.question" type="input" />
          <wt-search-form-item title="患病时长" v-model="form.formData.fallIllTime" type="input" />
          <wt-search-form-item title="疾病名称" v-model="form.formData.diseaseName" type="input" />
          <wt-search-form-item title="回复时间" v-model="form.formData.replyTime" type="daterange" />
          <wt-search-form-item title="是否删除" v-model="form.formData.isDelete" type="select" />
          <wt-search-form-item title="创建时间" v-model="form.formData.createTime" type="daterange" />
          <wt-search-form-item title="更新时间" v-model="form.formData.updateTime" type="daterange" />
      </template>
      <template slot="toolbar">
        <wt-button type="primary" v-has-any-premission="['CONSULTRECORD:ADD']" @click="$refs.dataForm.toAdd()">新增</wt-button>
        <wt-button type="danger" v-has-any-premission="['CONSULTRECORD:DELETE']" @click="$refs.dataForm.onClickMultipleDelete()">删除</wt-button>
      </template>
      <template slot="table">
        <el-table-column type="selection" label="选择"></el-table-column>
        <el-table-column prop="id" label="主键" sortable></el-table-column>
        <el-table-column prop="patientId" label="患者ID" sortable></el-table-column>
        <el-table-column prop="doctorId" label="医生ID" sortable></el-table-column>
        <el-table-column prop="question" label="患者提问"></el-table-column>
        <el-table-column prop="fallIllTime" label="患病时长"></el-table-column>
        <el-table-column prop="diseaseName" label="疾病名称"></el-table-column>
        <el-table-column prop="replyTime" label="回复时间" sortable></el-table-column>
        <el-table-column prop="createTime" label="创建时间" sortable></el-table-column>
        <el-table-column prop="updateTime" label="更新时间" sortable></el-table-column>
        <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <wt-text-button v-has-any-premission="['CONSULTRECORD:VIEW']" @click="$refs.dataForm.toView(scope.row)">查看详情</wt-text-button>
              <wt-text-button v-has-any-premission="['CONSULTRECORD:EDIT']" @click="$refs.dataForm.toEdit(scope.row)">编辑</wt-text-button>
            </template>
        </el-table-column>
      </template>
    </wt-data-form>
    <!--弹层 开始-->
    <wt-detail-page :visible.sync="form.isViewDialogVisible"><ConsultRecordView :detailData="form.detailData" view-type="view" @on-close="form.isViewDialogVisible = false"/></wt-detail-page>
    <wt-detail-page :visible.sync="form.isEditDialogVisible"><ConsultRecordEdit :detailData="form.detailData" :view-type="form.dialogViewType" @on-refresh="$refs.dataForm.requestDataList()" @on-close="form.isEditDialogVisible = false"/></wt-detail-page>
    <!--弹层 结束-->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'consultRecordList',
  data() {
    return {
      form: {
        isFromLoading: false,
        isViewDialogVisible: false,
        isEditDialogVisible: false,
        dialogViewType: 'edit',
        detailData: {},
        extFormData: {},
        formData: {
          name: '',
          updateTimeRange: []
        },
        pageData: { currentPage: 1, pageSize: 20, sortName: '', sortOrder: '' },
        table: {},
        formResult: {}
      }
    }
  },
  mounted() {
    this.$refs.dataForm.onClickSearch()
  },
  created() {
  },
  methods: {
  },
  computed: {
    ...mapState({
    })
  }
}
</script>
