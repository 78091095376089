<template>
  <div class="wt-form-toolbar">
    <div class="left">
      <slot name="left">
        <div class="table-info-title">数据列表</div>
        <div class="table-info-text" v-if="total >= 0">数据共 <span>{{total}}</span> 条</div>
      </slot>
    </div>
    <div class="right">
      <slot>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WtFormToolbar',
  props: ['form'],
  computed: {
    total() {
      if (this.form === null || this.form.formResult === null || this.form.formResult === undefined || this.form.formResult.total === undefined) {
        return -1
      }
      return this.form.formResult.total
    }
  }
}
</script>

<style scoped>

</style>
